import { useCallback } from 'react'
import { auth } from 'services/firebase'

const useSendPasswordResetEmail = () => {
  return useCallback((email) => {
    return auth.sendPasswordResetEmail(email, {
      url: `${window.location.origin}/login`,
      handleCodeInApp: false,
    })
  }, [])
}

export default useSendPasswordResetEmail
