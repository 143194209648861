import React from 'react'
import accountIcon from 'images/icon-account.png'

import './Account.scss'
import useUserInfo from 'hooks/useUserInfo'

const Account = () => {
  const [userInfo] = useUserInfo()
  return (
    <div className='m-account'>
      <header>
        <span>Account</span>
        <img src={accountIcon} alt='Account' />
      </header>
      <main>
        <div className='m-account__item'>
          <strong>Email address</strong>
          <span>{userInfo.email}</span>
          <small>The one used to sign in</small>
        </div>
        <div className='m-account__item'>
          <strong>Password</strong>
          <a href='#'>Reset Password</a>
        </div>
      </main>
    </div>
  )
}

export default Account
