import { useCallback } from 'react'
import { auth, firestore } from 'services/firebase'
import useGetUserByEmail from 'services/firebase/hooks/lawyers/useGetLawyerByEmail'
import { toast } from 'react-toastify'

const useGetInvitesToTeam = () => {
  return useCallback(async () => {
    if (auth.currentUser) {
      const { uid } = auth.currentUser

      return await firestore
        .collection('invites_to_team')
        .where('invitedUid', '==', uid)
        .limit(3)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            return snap.docs.map((item) => {
              return { ...item.data(), id: item.id }
            })
          } else {
            return null
          }
        })
    }
    return null
  }, [])
}

export default useGetInvitesToTeam
