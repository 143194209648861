import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { withRouter } from 'react-router-dom'

import {
  Select,
  FormControl,
  MenuItem,
  TextField,
  Button,
  makeStyles,
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Typed from 'react-typed'
import { expertise } from 'utils/constants'
import './HomeSearch.scss'
import { Spinner } from 'react-activity'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  field: {
    backgroundColor: '#f9f7f7',
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 400,
    '&:focus': {
      backgroundColor: '#f6fcff',
    },
  },
  button: {
    padding: theme.spacing(2),
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
  },
}))

const initialFormValues = {
  expertise: expertise[0].value,
  practiceArea: 'all',
  postcode: '',
}

const HomeSearch = ({ history }) => {
  const { handleSubmit, control, setValue } = useForm()
  const classes = useStyles()
  const [practiceAreas, setPracticeAreas] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = (data) => {
    setIsSubmitting(true)
    history.push(
      `/search/${data.expertise}/${data.practiceArea}/${data.postcode}`
    )
  }

  useEffect(() => {
    changePracticeAreas(initialFormValues.expertise)
  }, [])

  const changePracticeAreas = (newExpertise) => {
    setPracticeAreas([])
    setValue('practiceArea', 'all')
    if (newExpertise) {
      const tempPracticeAreas = expertise.filter((item) =>
        newExpertise.includes(item.value)
      )
      setPracticeAreas(tempPracticeAreas)
    }
  }

  return (
    <section className='o-homeSearch'>
      <div className='container mx-auto relative flex'>
        <form onSubmit={handleSubmit(onSubmit)} className='o-homeSearch__form'>
          <div className='container o-homeSearch__form--container '>
            <h1 className='o-homeSearch__form--title'>
              Looking for <br />
              <Typed
                strings={['a Lawyer?', 'legal advice?']}
                typeSpeed={50}
                backSpeed={50}
                loop
                backDelay={3000}
              />
            </h1>
            <div className='o-homeSearch__form--fields'>
              <div className='o-homeSearch__form--field'>
                <FormControl
                  fullWidth
                  variant='outlined'
                  className={classes.formControl}
                >
                  <Controller
                    as={
                      <Select
                        name='expertise'
                        IconComponent={KeyboardArrowDown}
                      >
                        {expertise.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    name='expertise'
                    control={control}
                    onChange={([selected]) => {
                      changePracticeAreas(selected.target.value)
                      return selected.target.value
                    }}
                    defaultValue={initialFormValues.expertise}
                  />
                </FormControl>
              </div>
              <div className='o-homeSearch__form--field'>
                <FormControl
                  fullWidth
                  variant='outlined'
                  className={classes.formControl}
                >
                  <Controller
                    as={
                      <Select
                        name='practiceArea'
                        IconComponent={KeyboardArrowDown}
                        displayEmpty
                      >
                        <MenuItem value='all'>All</MenuItem>
                        {practiceAreas.map((item) =>
                          item.options.map((subItem) => {
                            if (!subItem.placeholder) {
                              return (
                                <MenuItem
                                  key={subItem.value}
                                  value={subItem.value}
                                >
                                  {subItem.label}
                                </MenuItem>
                              )
                            }
                            return null
                          })
                        )}
                      </Select>
                    }
                    name='practiceArea'
                    control={control}
                    onChange={([selected]) => {
                      return selected.target.value
                    }}
                    defaultValue={initialFormValues.practiceArea}
                  />
                </FormControl>
              </div>
              <div className='o-homeSearch__form--field'>
                <FormControl
                  fullWidth
                  variant='outlined'
                  className={classes.formControl}
                >
                  <Controller
                    as={
                      <TextField
                        className={classes.field}
                        label='Postcode'
                        variant='outlined'
                        InputProps={{
                          classes: { input: classes.field },
                        }}
                      />
                    }
                    name='postcode'
                    control={control}
                    defaultValue={''}
                    required
                  />
                </FormControl>
              </div>
              <div className='o-homeSearch__form--action'>
                <FormControl
                  fullWidth
                  variant='outlined'
                  className={classes.formControl}
                >
                  <Button
                    className={classes.button}
                    size='large'
                    variant='contained'
                    color='primary'
                    type='submit'
                  >
                    {isSubmitting ? <Spinner color='#fff' /> : 'Search'}
                  </Button>
                </FormControl>
              </div>
            </div>
          </div>
        </form>
        <div className='o-homeSearch__map'>
          <div className='o-homeSearch__map--action'>
            <img src={require('images/animation-header.gif')} alt='' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default withRouter(HomeSearch)
