import React, { useRef, useState } from 'react'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'
import useUserInfo from 'hooks/useUserInfo'

import './Availability.scss'

const initialSchedule = {
  monday: 'Available',
  tuesday: 'Available',
  wednesday: 'Available',
  thursday: 'Available',
  friday: 'Available',
  saturday: 'Available',
  sunday: 'Available',
}

const Availability = () => {
  const elementRef = useRef(null)
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()

  const [value, setValue] = useState({
    ...initialSchedule,
    ...userInfo.availability,
  })

  const handleEdit = () => {
    if (edit) {
      if (value) {
        doUpdateUserProfile({
          availability: value,
        })
      }
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }

  return (
    <div className='m-availability'>
      <header>
        <span>Availability</span>
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <CheckIcon /> : <EditIcon />}
        </button>
      </header>
      <main>
        <div className='m-availability__table'>
          <div className='m-availability__table--header'>
            <span>Day</span>
            <span>Time</span>
          </div>
          <ul className='m-availability__table--body'>
            <li>
              <span>Monday</span>
              {edit ? (
                <input
                  ref={elementRef}
                  value={value.monday}
                  name={'monday'}
                  onChange={handleChange}
                />
              ) : (
                <span>{value.monday}</span>
              )}
            </li>
            <li>
              <span>Tuesday</span>
              {edit ? (
                <input
                  ref={elementRef}
                  value={value.tuesday}
                  name={'tuesday'}
                  onChange={handleChange}
                />
              ) : (
                <span>{value.tuesday}</span>
              )}
            </li>
            <li>
              <span>Wednesday</span>
              {edit ? (
                <input
                  ref={elementRef}
                  value={value.wednesday}
                  name={'wednesday'}
                  onChange={handleChange}
                />
              ) : (
                <span>{value.wednesday}</span>
              )}
            </li>
            <li>
              <span>Thursday</span>
              {edit ? (
                <input
                  ref={elementRef}
                  value={value.thursday}
                  name={'thursday'}
                  onChange={handleChange}
                />
              ) : (
                <span>{value.thursday}</span>
              )}
            </li>
            <li>
              <span>Friday</span>
              {edit ? (
                <input
                  ref={elementRef}
                  value={value.friday}
                  name={'friday'}
                  onChange={handleChange}
                />
              ) : (
                <span>{value.friday}</span>
              )}
            </li>
            <li>
              <span>Saturday</span>
              {edit ? (
                <input
                  ref={elementRef}
                  value={value.saturday}
                  name={'saturday'}
                  onChange={handleChange}
                />
              ) : (
                <span>{value.saturday}</span>
              )}
            </li>
            <li>
              <span>Sunday</span>
              {edit ? (
                <input
                  ref={elementRef}
                  value={value.sunday}
                  name={'sunday'}
                  onChange={handleChange}
                />
              ) : (
                <span>{value.sunday}</span>
              )}
            </li>
          </ul>
        </div>
      </main>
    </div>
  )
}

export default Availability
