import React, { useState, useRef, useEffect } from 'react'
import { ReactComponent as ButtonIcon } from 'images/icon-circle-plus.svg'
import { ReactComponent as CameraIcon } from 'images/icon-camera.svg'
import { ReactComponent as ImageIcon } from 'images/icon-image.svg'
import { ReactComponent as DocIcon } from 'images/icon-doc.svg'

import './ChatActions.scss'
import ChatCamera from './ChatCamera'
import { auth, db, storage } from 'services/firebase'
import moment from 'moment'
import { toast } from 'react-toastify'

const ChatActions = ({ chatId }) => {
  const { uid } = auth.currentUser
  const inputRef = useRef()
  const documentInputRef = useRef()
  const dropdownRef = useRef(null)
  const [showDropdown, setShowDropdown] = useState(null)
  const [showCamera, setShowCamera] = useState(false)
  const handleClickOutsideDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false)
    }
  }

  const handleUploadFile = async (file, fileType) => {
    setShowDropdown(false)
    const filename = file.type.startsWith('image/')
      ? `${Date.now()}.jpg`
      : file.name
    const imagePath = `chats/${chatId}/${filename}`

    const type =
      fileType === 'document'
        ? fileType
        : file.type.startsWith('video/')
        ? 'video'
        : 'image'

    const messageRef = await db.ref(`chatMessages/${chatId}`).push({
      type: 'loading',
      message: filename,
      filename: filename,
      sender: uid,
      timestamp: moment().unix(),
    })

    storage
      .ref()
      .child(imagePath)
      .put(file, {
        customMetadata: {
          chatId,
          messageId: messageRef.key,
        },
      })
      .then(() => {
        return storage
          .ref()
          .child(imagePath)
          .getDownloadURL()
          .then(async (url) => {
            await db
              .ref(`chatMessages/${chatId}/${messageRef.key}/fileUrl`)
              .set(url)
            await db
              .ref(`chatMessages/${chatId}/${messageRef.key}/type`)
              .set(type)
          })
          .catch((err) => {
            toast.error(err.message)
          })
      })
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDropdown)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown)
    }
  }, [])

  return (
    <>
      <div ref={dropdownRef} className='m-chatActions'>
        <div
          className={`m-chatActions__dropdown ${showDropdown ? '-show' : ''}`}
        >
          <button onClick={() => setShowCamera(true)}>
            <i>
              <CameraIcon />
            </i>
            <span>Camera</span>
          </button>
          <button onClick={() => inputRef.current.click()}>
            <i>
              <ImageIcon />
            </i>
            <span>Photo & Video</span>
          </button>
          <button onClick={() => documentInputRef.current.click()}>
            <i>
              <DocIcon />
            </i>
            <span>Document</span>
          </button>
        </div>
        <button
          className={`m-chatActions__trigger ${showDropdown ? '-open' : ''}`}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <ButtonIcon />
        </button>
      </div>
      {showCamera && (
        <ChatCamera chatId={chatId} handleCloseCamera={setShowCamera} />
      )}
      <input
        accept='video/*,image/*'
        type='file'
        id='file'
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={(e) => handleUploadFile(e.target.files[0], 'image')}
      />
      <input
        accept='.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf'
        type='file'
        id='file'
        ref={documentInputRef}
        style={{ display: 'none' }}
        onChange={(e) => handleUploadFile(e.target.files[0], 'document')}
      />
    </>
  )
}

export default ChatActions
