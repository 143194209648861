import React, { useState } from 'react'
import { Checkbox, Button } from '@material-ui/core'
import { ReactComponent as CloseIcon } from 'images/icon-close.svg'
import IOSSwitch from 'components/atoms/IOSSwitch'
import StarFilter from 'components/atoms/StarFilter'
import DistanceFilter from 'components/atoms/DistanceFilter'
import useLawyersSearch from 'hooks/useLawyersSearch'

import { calculateDistance, getLatLngFromPostcode } from 'utils/functions'
import { toast } from 'react-toastify'
import useGetLawyers from 'services/firebase/hooks/lawyers/useGetLawyers'
import './ResultsFilter.scss'

const ResultsFilter = ({ open, handleShowFilters }) => {
  const [lawyers, setLawyers] = useLawyersSearch()
  const doGetLawyers = useGetLawyers()

  const [fixedFees, setFixedFees] = useState(false)
  const [onlineNow, setOnlineNow] = useState(true)
  const [distance, setDistance] = useState(50)
  const [starRating, setStarRating] = useState([])
  const [yearsOfExperience, setYearsOfExperience] = useState([])
  const [hourlyRate, setHourlyRate] = useState([])

  const handleStarRating = (e) => {
    const cloneArr = starRating
    if (e.target.checked) {
      cloneArr.push(e.target.value)
    } else {
      const indx = cloneArr.indexOf(e.target.value)
      cloneArr.splice(indx, 1)
    }
    setStarRating(cloneArr)
  }

  const handleYearsOfExperience = (e) => {
    const cloneArr = yearsOfExperience
    if (e.target.checked) {
      cloneArr.push(e.target.value)
    } else {
      const indx = cloneArr.indexOf(e.target.value)
      cloneArr.splice(indx, 1)
    }
    setYearsOfExperience(cloneArr)
  }

  const handleHourlyRate = (e) => {
    const cloneArr = hourlyRate
    if (e.target.checked) {
      cloneArr.push(e.target.value)
    } else {
      const indx = cloneArr.indexOf(e.target.value)
      cloneArr.splice(indx, 1)
    }
    setHourlyRate(cloneArr)
  }

  const handleDistanceChange = (event, newValue) => {
    setDistance(newValue)
  }

  const getLawyersList = async (postcodeInfo) => {
    const coordinates = {
      latitude: parseFloat(window.localStorage.getItem('current-latitude')),
      longitude: parseFloat(window.localStorage.getItem('current-longitude')),
    }
    if (postcodeInfo) {
      coordinates.latitude = postcodeInfo.geometry.location.lat
      coordinates.longitude = postcodeInfo.geometry.location.lng
    }

    return doGetLawyers({
      ...coordinates,
      distance,
      expertise: window.localStorage.getItem('search-expertise'),
      practiceArea: window.localStorage.getItem('search-practiceArea'),
    }).then((result) => {
      const lawyersList = []
      result.map((item) => {
        lawyersList.push({
          ...item,
          distance: calculateDistance(
            item.l.latitude,
            item.l.longitude,
            window.localStorage.getItem('current-latitude'),
            window.localStorage.getItem('current-longitude')
          ),
        })
      })
      setLawyers({ original: lawyersList, withFilters: lawyersList })
      return lawyersList
    })
  }

  const applyFilters = async () => {
    let currentLawyerList = lawyers.original
    const currentDistance = window.localStorage.getItem('search-distance')
    const currentPostcode = window.localStorage.getItem('search-postcode')
    if (parseInt(currentDistance) !== distance) {
      console.log('searching on firestore...')
      let postcodeInfo = null
      if (currentPostcode) {
        postcodeInfo = await getLatLngFromPostcode(currentPostcode).then(
          (result) => {
            if (result.length > 0) {
              return result[0]
            }
            toast.error(
              'Please make sure the postcode is correct or leave it blank to get your current location.'
            )
            return null
          }
        )
      }

      if (currentPostcode && !postcodeInfo) return false
      currentLawyerList = await getLawyersList(postcodeInfo)
    }

    if (fixedFees) {
      currentLawyerList = currentLawyerList.filter(
        (item) => item.fixedFee === true
      )
    }
    if (onlineNow) {
      currentLawyerList = currentLawyerList.filter(
        (item) => item.onlineNow === true
      )
    }

    if (starRating.length > 0) {
      currentLawyerList = currentLawyerList.filter((item) =>
        starRating.includes(Math.floor(item.rating).toString())
      )
    }

    if (yearsOfExperience.length > 0) {
      currentLawyerList = currentLawyerList.filter((item) =>
        yearsOfExperience.includes(item.practisingSince.toString())
      )
    }

    if (hourlyRate.length > 0) {
      const rates = []
      let moreExpenseLawyers = []
      let moreExpense = false
      hourlyRate.map((item) => {
        const currentItem = item.split('-')
        if (typeof currentItem[1] !== 'undefined') {
          rates.push(parseInt(currentItem[0]))
          rates.push(parseInt(currentItem[1]))
        } else {
          moreExpense = true
        }
      })

      const minValue = rates.reduce((min, b) => Math.min(min, b), rates[0])
      const maxValue = rates.reduce((min, b) => Math.max(min, b), rates[0])

      if (moreExpense) {
        moreExpenseLawyers = currentLawyerList.filter(
          (item) => item.hourPrice >= 500
        )
      }

      const otherCosts = currentLawyerList.filter(
        (item) => item.hourPrice >= minValue && item.hourPrice <= maxValue
      )
      currentLawyerList = otherCosts.concat(moreExpenseLawyers)
    }
    setLawyers({ ...lawyers, withFilters: currentLawyerList })
    handleShowFilters(false)
  }

  return (
    <div className={`m-resultsFilter ${open ? '-show' : ''}`}>
      <div className='m-resultsFilter__header'>
        <strong>Filter Options</strong>
        <button onClick={() => handleShowFilters(false)}>
          <CloseIcon />
        </button>
      </div>
      <div className='m-resultsFilter__content'>
        <div className='m-resultsFilter__content--option'>
          <div className='m-resultsFilter__content--label'>Fixed Fees</div>
          <div className='m-resultsFilter__content--field'>
            <IOSSwitch
              label='Offers ﬁxed fees'
              value='fixed-fees'
              checked={fixedFees}
              handleChange={setFixedFees}
            />
          </div>
        </div>
        <div className='m-resultsFilter__content--option'>
          <div className='m-resultsFilter__content--label'>Online now</div>
          <div className='m-resultsFilter__content--field'>
            <IOSSwitch
              label='Online now'
              value='online'
              checked={onlineNow}
              handleChange={setOnlineNow}
            />
          </div>
        </div>

        <div className='m-resultsFilter__content--option'>
          <div className='m-resultsFilter__content--label'>Star Rating</div>
          <div className='m-resultsFilter__content--field'>
            <StarFilter stars={5} handleChange={handleStarRating} value={5} />
            <StarFilter stars={4} handleChange={handleStarRating} value={4} />
            <StarFilter stars={3} handleChange={handleStarRating} value={3} />
            <StarFilter stars={2} handleChange={handleStarRating} value={2} />
            <StarFilter stars={1} handleChange={handleStarRating} value={1} />
          </div>
        </div>
        <div className='m-resultsFilter__content--option'>
          <div className='m-resultsFilter__content--label'>
            Years of Experience
          </div>
          <div className='m-resultsFilter__content--field -experience'>
            <label>
              <Checkbox
                color='primary'
                checked={yearsOfExperience.find((item) => item === '20')}
                onChange={handleYearsOfExperience}
                value={20}
              />
              <span>20 + years</span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={yearsOfExperience.find((item) => item === '10')}
                onChange={handleYearsOfExperience}
                value={10}
              />
              <span>10 + years</span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={yearsOfExperience.find((item) => item === '5')}
                onChange={handleYearsOfExperience}
                value={5}
              />
              <span>5 + years</span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={yearsOfExperience.find((item) => item === '1')}
                onChange={handleYearsOfExperience}
                value={1}
              />
              <span>1 + year</span>
            </label>
          </div>
        </div>
        <div className='m-resultsFilter__content--option -fullWidth'>
          <div className='m-resultsFilter__content--label'>Distance</div>
          <div className='m-resultsFilter__content--field -distance'>
            <DistanceFilter handleChange={handleDistanceChange} />
          </div>
        </div>
        <div className='m-resultsFilter__content--option -fullWidth'>
          <div className='m-resultsFilter__content--label'>Hourly Rate</div>
          <div className='m-resultsFilter__content--field -hourlyRate'>
            <label>
              <Checkbox
                color='primary'
                checked={hourlyRate.find((item) => item === '50-100')}
                onChange={handleHourlyRate}
                value='50-100'
              />
              <span>£50 - £100 </span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={yearsOfExperience.find((item) => item === '100-200')}
                onChange={handleHourlyRate}
                value='100-200'
              />
              <span>£100 - £200 </span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={yearsOfExperience.find((item) => item === '200-300')}
                onChange={handleHourlyRate}
                value='200-300'
              />
              <span>£200 - £300 </span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={yearsOfExperience.find((item) => item === '300-400')}
                onChange={handleHourlyRate}
                value='300-400'
              />
              <span>£300 - £400 </span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={yearsOfExperience.find((item) => item === '500')}
                onChange={handleHourlyRate}
                value='500'
              />
              <span>£500 +</span>
            </label>
          </div>
        </div>
        <div className='m-resultsFilter__content--action'>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={applyFilters}
          >
            Save Filter
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ResultsFilter
