import React from 'react'
import { Checkbox } from '@material-ui/core'
import { ReactComponent as StarIcon } from 'images/icon-star.svg'
import './StarFilter.scss'

const drawStars = (total) => {
  const stars = []
  for (let s = 1; s <= total; s++) {
    stars.push(<StarIcon key={`star-${s}`} />)
  }
  return stars
}

const StarFilter = ({ value, checked, handleChange, stars }) => {
  return (
    <label className='a-starFilter'>
      <div className='a-starFilter__checkbox'>
        <Checkbox
          color='primary'
          checked={checked}
          onChange={handleChange}
          value={value}
        />
      </div>
      <div className='a-starFilter__stars'>{drawStars(stars)}</div>
      <div className='a-starFilter__label'>{stars} Stars</div>
    </label>
  )
}

export default StarFilter
