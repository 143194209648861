import { useCallback } from 'react'
import { firestore } from 'services/firebase'

const useGetCaseReviews = () => {
  return useCallback(async (caseId) => {
    return firestore
      .collection('cases')
      .doc(caseId)
      .collection('reviews')
      .get()
      .then(async (snap) => {
        if (snap.size > 0) {
          return snap.docs.map((item) => item.data())
        }
        return []
      })
  }, [])
}

export default useGetCaseReviews
