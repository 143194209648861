import React, { useEffect, useState, useRef } from 'react'
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  FormHelperText,
  Button,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { formatter } from 'utils/functions'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import { expertise } from 'utils/constants'
import useGetContacts from 'services/firebase/hooks/lawyers/useGetContacts'
import Loader from 'components/atoms/Loader'
import { useForm, Controller } from 'react-hook-form'
import './NewOffer.scss'
import useRegisterOffer from 'services/firebase/hooks/cases/useRegisterOffer'
import MomentUtils from '@date-io/moment'
import useUserInfo from 'hooks/useUserInfo'
import { Spinner } from 'react-activity'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'transparent',
    margin: theme.spacing(2, 0),
    minWidth: 120,
    border: 'none',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    background: 'transparent',
  },
  button: {
    padding: theme.spacing(2),
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
  },
  helperText: {
    margin: 0,
    padding: '5px 0 0 0',
    color: '#999',
  },
  date: {
    marginRight: 0,
  },
  field: {
    '&:disabled': {
      opacity: 0.3,
    },
  },
}))

const NewOffer = ({ history }) => {
  const [user] = useUserInfo()

  const classes = useStyles()
  const doGetContacts = useGetContacts()
  const doRegisterOffer = useRegisterOffer()

  const { setValue, handleSubmit, control, watch, errors } = useForm()

  const [labelSizes, setLabelSizes] = useState('')
  const [contacts, setContacts] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const toElement = useRef(null)
  const forElement = useRef(null)
  const paymentTypeElement = useRef(null)

  useEffect(() => {
    if (user.role !== 'lawyer') {
      return history.push('/panel/cases')
    }

    doGetContacts().then((contacts) => {
      setContacts(contacts)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setLabelSizes({
        to: toElement.current.offsetWidth,
        for: forElement.current.offsetWidth,
        paymentType: paymentTypeElement.current.offsetWidth,
      })
    }
  }, [isLoading])

  useEffect(() => {
    if (watch('paymentType') === 'hourlyRate') {
      setValue('amount', watch('estimatedHours') * watch('rate'))
    }
  }, [watch('estimatedHours')])

  useEffect(() => {
    if (watch('paymentType') === 'noWinNoFee') {
      setValue('clientRate', 100 - watch('rate'))
    }
    if (watch('paymentType') === 'hourlyRate') {
      setValue('amount', watch('estimatedHours') * watch('rate'))
    }
  }, [watch('rate')])

  const onSubmit = (data) => {
    if (!isSubmitting) {
      setIsSubmitting(true)
      doRegisterOffer(data, user, history)
    }
  }

  const handleChangeData = (date) => {
    setValue('deadline', date)
  }

  useEffect(() => {
    if (Object.entries(errors).length > 0) {
      toast.error('All fields are mandatory.')
    }
  }, [errors])

  return isLoading ? (
    <Loader />
  ) : (
    <div className='p-newOffer'>
      <div className='p-newOffer__container'>
        <div className='p-newOffer__header'>
          <strong>New Offer</strong>
        </div>
        <div className='p-newOffer__content'>
          <form onSubmit={handleSubmit(onSubmit)} className='p-newOffer__form'>
            <div className='p-newOffer__form--field'>
              <FormControl
                fullWidth
                variant='outlined'
                className={classes.formControl}
              >
                <InputLabel ref={toElement}>To</InputLabel>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name='receiverUid'
                  onChange={([selected]) => {
                    return selected.target.value
                  }}
                  as={
                    <Select
                      classes={{
                        select: classes.select,
                      }}
                      labelWidth={labelSizes.to}
                      IconComponent={KeyboardArrowDown}
                    >
                      {contacts.map((contact) => {
                        if (!contact) return false
                        return (
                          <MenuItem key={contact.uid} value={contact.uid}>
                            {contact.displayName
                              ? contact.displayName
                              : `${contact.name} ${contact.surname}`}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  }
                  defaultValue={{}}
                />
              </FormControl>
            </div>
            <div className='p-newOffer__form--field'>
              <FormControl
                fullWidth
                variant='outlined'
                className={classes.formControl}
              >
                <InputLabel ref={forElement}>For</InputLabel>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name='for'
                  onChange={([selected]) => {
                    return selected.target.value
                  }}
                  as={
                    <Select
                      classes={{
                        select: classes.select,
                      }}
                      labelWidth={labelSizes.for}
                      IconComponent={KeyboardArrowDown}
                    >
                      {expertise.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  defaultValue={{}}
                />
              </FormControl>
            </div>
            <div className='p-newOffer__form--field'>
              <FormControl
                fullWidth
                variant='outlined'
                className={classes.formControl}
              >
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name='description'
                  as={
                    <TextField
                      inputProps={{
                        maxLength: 120,
                      }}
                      label='Description'
                      placeholder='Description of what services you will be providing. Be as specific as possible. (120 characters max)'
                      className={classes.field}
                      variant='outlined'
                      multiline
                      rows={4}
                    />
                  }
                  defaultValue=''
                />
                <FormHelperText className={classes.helperText}>
                  {watch('description') ? watch('description').length : 0}/120
                </FormHelperText>
              </FormControl>
            </div>
            <div className='p-newOffer__form--field -paymentType'>
              <FormControl
                fullWidth
                variant='outlined'
                className={classes.formControl}
              >
                <InputLabel ref={paymentTypeElement}>Payment Type</InputLabel>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name='paymentType'
                  onChange={([selected]) => {
                    setValue('amount', '')
                    return selected.target.value
                  }}
                  as={
                    <Select
                      classes={{
                        select: classes.select,
                      }}
                      labelWidth={labelSizes.paymentType}
                      IconComponent={KeyboardArrowDown}
                    >
                      <MenuItem value='fixedFee'>Fixed Fee</MenuItem>
                      <MenuItem value='legalAid'>Legal Aid</MenuItem>
                      <MenuItem value='hourlyRate'>Hourly Rate</MenuItem>
                      <MenuItem value='noWinNoFee'>No win No fee</MenuItem>
                    </Select>
                  }
                  defaultValue='fixedFee'
                />
              </FormControl>
            </div>
            <div className='p-newOffer__form--field -amount'>
              <FormControl
                fullWidth
                variant='outlined'
                className={classes.formControl}
              >
                {watch('paymentType') === 'hourlyRate' ||
                watch('paymentType') === 'noWinNoFee' ? (
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name='rate'
                    as={
                      <TextField
                        label='Rate'
                        className={classes.field}
                        variant='outlined'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              {watch('paymentType') === 'noWinNoFee'
                                ? '%'
                                : '£'}
                            </InputAdornment>
                          ),
                        }}
                        type='number'
                      />
                    }
                    defaultValue=''
                  />
                ) : watch('paymentType') === 'legalAid' ? (
                  <TextField
                    label='Amount'
                    className={classes.field}
                    variant='outlined'
                    disabled={watch('paymentType') === 'legalAid'}
                    InputProps={{
                      classes: {
                        input: classes.field,
                      },
                      disabled: watch('paymentType') === 'legalAid',
                      startAdornment: (
                        <InputAdornment position='start'>£</InputAdornment>
                      ),
                    }}
                    type='number'
                  />
                ) : (
                  <Controller
                    control={control}
                    rules={{
                      required: watch('paymentType') !== 'legalAid',
                    }}
                    name='amount'
                    onChange={([selected]) => {
                      return selected.target.value
                    }}
                    as={
                      <TextField
                        label='Amount'
                        className={classes.field}
                        variant='outlined'
                        disabled={watch('paymentType') === 'legalAid'}
                        InputProps={{
                          classes: {
                            input: classes.field,
                          },
                          disabled: watch('paymentType') === 'legalAid',
                          startAdornment: (
                            <InputAdornment position='start'>£</InputAdornment>
                          ),
                        }}
                        type='number'
                      />
                    }
                    defaultValue=''
                  />
                )}
              </FormControl>
            </div>
            {watch('paymentType') === 'noWinNoFee' && (
              <>
                <div className='p-newOffer__form--field -noWinNoFee'>
                  <p>
                    This percentage is the amount you agree to receive from the
                    &apos;settled&apos; or &apos;recovered funds&apos; for your
                    services. <br />
                    Remember to account for Lawyerup&apos;s fee within your
                    rate. <br />
                    Lawyerup&apos;s fee must be paid on completion of the case.
                  </p>
                </div>
                <div className='p-newOffer__form--calculation'>
                  <span>Lawyerup 10% + VAT:</span>
                  <span>{(watch('rate') * 0.12).toFixed(2)}%</span>
                </div>
              </>
            )}
            {watch('paymentType') === 'hourlyRate' && (
              <>
                <div className='p-newOffer__form--field -estimatedHours'>
                  <span>Estimated Hours</span>
                  <FormControl
                    variant='outlined'
                    className={classes.formControl}
                  >
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name='estimatedHours'
                      as={
                        <TextField
                          className={classes.field}
                          variant='outlined'
                          type='number'
                        />
                      }
                      defaultValue=''
                    />
                  </FormControl>
                </div>
              </>
            )}

            {(watch('paymentType') === 'noWinNoFee' ||
              watch('paymentType') === 'hourlyRate') && (
              <div className='p-newOffer__form--field -total'>
                <span>
                  {watch('paymentType') === 'noWinNoFee'
                    ? 'Client will receive:'
                    : 'Total:'}
                </span>
                <FormControl variant='outlined' className={classes.formControl}>
                  <Controller
                    control={control}
                    rules={{ required: watch('paymentType') !== 'legalAid' }}
                    name={
                      watch('paymentType') === 'noWinNoFee'
                        ? 'clientRate'
                        : 'amount'
                    }
                    as={
                      <TextField
                        className={classes.field}
                        variant='outlined'
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <InputAdornment position='start'>
                              {watch('paymentType') === 'noWinNoFee'
                                ? '%'
                                : '£'}
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                    defaultValue=''
                  />
                </FormControl>
              </div>
            )}

            {watch('paymentType') === 'legalAid' && (
              <div className='p-newOffer__form--calculation -legalAid'>
                <p>
                  If eligible for legal aid, zero commission will be applied.
                  You must however send this offer to respective client to
                  confirm your services have been instructed.
                </p>
              </div>
            )}
            {watch('amount') > 0 &&
              watch('paymentType') !== 'legalAid' &&
              watch('paymentType') !== 'noWinNoFee' && (
                <>
                  <div className='p-newOffer__form--calculation'>
                    <span>Lawyerup 10% + VAT:</span>
                    <span>{formatter.format(watch('amount') * 0.12)}</span>
                  </div>
                  <div className='p-newOffer__form--calculation'>
                    <span>You receive after completion:</span>
                    <span>{formatter.format(watch('amount') * 0.88)}</span>
                  </div>
                </>
              )}
            <div className='p-newOffer__form--field -completionDate'>
              <span>Completion Date</span>
              <FormControl variant='outlined' className={classes.formControl}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name='deadline'
                  defaultValue={moment()}
                  as={
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        KeyboardButtonProps={{
                          class: classes.date,
                        }}
                        disablePast
                        placeholder='DD/MM/YYYY'
                        className={classes.date}
                        inputVariant='outlined'
                        format='DD/MM/YYYY'
                        value={watch('deadline')}
                        onChange={handleChangeData}
                      />
                    </MuiPickersUtilsProvider>
                  }
                />
              </FormControl>
            </div>
            <div className='p-newOffer__form--action'>
              <Button
                fullWidth
                className={classes.button}
                size='large'
                variant='contained'
                color='primary'
                type='submit'
              >
                {isSubmitting ? <Spinner color='#fff' /> : 'Send Offer'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default NewOffer
