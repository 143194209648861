import React from 'react'
import Avatar from 'react-avatar'
import useUserInfo from 'hooks/useUserInfo'
import StarRatings from 'react-star-ratings'

import Address from 'components/molecules/PanelFormItems/Address'
import Contacts from 'components/molecules/PanelFormItems/Contacts'
import Linkedin from 'components/molecules/PanelFormItems/Linkedin'

import './LawyerProfile.scss'
import InviteYourFirm from 'components/molecules/Profile/InviteYourFirm'
import AboutMe from 'components/molecules/PanelFormItems/AboutMe'
import MainExpertise from 'components/molecules/PanelFormItems/Expertise'
import HourlyRates from 'components/molecules/PanelFormItems/HourlyRates'
import Availability from 'components/molecules/PanelFormItems/Availability'
import Team from 'components/molecules/PanelFormItems/Team'
import BankAccount from 'components/molecules/PanelFormItems/BankAccount'

const LawyerProfile = () => {
  const [userInfo] = useUserInfo()

  return (
    <div className='o-lawyerProfile'>
      <div className='o-lawyerProfile__container'>
        <div className='o-lawyerProfile__aside'>
          <div className='o-lawyerProfile__aside--box'>
            <div className='o-lawyerProfile__aside--avatar'>
              <Avatar
                size='170'
                maxInitials={2}
                round='100%'
                color='#18a1db'
                textSizeRatio={2.5}
                src={userInfo.photoURL}
                name={`${userInfo.name} ${userInfo.surname}`}
              />
            </div>
            <div className='o-lawyerProfile__aside--info'>
              <h2>{`${userInfo.name} ${userInfo.surname}`}</h2>
              <div className='o-lawyerProfile__aside--info-rating'>
                <StarRatings
                  svgIconPath='M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
              c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
              L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438
              c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701
              c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0
              l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904
              l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z'
                  starDimension={'18px'}
                  starRatedColor='#ffc107'
                  rating={userInfo.rating || 4.3}
                  numberOfStars={5}
                  starSpacing={'3px'}
                />
                <span>{userInfo.rating || 4.3}</span>
              </div>
            </div>
            <div className='o-lawyerProfile__aside--form'>
              <Address />
              <Contacts />
              <BankAccount />

              <Linkedin />
              {userInfo.srcOisc && (
                <div className='o-lawyerProfile__aside--sra'>
                  <main>
                    <span>SRA ID / OISC no: {userInfo.srcOisc}</span>
                  </main>
                </div>
              )}
            </div>
          </div>
          <div className='o-lawyerProfile__aside--inviteYourFirm'>
            <InviteYourFirm />
          </div>
        </div>
        <div className='o-lawyerProfile__content'>
          <div className='o-lawyerProfile__content--filter'>
            <strong>Profile</strong>
          </div>
          <div className='o-lawyerDashboard__content--box'>
            <AboutMe />
          </div>
          <div className='o-lawyerDashboard__content--box'>
            <MainExpertise />
          </div>
          <div className='o-lawyerDashboard__content--box'>
            <HourlyRates />
          </div>
          <div className='o-lawyerDashboard__content--box'>
            <Availability />
          </div>
          <div className='o-lawyerDashboard__content--box -full'>
            <Team />
          </div>

          <div className='o-lawyerProfile__content--inviteYourFirm'>
            <InviteYourFirm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LawyerProfile
