import { useCallback } from 'react'
import { firestore } from 'services/firebase'

const useGetUnregisteredLawyerByUid = () => {
  return useCallback(async (uid) => {
    return firestore.collection('unregistered_users').doc(uid).get()
  }, [])
}

export default useGetUnregisteredLawyerByUid
