import React from 'react'
import './OurTerms.scss'

const OurTerms = () => {
  const email = 'ku.gro.p*ur|*eyw*al@maet'

  const formatT = (txt) => {
    return txt
      .replace(new RegExp(/\|/, 'g'), '')
      .replace(new RegExp(/\*/, 'g'), '')
      .split('')
      .reverse()
      .join('')
  }

  return (
    <div className='o-ourTerms'>
      <p>
        <b>
          THESE TERMS AND CONDITIONS (&quot;TERMS&quot;) SET OUT THE TERMS AND
          CONDITIONS ON WHICH ECLATECH LIMITED T/A LAWYERUP
          (&quot;ECLATECH&quot;; &quot;WE&quot;; &quot;US&quot;) WITH COMPANY
          NUMBER 11777022 AND HAVING ITS REGISTERED ADDRESS AT 4 OLD PARK LANE,
          MAYFAIR, LONDON, UNITED KINGDOM, W1K 1QW SHALL OFFER ACCESS TO ITS
          WEBSITE WWW.LAWYERUP.ORG.UK (&quot;WEBSITE&quot;) TO YOU THE LAWYER OR
          LEGAL PROFESSIONAL (&quot;YOU&quot; or &quot;LAWYER&quot;) SEEKING TO
          CONNECT WITH USERS (&quot;USERS&quot;) TO PROVIDE SUCH LEGAL SERVICES.
        </b>
      </p>
      <p>
        <b>
          PLEASE READ THESE TERMS VERY CAREFULLY BEFORE USING THE WEBSITE. YOU
          ACKNOWLEDGE THAT BY ACCESSING THIS WEBSITE YOU AGREE TO BE BOUND BY
          THESE TERMS. IF YOU ARE USING THIS WEBSITE ON BEHALF OF A LAW FIRM,
          YOU WARRANT THAT YOU ARE AUTHORISED TO ENTER INTO LEGALLY BINDING
          CONTRACTS ON BEHALF OF THE LAW FIRM. IF YOU DO NOT ACCEPT THESE TERMS
          AND CONDITIONS, OR ARE NOT AUTHORISED TO ENTER INTO CONTRACTS ON
          BEHALF OF THE LAW FIRM DO NOT CONTINUE WITH YOUR USE OF THE WEBSITE.
        </b>
      </p>
      <p className='gray'>
        IMPORTANT NOTICE: For the actual provision of services listed on this
        Website by a lawyer, Users (which includes any Lawyer acting in the
        capacity as a recipient of services) will enter into a contract directly
        with the Lawyer providing the services. Eclatech is not a party to that
        contract, and Users and Lawyers will be separately responsible and
        liable to one another under the terms of that contract. Eclatech simply
        connects Users and Lawyers through the Website.
      </p>
      <p>
        <b>
          Please note that these Terms only apply to our Lawyers; if you are a
          User, please refer to terms set out here
          www.lawyerup.org.uk/terms_condition, or contact us {formatT(email)}.
        </b>
      </p>

      <p>
        <b>DEFINITIONS</b>
      </p>
      <ul>
        <li>
          <b>&quot;User(s)&quot;</b> means a person who uses www.lawyerup.org.uk
          or www.lawyer-up.co.uk website or Lawyerup IOS or Andriod app.
        </li>
        <li>
          <b>&quot;Services&quot;</b> include but are not limited to:
          <ul>
            <li>Search</li>
            <li>Call</li>
            <li>Video Chat</li>
            <li>Message</li>
            <li>Send files (pictures, documents, voicenotes..etc)</li>
            <li>Booking / Scheduling meetings</li>
            <li>Receiving contact from clients</li>
            <li>Responding to clients</li>
            <li>Using maps</li>
            <li>Sending offers</li>
            <li>Starting cases</li>
            <li>Completing cases</li>
          </ul>
        </li>
        <li>
          <b>&quot;Legal Services&quot;</b> means any legal services provided by
          You to a User via a Contact Request or a Subsequent Request.
        </li>
        <li>
          <b>&quot;Profile&quot;</b> means a public page on Lawyerup containing:
          <ul>
            <li>Your Picture</li>
            <li>Your name</li>
            <li>Your law firm name</li>
            <li>Your law firm address</li>
            <li>Your practise areas of expertise</li>
            <li>Your availability for meetings</li>
            <li>Your hourly rates</li>
            <li>A map showing the location of your office</li>
            <li>Client rating and feedback</li>
            <li>
              Contact functions for clients to get in touch (Call, Video,
              Message, Meeting)
            </li>
            <li>
              Other documents, and/or information about the Lawyers&apos; quotes
              for the provision of service
            </li>
            <li>
              And any other such information as we may agree from time to time
            </li>
          </ul>
        </li>
        <li>
          <b>&quot;Contact Request&quot;</b> is when a user clicks one of the
          contact functions on www.lawyerup.org.uk website or Lawyerup IOS or
          Andriod app:
          <ul>
            <li>Call</li>
            <li>Video</li>
            <li>Message</li>
            <li>Meeting</li>
            <li>
              And any other function that may be made available from time to
              time
            </li>
          </ul>
        </li>
        <li>
          <b>&quot;Login Details&quot;</b> means:
          <ul>
            <li>Username (the email you registered)</li>
            <li>Password (the password you chose when you registered)</li>
          </ul>
        </li>
        <li>
          <b>&quot;Converted Client&quot;</b> means any User who has agreed to
          receive the Legal Services.
        </li>
        <li>
          <b>&quot;Fees&quot;</b> means 90% (plus VAT) of the total fees for the
          Legal Services.
        </li>
        <li>
          <b>&quot;Intellectual Property Rights&quot;</b> means all registered
          or unregistered intellectual property rights throughout the world,
          including: rights in patents rights, copyrights, trademarks rights,
          rights in trade secrets, designs, databases rights, domain names,
          designs, and moral rights, and any other intellectual property rights
          (registered or unregistered) throughout the world.
        </li>
        <li>
          <b>&quot;Force Majeure Events&quot;</b> means one or more of the
          following causes which renders the Services impossible, extremely
          impracticable, or unsafe: fire; threat(s) or act(s) of terrorism;
          riot(s) or other form(s) of civil disorder in, around, or near the
          performance of the Services; strike, lockout, or other forms of labour
          difficulties; any act, order, rule, or regulation of any court,
          government agency, or public authority; act of God; absence of power
          or other essential services; failure of technical facilities; and/or
          any similar or dissimilar cause beyond Eclatech&apos;s reasonable
          control.
        </li>
        <li>
          <b>&quot;Subsequent Request(s)&quot;</b> means a request for advice or
          assistance with Legal Services which may include (a) further advice in
          relation to the same matter as the Contact Request; or (b) relate to
          any new matter.
        </li>
        <li>
          <b>&quot;Submission(s)&quot;</b> means any information, comments,
          images, third party URL links or other material whatsoever in any
          format, details, descriptions or materials submitted by Lawyers in
          relation to discussions or projects and information, details,
          descriptions and materials submitted by Users.
        </li>
        <li>
          <b>&quot;Confidential Information&quot;</b>means all material,
          non-public, business-related information, written or oral, whether or
          not it is marked as such, that is disclosed or made available to the
          receiving party, directly or indirectly, through any means of
          communication or observation.
        </li>
        <li>
          <b>&quot;GDPR&quot;</b>means the General Data Protection Regulation,
          the EU law that effectively replaced the Data Protection Act 1998.
        </li>
        <li>
          <b>&quot;Payment Portal&quot;</b> is the online gateway where payment
          information is stored, and payment functions are enabled. This is
          located after Login, within Profile, on the left bank details column.
        </li>
        <li>
          <b>&quot;VAT&quot;</b> means any value added taxes.
        </li>
      </ul>
      <ol>
        <li>
          <b>INFORMATION ABOUT ECLATECH AND THE WEBSITE</b>
          <ol>
            <li>
              The Website is owned and managed, operated and maintained by
              ECLATECH LIMITED.
            </li>
            <li>
              Eclatech may be contacted at 4 Old Park Lane, Mayfair, London W1K
              1QW or by email at {formatT(email)}
            </li>
            <li>
              The Website is an online network that provides a means for Lawyers
              to connect with Users who could potentially become Converted
              Clients and pay for legal services. The features and functionality
              of Lawyerup&apos;s platform offer Services to lawyers, Users and
              Converted Clients.
              <ol>
                <li>
                  Lawyers can create a Profile to describe themselves and their
                  business, which is accessible by Users.
                </li>
                <li>
                  Users can contact Lawyers via a Contact Request in order to
                  seek advice or assistance with a case or any other Legal
                  Services (&quot;Legal Services&quot;) in respect of which they
                  are looking to engage a Lawyer.
                </li>
                <li>
                  Lawyers can then respond to the User by answering their
                  Contact Request and agreeing to perform the legal services for
                  the User.
                </li>
                <li>
                  Following a Contact Request, Users or Converted Clients can
                  continue to contact the Lawyer via their office telephone
                  number, messaging, scheduling a meeting or otherwise on
                  lawyerup&apos;s platform in order to request advice or
                  assistance with legal services which may include (a) further
                  advice in relation to the same matter as the Contact Request;
                  or (b) relate to any new matter (each, a &quot;Subsequent
                  Request&quot;).
                </li>
                <li>
                  Converted clients can make payments to Lawyers via the payment
                  portal which holds fees until a case has been completed.
                </li>
                <li>
                  Lawyers can make payments to Eclatech for Fees via the Payment
                  Portal; and
                </li>
                <li>
                  Eclatech Ltd can make payments to Eclatech for Fees via Stripe
                  payment portal
                </li>
                <li>
                  Users can provide feedback and rate the performance of Lawyers
                  by providing reviews by email.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>PRELIMINARY INFORMATION</b>
          <ol>
            <li>
              By using the Website, you warrant that:
              <ol>
                <li>
                  You are legally capable of entering into binding contracts;
                </li>
                <li>
                  You have a valid SRA ID number or OISC number proving you can
                  practise law in the United Kingdom.
                </li>
                <li>
                  You have an upto date valid practising certificate and valid
                  PI insurance.
                </li>
                <li>
                  You are not in any way prohibited by the applicable law in the
                  jurisdiction in which you are currently located to enter into
                  these Terms; and
                </li>
                <li>
                  If an individual, you are at least 18 years old. If you are
                  under the age of 18, you should ask a parent or guardian over
                  the age of 18 to enter into these Terms on your behalf. As a
                  parent or guardian of someone under the age of 18, you are
                  responsible for ensuring that person's use of the Website
                  and/or Services is in accordance with these Terms.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>REGISTRATION</b>
          <ol>
            <li>
              In order to access and participate in the Services on the Website
              and App you will first be required to register with Eclatech by
              completing the applicable online registration form made available
              to you on the Website or App.
            </li>
            <li>
              Upon the completion and submission of the online registration form
              on the Website, you shall be sent a confirmation email to the
              email address you provided on the registration form. This
              confirmation email will notify you that your Profile is
              accessible.
            </li>
            <li>
              In order to accept payments from Eclatech Ltd you will then need
              to complete the credentials modal and be required to add your bank
              details for the account you are able to accept fees into.
            </li>
            <li>
              You hereby warrant that the information you provide to Eclatech is
              true, accurate and correct. You further warrant that you shall
              promptly notify Eclatech in the event of any changes to such
              information.
            </li>
            <li>
              You must keep your Login Details for the Website and App
              confidential and secure. We reserve the right to promptly disable
              your Login Details and suspend your access to the Services and/or
              the Website in the event we have any reason to believe that you
              have breached any of the provisions set out herein.
            </li>
            <li>
              You must promptly notify Eclatech in the event there is any actual
              or suspected breach of security or any unauthorised use or
              attempted use of your Login Details. Eclatech may be contacted by
              email at {formatT(email)}.
            </li>
            <li>
              You agree that at all times, you shall:
              <ol>
                <li>
                  Not use your Login Details with the intent of impersonating
                  another person;
                </li>
                <li>Not allow any other person to use your Login Details;</li>
                <li>
                  Not use the Website/App if your SRA ID or OISC number has
                  expired or is invalid for any reason.
                </li>
                <li>
                  Not use the information presented on the Website/App or
                  derived from the Services for any purposes other than those
                  expressly set out in these Terms;
                </li>
                <li>
                  Not do anything likely to impair, interfere with or damage or
                  cause harm or distress to any persons using the Website/App or
                  in respect of the network;
                </li>
                <li>
                  Not use the Website/App, the content therein and/or do
                  anything that will infringe anyone&apos;s intellectual
                  property rights as defined in clause 10 or other rights of any
                  third parties;
                </li>
                <li>
                  Comply with all instructions and policies from Eclatech from
                  time to time in respect of the Services and the Website and
                  App;
                </li>
                <li>
                  Co-operate with any reasonable security or other checks or
                  requests for information made by Eclatech from time to time;
                  and
                </li>
                <li>
                  Use the information made available to you on the Website
                  and/or App and through the Services at your own risk.
                </li>
              </ol>
            </li>
            <li>
              You may request to cancel your registration with the Website and
              App at any time during the registration process.
            </li>
          </ol>
        </li>
        <li>
          <b>FEES AND PAYMENT</b>
          <ol>
            <li>
              We will arrange for the release of the Fees for any Legal Services
              provided by You to any Converted Client within [14] days of the
              Converted Client confirming to Us that You have delivered and
              completed the Services.
            </li>
            <li>
              A User will be a Converted Client with respect to any Subsequent
              Request(s) for a period of five years from the date of the
              User&apos;s first Contact Request, and clause 4.1 will apply to
              all such Subsequent Request(s). You agree that You will not ask
              any Converted Client to make payment to You directly or by any
              other means not in accordance with this clause 4.
            </li>
            <li>
              For the purposes of any Contact Request or Subsequent Request: (a)
              relating to criminal proceedings; and/or (b) where a User benefits
              from any type of legal aid, the definition of &quot;Fees&quot;
              will be 100% (plus VAT) of the total fees for the Legal Services.
            </li>
            <li>
              For the purposes or any Contact Request or Subsequent Request
              relating to a property purchase where it is standard practice for
              You to hold User funds as part of that purchase, any such funds
              are not subject to this clause 4.
            </li>
            <li>
              We may decline to provide the Fees to You for any reason,
              including where fraud is suspected or where the Converted Client
              has not deposited their payment due to any related dispute,
              including relating to the payment or the delivery or completion of
              the Legal Services.
            </li>
            <li>
              In the event that payment of the Fee is not received by Us by the
              due date:
              <ol>
                <li>
                  The provision of the Services shall be suspended and any
                  material published on the Website in connection with the
                  provision of the Services shall be removed from the Website;
                  and
                </li>
                <li>
                  We reserve the right to charge interest on any payment not
                  made by the due date for cases which are no win no fee, or any
                  kind of settlements. Interest will be calculated on a daily
                  basis, both before and after any judgement, at the rate of 2
                  per cent per annum above the base rate from time to time of
                  Bank of England, for the period from the due date until the
                  date on which it is actually paid, compounded quarterly and
                  payable on demand.
                </li>
                <li>
                  Our relationship with you, the Lawyer, is that of an
                  independent contractor and not an employer-employee
                  relationship. We shall not be liable for any tax or
                  withholding, including but not limited to VAT, unemployment
                  insurance, employer&apos;s liability, social security or
                  payroll withholding tax in connection with your use of the
                  Services or provision of your services to Users.
                </li>
              </ol>
            </li>
            <li>
              We, or Our auditors or authorised representative, shall be
              entitled at any time to inspect and/or audit Your books of account
              and all supporting and related documentation relating to the Fee
              and take copies thereof by giving reasonable notice to You, such
              inspection and/or audit to take place during normal business
              hours.
            </li>
            <li>
              If the audit described above in clause 4.7 (or any other periodic
              inspection not being a full audit) shows that Your accounting as
              to the calculation of the Fee due is incorrect, You undertake to
              rectify promptly the defect in the amount accounted for (plus
              interest in accordance with these terms) and if the relevant
              payment is incorrect by a margin of 10% and the error is in Your
              favour then You shall pay Our reasonable costs incurred in
              carrying out that audit or inspection. Information provided during
              the course of the audit will be treated as confidential
              information.
            </li>
          </ol>
        </li>
        <li>
          <b>BASIS OF CONTRACT</b>
          <ol>
            <li>
              In consideration of payment of the Fee and your compliance with
              these Terms, Eclatech shall provide the Services to you as a
              Lawyer.
            </li>
            <li>
              By completing the online registration process, you consent to
              Eclatech and/or other users conducting verification and security
              procedures that we or they deem reasonably necessary in respect of
              the information provided by you.
            </li>
          </ol>
        </li>
        <li>
          <b>YOUR OBLIGATIONS</b>
          <ol>
            <li>
              You are solely responsible and liable for all activities on the
              Website and App undertaken through or using your Login Details.
            </li>
            <li>
              You must comply with all applicable law in respect of your use of
              the Website/App and the Services including, without limitation,
              compliance with all applicable e-commerce directives, consumer
              protection legislation and data protection and privacy directives.
            </li>
            <li>
              You shall not submit, to appear on the Website/App or otherwise
              through your use of the Services, any information, comments,
              images, third party URL links or other material whatsoever in any
              format and/or any Submissions, that may reasonably be deemed to:
              <ol>
                <li> Be offensive, illegal, inappropriate or in any way;</li>
                <li>
                  Promote racism, bigotry, hatred or physical harm of any kind
                  against any group or individual;
                </li>
                <li> Harass or advocate harassment of another person;</li>
                <li> Display pornographic or sexually explicit material;</li>
                <li>
                  Promote any conduct that is abusive, threatening, obscene,
                  defamatory or libellous;
                </li>
                <li> Promote any illegal activities;</li>
                <li>
                  Provide instructional information about illegal activities,
                  including violating someone else&apos;s privacy or providing
                  or creating computer viruses;
                </li>
                <li>
                  Promote or contain information that you know or believe to be
                  inaccurate, false or misleading;
                </li>
                <li>
                  Engage in or promote commercial activities and/or sales not
                  related to the Lawyer Services, including but not limited to
                  contests, sweepstakes, barter, advertising and pyramid
                  schemes, without the prior written consent of Eclatech; or
                </li>
                <li>
                  Infringe any rights of any third party, and YOU HEREBY
                  INDEMNIFY ECLATECH FOR ANY BREACH BY YOU OF THIS CLAUSE 6.3.
                </li>
              </ol>
            </li>
            <li>
              Unless otherwise explicitly stated by us, Eclatech does not vet,
              verify the accuracy, correctness and completeness, edit or modify
              any Submissions or any other information, data and materials
              created, used and/or published by you to determine whether they
              may result in any liability to any third party. You hereby warrant
              that you have the rights to use all such information, data and
              material in the manner set out in these Terms. WE EXPRESSLY
              EXCLUDE OUR LIABILITY FOR ANY LOSS OR DAMAGE ARISING FROM THE USE
              OF OUR SERVICE BY A LAWYER OR A USER IN CONTRAVENTION OF THIS
              CLAUSE 6.
            </li>
            <li>
              Regardless of clause 6.4 above, you acknowledge that making a
              Submission does not guarantee that such Submission, or any part
              thereof, shall appear on the Website and/or App, whether or not
              the submission of such Submission is part of the Services. You
              agree that Eclatech may, at its sole discretion, choose to display
              any Submission or any part of the same that you make on the
              Website, or to remove them as we deem necessary or appropriate.
            </li>
            <li>
              As a Lawyer, you must:
              <ol>
                <li>
                  Provide all information reasonably requested by Eclatech to
                  set up your Profile, including but not limited to your contact
                  details (including your mobile number for SMS purposes), a
                  personal photo, identification, banking details;
                </li>
                <li>
                  Have all necessary qualifications, skills, training and
                  experience and the right to work in the country in which you
                  are advertising and promoting your services;
                </li>
                <li>
                  Exercise caution and take care not to breach any obligations
                  that you have under a contract with any other party when you
                  respond to Users;
                </li>
                <li>
                  Provide Users with your terms and conditions for the provision
                  of your services (including information about cancellation if
                  applicable) which must comply in all respects with all
                  relevant consumer laws;
                </li>
                <li>
                  At all times keep all information including without
                  limitation, communication and correspondences between you and
                  the Users and all information relating to Users and the
                  Contact Request process, secure and confidential; and
                </li>
                <li>
                  Immediately notify Eclatech in the event you have any reason
                  to believe or suspect that a User has breached any of its
                  Terms or that any of the Requests or ratings posted by a User
                  are not genuine or are false, inaccurate and/or incomplete.
                </li>
              </ol>
            </li>
            <li>
              By choosing to advertise your services on the Website and App,
              further to the general obligations on you as a Lawyer under these
              Terms, you agree that all your Submissions and any other
              information submitted by you, including but not limited to
              information provided to Users directly, shall:
              <ol>
                <li>Be accurate, correct and up-to-date;</li>
                <li>
                  Be provided with all reasonable care and skill in a manner
                  consistent with generally accepted standards in the industry
                  in which you the Lawyer operate;
                </li>
                <li>
                  Not breach any applicable legal, statutory or regulatory
                  requirement;
                </li>
                <li>
                  Not be misleading, deceptive or in any way contravene any and
                  all applicable User, e-commerce and data protection
                  legislation; and
                </li>
                <li>
                  Not be obscene, defamatory or be in the reasonable view of
                  Eclatech deemed to be offensive and/or inappropriate.
                </li>
              </ol>
            </li>
            <li>
              Eclatech may, at its sole discretion, remove your Profile from the
              Website and terminate your account if it reasonably considers that
              your Profile, the provision of services by you or any other
              activities by you in connection with the Website contravenes or
              otherwise breaches your obligations under these Terms or does not
              meet the standards that Eclatech requires of its Lawyers, at its
              absolute discretion upon written notice to you.
            </li>
            <li>
              If you have reason to believe that another user of the Website has
              not followed or complied with their obligations in these Terms, or
              you have a complaint to make, please e-mail us at:
              {formatT(email)} or write to: Eclatech Ltd, 4 Old Park Lane,
              Mayfair, London, W1K1QW, or by using the &quot;Contact Us&quot;
              form available on the Website. Please provide full details of the
              nature of your complaint and the materials to which the complaint
              relates. We will use our reasonable endeavours to respond to all
              complaints within a reasonable time and to take reasonable action,
              which we deem appropriate to resolve or rectify the subject matter
              of such complaints.
            </li>
            <li>
              In the event that Eclatech, in its sole and absolute discretion,
              considers that there has been a breach or threatened breach of any
              of the terms set out in this clause 6, Eclatech reserves the right
              to take any action that it deems to be necessary, including
              without limitation, temporary suspension, or termination forthwith
              and without notice of your use of and access to the Services and
              the Website and/or App; and in the case of illegal use, the
              instigation of legal proceedings as appropriate.
            </li>
          </ol>
        </li>
        <li>
          <b>TERMINATION</b>
          <ol>
            <li>
              You or Eclatech may terminate these Terms at any time and for any
              reason whatsoever by giving the other party not less than thirty
              (30) days&apos; notice in writing.
              <ol>
                <li>
                  If the other party is in material breach of its contractual
                  obligations and has not remedied such breach within fourteen
                  (14) days after receipt of a written notice of default from
                  the other party. For the avoidance of doubt, and without
                  limitation, breach of any provision under clause 6 is a
                  material breach for these purposes; or
                </li>
                <li>
                  By either party if the other is declared bankrupt, or if a
                  body corporate, has a liquidator, receiver or administrator
                  appointed or enters into any form of arrangement with its
                  creditors, or is otherwise insolvent.
                </li>
                <li>
                  Notwithstanding the provisions of this clause 7, these Terms
                  may be terminated by either you or us immediately, on written
                  notice to the other party.
                </li>
              </ol>
            </li>
            <li>
              Notwithstanding the provisions of this clause 7, these Terms may
              be terminated by either you or us immediately, on written notice
              to the other party:
            </li>
          </ol>
        </li>
        <li>
          <b>EXCLUSION OF WARRANTIES & LIABILITY</b>
          <ol>
            <li>
              All warranties, representations, guarantees, conditions and terms,
              other than those expressly set out in these Terms whether express
              or implied by statute, common law, trade usage or otherwise and
              whether written or oral are hereby expressly excluded to the
              fullest extent permissible by law.
            </li>
            <li>
              Eclatech does not warrant, represent or guarantee the quality and
              suitability of the Users, or that you will:
              <ol>
                <li>
                  Be successful on any Request Responses through the Website;
                </li>
                <li>
                  Obtain any requests from Users for the provision of services;
                  or
                </li>
                <li>Achieve any specific results whatsoever.</li>
              </ol>
            </li>
            <li>
              You further agree that Eclatech does not vet or verify the
              identity of the Users or Requests received via or on the Website.
              Consequently, Eclatech does not warrant or guarantee:
              <ol>
                <li>
                  The currentness, completeness, correctness and accuracy of the
                  Requests received via or on the Website by any User;
                </li>
                <li>That the Requests are genuine;</li>
                <li>
                  The manner in which Users will evaluate and select Request
                  Responses;
                </li>
                <li>The identity of the Users using the Website.</li>
              </ol>
            </li>
            <li>
              You are solely responsible for the provision of your services to
              Users and the management of the application process for any
              Request. You are also responsible for:
              <ol>
                <li>Ascertaining the identity of the User(s);</li>
                <li>
                  Verifying the information on any User&apos;s Profile or
                  Contact Request; and
                </li>
                <li>
                  Procuring from the User(s) all information reasonably required
                  by you to determine your suitability for any Request, in each
                  case, prior to submitting a response to a User.
                </li>
              </ol>
            </li>
            <li>
              YOU FURTHER ACKNOWLEDGE THAT ECLATECH HAS NO CONTROL OF AND
              THEREFORE HAS NO LIABILITY WHATSOEVER IN RESPECT OF THE BEHAVIOUR,
              RESPONSE AND QUALITY OF THE USERS OR ANY REQUEST RECEIVED THROUGH
              THE WEBSITE, INCLUDING BUT NOT LIMITED TO THE CONDUCT OF USERS
              UNDER ANY CONTRACT. 8.6 ECLATECH PROVIDES THE SERVICES SOLELY TO
              CONNECT YOU, THE LAWYER, WITH USERS WHO MAY BE SEEKING TO OBTAIN
              SERVICES FROM YOU. YOU ACKNOWLEDGE AND AGREE THAT YOU ARE SOLELY
              RESPONSIBLE FOR ALL COMMUNICATIONS WITH, AND ANY SUBSEQUENT
              DEALINGS WITH, USERS (INCLUDING BUT NOT LIMITED TO THE TERMS UPON
              WHICH YOU SUPPLY ANY SERVICES TO USERS).
            </li>
            <li>
              ECLATECH PROVIDES THE SERVICES SOLELY TO CONNECT YOU, THE LAWYER,
              WITH USERS WHO MAY BE SEEKING TO OBTAIN SERVICES FROM YOU. YOU
              ACKNOWLEDGE AND AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR ALL
              COMMUNICATION WITH, AND ANY SUBSEQUENT DEALINGS WITH, USERS
              (INCLUDING BUT NOT LIMITED TO THE TERMS UPON WHICH YOU SUPPLY ANY
              SERVICES TO USERS). YOU, THE LAWYER, HEREBY INDEMNIFY ECLATECH IN
              FULL AND ON DEMAND AGAINST ALL LOSSES, DAMAGES, COSTS, CLAIMS AND
              EXPENSES ARISING OUT OF OR IN CONNECTION WITH ANY OF YOUR DEALINGS
              WITH, OR GOODS OR SERVICES PROVIDED TO, USERS.
            </li>
            <li>
              Eclatech shall bear no responsibility or liability for inspecting
              or verifying any material posted in any Submission. Consequently,
              Eclatech does not warrant or guarantee the accuracy, correctness,
              reliability, suitability or at all in respect of any such
              Submission. Commentary and other materials posted on the
              Website/App are not intended to amount to advice on which reliance
              should be placed. We therefore disclaim all liability and
              responsibility arising from any reliance placed on such materials
              by any visitor to our site, or by anyone who may be informed of
              any of its contents.
            </li>
            <li>
              Subject to clause 8.10, liability of which shall remain unlimited,
              the maximum aggregate liability of Eclatech (including its
              respective agents and sub-contractors) under, arising from or in
              connection with these Terms, whether arising in contract, tort
              (including negligence) or otherwise, shall not exceed the total
              Fee paid by You to Us.
            </li>
            <li>
              We shall not be responsible for any:
              <ol>
                <li>
                  Losses, damages, costs and expenses which were not reasonably
                  foreseeable to be incurred by you and could not have been
                  reasonably foreseeable by you or us on entering these Terms;
                  or
                </li>
                <li>
                  Loss of profits, contracts or business, loss of an anticipated
                  outcome or anticipated savings.
                </li>
              </ol>
            </li>
            <li>
              We accept and do not limit Our liability to you for the following
              categories:
              <ol>
                <li>Death or personal injury;</li>
                <li>
                  Negligent misrepresentation, fraud or fraudulent
                  misrepresentation;
                </li>
                <li>
                  Any breach of the obligations implied by section 12 of the
                  Sale of Goods Act 1979 or section 2 of the Supply of Goods and
                  Services Act 1982.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>SUSPENSION AND CHANGES TO TERMS & SERVICES</b>

          <ol>
            <li>
              Eclatech shall be entitled at its own discretion and upon notice
              to you, to suspend the Services and/or the Website/App whether
              wholly or partly for any reason whatsoever including but not
              limited to repairs, planned maintenance or upgrades and shall not
              be liable to you for any such suspension or non-availability of
              the Services and/or the Website (whether wholly or partly).
            </li>
            <li>
              Eclatech reserves the right to make any changes to the Services
              and/or the Website including any functionalities and content
              therein or to discontinue a specific feature from time to time
              subject always that:
              <ol>
                <li>
                  Eclatech notifies you in writing through a message displayed
                  on the Website/App; and
                </li>
                <li>Such changes have no material adverse effects to you.</li>
              </ol>
            </li>
            <li>
              Eclatech may update or revise these Terms from time to time and
              shall notify you of any such updates and revisions through a
              message displayed on the Website. Your continued use of the
              Services and/or the Website (whether wholly or partly) shall be
              deemed your acceptance of such change(s) in respect of the updated
              or revised Terms. If you do not agree with the changes to these
              Terms, you may terminate your Contract with us in accordance with
              clause 7.
            </li>
          </ol>
        </li>
        <li>
          <b>INTELLECTUAL PROPERTY RIGHTS</b>
          <ol>
            <li>
              Eclatech and its licensors own all the Intellectual Property
              Rights relating to the Services and the Website and App, save for
              those that fall under clause 10.3 below. Nothing in these Terms
              shall serve to transfer from Eclatech to any User or Lawyer any of
              the intellectual property rights in the Website.
            </li>
            <li>
              You are expressly prohibited from:
              <ol>
                <li>
                  Reproducing, copying, editing, transmitting, uploading or
                  incorporating into any other materials, any of the Website;
                  and
                </li>
                <li>
                  Removing, modifying, altering or using any registered or
                  unregistered marks/logos/designs owned by us or our licensors,
                  and doing anything which may be seen to take unfair advantage
                  of the reputation and goodwill of Eclatech or could be
                  considered an infringement of any of the rights in the
                  Intellectual Property Rights owned by and/or licensed to
                  Eclatech.
                </li>
              </ol>
            </li>
            <li>
              All rights, title and interest in any Submissions shall remain
              exclusively with the relevant user who posted such Submission.
            </li>
            <li>
              You hereby grant Eclatech a non-exclusive, perpetual, irrevocable,
              worldwide licence to make Submissions available on the Website,
              and to use such Submissions for our promotional and marketing
              purposes.
            </li>
            <li>
              Where the Website contains links to other sites and resources
              provided by third parties, these links are provided for your
              information only. We have no control over the contents of those
              sites or resources, and accept no responsibility for them or for
              any loss or damage that may arise from your use of them.
            </li>
          </ol>
        </li>
        <li>
          <b>CONFIDENTIALITY</b>
          <ol>
            <li>
              You shall not, during the term in which these Terms apply or after
              such term has ended, except as required by law, disclose to any
              person any confidential information and/or trade secrets relating
              to Eclatech’s business. Such information includes but is not
              limited to, information or secrets relating to: corporate and
              marketing strategy, business development and plans, sales reports
              and research results, business methods and processes, technical
              information and know-how relating to Eclatech’s business and which
              is not in the public domain, including inventions, designs,
              programs, techniques, database systems, formulae and ideas,
              business contacts, lists of Users, Users and companies and details
              of contracts with them, and any document marked “confidential”
              and/or “privileged”. This restriction shall not apply during or
              after these Terms have terminated to information which has become
              available to the public generally otherwise than through
              unauthorised disclosure.
            </li>
          </ol>
        </li>
        <li>
          <b>PRIVACY</b>
          <ol>
            <li>
              Eclatech Ltd collects, uses and is responsible for certain
              personal information about you. Eclatech Ltd is regulated under
              the General Data Protection Regulation which applies across the
              European Union and the United Kingdom and is responsible as
              ‘controller’ of that personal information for the purposes of
              those laws. 12.2 In respect of any Services where you receive
              personal data from Eclatech as part of such Services, each party
              will be considered an independent data controller in relation to
              such personal data and comply with all requirements and
              obligations applicable under the law.
            </li>
            <li>
              In respect of any Services where you receive personal data from
              Eclatech as part of such Services, each party will be considered
              an independent data controller in relation to such personal data
              and comply with all requirements and obligations applicable under
              the Data Protection Laws.
            </li>
          </ol>
        </li>
        <li>
          <b>GENERAL</b>
          <ol>
            <li>
              If Eclatech fails at any time to insist upon strict performance of
              its obligations under these Terms, or if it fails to exercise any
              of the rights or remedies to which it is entitled under these
              Terms, this will not constitute a waiver of any such rights or
              remedies and shall not relieve you from compliance with such
              obligations. No waiver by Eclatech of any of these Terms shall be
              effective unless it is expressly stated to be a waiver and is
              communicated to you in writing.
            </li>
            <li>
              Any requirement for written notice to be provided under these
              Terms may be satisfied by sending the notice by registered post or
              email to, in the case of Eclatech, the postal address or email
              address as set out in these Terms, or in the case of a Lawyer, to
              the email address provided by that Lawyer.
            </li>
            <li>
              If any of these Terms is determined by any competent authority to
              be invalid, unlawful or unenforceable to any extent, such term,
              condition or provision will to that extent be severed from the
              remaining terms, conditions and provisions which shall continue to
              be valid to the fullest extent permitted by law.
            </li>
            <li>
              Nothing in these Terms shall give, directly or indirectly, any
              third party any enforceable benefit or any right of action against
              Eclatech and such third parties shall not be entitled to enforce
              any of these Terms against Eclatech.
            </li>
            <li>
              We will not be liable or responsible for any failure to perform,
              or delay in performance of, any of our obligations under these
              Terms that is caused by a Force Majeure Event. We will use our
              reasonable endeavours to bring the Force Majeure Event to a close
              or to find a solution by which our obligations under these Terms
              may be performed despite the Force Majeure Event.
            </li>
            <li>
              These Terms and any document expressly referred to in them
              represent the entire agreement between you and Eclatech in respect
              of your use of the Website, and shall supersede any prior
              agreement, understanding or arrangement between you and Eclatech,
              whether oral or in writing.
            </li>
            <li>
              These Terms are governed by and construed in accordance with
              English law. The Courts of England and Wales shall have exclusive
              jurisdiction over any disputes arising out of these Terms.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  )
}

export default OurTerms
