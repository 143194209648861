import { useCallback } from 'react'
import { auth, firestore } from 'services/firebase'

const useGetCases = () => {
  const { uid } = auth.currentUser
  return useCallback(async () => {
    return firestore
      .collection('cases')
      .where('users', 'array-contains', uid)
      .get()
      .then(async (snap) => {
        if (!snap.empty) {
          return snap.docs.map((doc) => ({
            ...doc.data(),
            caseId: doc.id,
          }))
        }
        return null
      })
  }, [])
}

export default useGetCases
