import React, { useState } from 'react'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'
import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'

const Contacts = () => {
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.phone)
  const handleEdit = () => {
    if (edit) {
      if (value !== userInfo.phone) {
        doUpdateUserProfile({
          phone: value,
        })
      }
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  return (
    <div className='o-lawyerProfile__aside--form-box'>
      <header>
        <strong>Contact</strong>
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <CheckIcon /> : <EditIcon />}
        </button>
      </header>
      <main>
        <span>{userInfo.email}</span>
        {edit ? (
          <input
            type='text'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <span>{value}</span>
        )}
      </main>
    </div>
  )
}

export default Contacts
