import React, { useState } from 'react'
import Title from 'components/atoms/Title'
import OwlCarousel from 'react-owl-carousel2'
import { ReactComponent as CourtIcon } from 'images/icon-court.svg'
import { ReactComponent as ImigrationIcon } from 'images/icon-imigration.svg'
import { ReactComponent as NetworkIcon } from 'images/icon-network.svg'
import { ReactComponent as PapyrusIcon } from 'images/icon-papyrus.svg'
import { ReactComponent as PhoneIcon } from 'images/icon-phone.svg'
import { ReactComponent as EnvelopeIcon } from 'images/icon-envelope.svg'

import 'react-owl-carousel2/src/owl.carousel.css'
import FaqQuestions from 'components/organisms/FaqQuestions'
import Section from 'components/atoms/Section'
import './FaqPage.scss'

const categories = [
  {
    id: 1,
    icon: <CourtIcon />,
    title: 'Lawyerup Services',
    questions: [
      {
        question: 'How long does it take to contact a lawyer?',
        answer:
          'You can audio call, video call, email direct, or book a meeting with a Lawyer right now. Choose a lawyer and contact them immediately.',
      },
      {
        question: 'How much do you charge for finding me a lawyer?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec facilisis leo. Praesent pulvinar metus id dui accumsan, et hendrerit nisi pharetra. Maecenas hendrerit in risus vel interdum. Donec a pulvinar odio. Aliquam blandit ipsum lacus, eu tempus sem vehicula sed. Quisque vel ex non neque fermentum sagittis.',
      },
      {
        question: 'Can I see lawyers near me for what I need?',
        answer:
          'Praesent pulvinar metus id dui accumsan, et hendrerit nisi pharetra. Maecenas hendrerit in risus vel interdum. Donec a pulvinar odio. Aliquam blandit ipsum lacus, eu tempus sem vehicula sed. Quisque vel ex non neque fermentum sagittis.',
      },
      {
        question: 'How do I contact LawyerUp?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec facilisis leo. Praesent pulvinar metus id dui accumsan, et hendrerit nisi pharetra. Aliquam blandit ipsum lacus, eu tempus sem vehicula sed. Quisque vel ex non neque fermentum sagittis.',
      },
      {
        question:
          'Will my personal details be shared with any 3rd party websites or my case details made public?',
        answer:
          'Quisque nec facilisis leo. Praesent pulvinar metus id dui accumsan, et hendrerit nisi pharetra. Maecenas hendrerit in risus vel interdum. Donec a pulvinar odio. Aliquam blandit ipsum lacus, eu tempus sem vehicula sed. Quisque vel ex non neque fermentum sagittis.',
      },
    ],
  },
  {
    id: 2,
    icon: <ImigrationIcon />,
    title: 'Visa & Imigration',
    questions: [
      {
        question: 'How do I contact LawyerUp?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec facilisis leo. Praesent pulvinar metus id dui accumsan, et hendrerit nisi pharetra. Aliquam blandit ipsum lacus, eu tempus sem vehicula sed. Quisque vel ex non neque fermentum sagittis.',
      },
      {
        question:
          'Will my personal details be shared with any 3rd party websites or my case details made public?',
        answer:
          'Quisque nec facilisis leo. Praesent pulvinar metus id dui accumsan, et hendrerit nisi pharetra. Maecenas hendrerit in risus vel interdum. Donec a pulvinar odio. Aliquam blandit ipsum lacus, eu tempus sem vehicula sed. Quisque vel ex non neque fermentum sagittis.',
      },
    ],
  },
  {
    id: 3,
    icon: <NetworkIcon />,
    title: 'Business & Employe',
    questions: [],
  },
  {
    id: 4,
    icon: <PapyrusIcon />,
    title: 'Wills & Probate',
    questions: [],
  },
  {
    id: 5,
    icon: <CourtIcon />,
    title: 'Lawyerup Services 2',
    questions: [],
  },
]

/**
 * Hide email from spam bots
 *
 * @param lhs
 * @param rhs
 * @returns {string}
 */
const convertEmailToLink = (lhs, rhs) => {
  return (
    <a href={`mailto:${lhs}@${rhs}`}>
      {lhs}@{rhs}
    </a>
  )
}

const FaqPage = () => {
  const [activeCategory, setActiveCategory] = useState(categories[0].id)
  const [questions, setQuestions] = useState(categories[0].questions)

  const handleCategoryChange = (item) => {
    setActiveCategory(item.id)
    setQuestions(item.questions)
  }

  return (
    <div className='p-faqPage'>
      <Title>Can we help you with some of these problems?</Title>
      <Section className='p-faqPage__categories'>
        <OwlCarousel
          options={{
            nav: true,
            loop: false,
            autoplay: false,
            dots: false,
            margin: 30,
            navText: [
              '<i class="chevron-left"/>',
              '<i class="chevron-right"/>',
            ],
            responsive: {
              0: {
                items: 1,
                autoWidth: true,
                nav: false,
              },
              576: {
                items: 3,
                autoWidth: true,
                nav: false,
              },
              768: {
                items: 2,
              },
              1024: {
                items: 3,
              },
              1200: {
                items: 4,
              },
            },
          }}
        >
          {categories.map((item, key) => (
            <div
              onClick={() => handleCategoryChange(item)}
              key={`faq-carousel-item-${key}`}
              className={`p-faqPage__categories--item ${
                item.id === activeCategory ? '-active' : ''
              }`}
            >
              <div className='p-faqPage__categories--itemIcon'>{item.icon}</div>
              <div className='p-faqPage__categories--itemTitle'>
                {item.title}
              </div>
            </div>
          ))}
        </OwlCarousel>
      </Section>
      <Section className='p-faqPage__questions'>
        <div className='p-faqPage__questions--content'>
          <FaqQuestions data={questions} />
        </div>
      </Section>

      <Section className='p-faqPage__contact'>
        <Title>Still have questions?</Title>
        <div className='p-faqPage__contact--boxes'>
          <div className='p-faqPage__contact--box'>
            <div className='p-faqPage__contact--boxIcon'>
              <PhoneIcon />
            </div>
            <span className='p-faqPage__contact--boxText'>07787772777</span>
            <small className='p-faqPage__contact--boxSubtext'>
              We are always happy to help
            </small>
          </div>
          <div className='p-faqPage__contact--box'>
            <div className='p-faqPage__contact--boxIcon'>
              <EnvelopeIcon />
            </div>
            <span className='p-faqPage__contact--boxText'>
              {convertEmailToLink('info', 'lawyerup.org.uk')}
            </span>
            <small className='p-faqPage__contact--boxSubtext'>
              For fast responses
            </small>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default FaqPage
