import React, { useState } from 'react'

import './RemoveAccount.scss'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 46,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#18a1db',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#18a1db',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

const RemoveAccount = () => {
  const [value, setValue] = useState(false)
  const handleChange = (e) => {
    setValue(e.target.checked)
  }

  return (
    <div className='m-removeAccount'>
      <main>
        <div className='m-removeAccount__text'>
          <strong>Delete Account </strong>
          <p>
            Please note: Deletion of your account and personal data is permanent
            and cannot be undone. <br />
            Lawyerup will not be able to recover your account or the data that
            is deleted.
          </p>
        </div>

        <div className='m-removeAccount__action'>
          <IOSSwitch checked={value} onChange={handleChange} name='checkedB' />
        </div>
      </main>
    </div>
  )
}

export default RemoveAccount
