import React from 'react'
import './LoginModal.scss'
import LoginPage from 'components/pages/LoginPage'

const LoginModal = ({ handleCloseModal, handleModalRedirect }) => {
  return (
    <div className='o-loginModal'>
      <LoginPage
        isModal
        handleModalRedirect={handleModalRedirect}
        handleCloseModal={handleCloseModal}
      />
    </div>
  )
}

export default LoginModal
