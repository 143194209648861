import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import StarRatings from 'react-star-ratings'
import Button from '@material-ui/core/Button'
import { ReactComponent as PhoneIcon } from 'images/icon-phone-call.svg'
import { ReactComponent as MembersIcon } from 'images/icon-people.svg'
import { ReactComponent as ExperienceIcon } from 'images/icon-badge.svg'
import { ReactComponent as CircleCheckIcon } from 'images/icon-check-circle.svg'
import { ReactComponent as LanguageIcon } from 'images/icon-translate.svg'
import GetDirections from 'components/molecules/GetDirections'
import Loader from 'components/atoms/Loader'
import { expertise } from 'utils/constants'
import useUserInfo from 'hooks/useUserInfo'
import './ProfilePage.scss'
import LoginModal from 'components/organisms/LoginModal'
import useGetUnregisteredLawyerByUid from 'services/firebase/hooks/lawyers/useGetUnregisteredLawyerByUid'

const ProfilePage = ({ match, history }) => {
  const [userInfo] = useUserInfo()

  const [loading, setLoading] = useState(true)
  const [lawyerData, setLawyerData] = useState(null)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [actionType, setActionType] = useState(null)
  const [showPhoneNumber, setShowPhoneNumber] = useState(false)

  const doGetUnregisteredLawyerByUid = useGetUnregisteredLawyerByUid()

  useEffect(() => {
    if (match.params.slug) {
      doGetUnregisteredLawyerByUid(match.params.slug)
        .then(async (result) => {
          if (result.exists) {
            setLawyerData(result.data())
            setLoading(false)
          }
        })
        .catch((err) => {
          history.push('/')
        })
    } else {
      history.push('/')
    }
  }, [])

  const handleActionButtons = (type) => {
    if (userInfo && typeof userInfo.uid !== 'undefined') {
      if (type === 'phone') {
        setShowPhoneNumber(true)
      }
    } else {
      setActionType(type)
      setShowLoginModal(true)
    }
  }

  const handleModalRedirect = () => {
    if (actionType === 'phone') {
      setShowPhoneNumber(true)
    }
    setShowLoginModal(false)
  }

  const openLinkedIn = () => {
    window.open(lawyerData.linkedIn, '_blank')
  }

  const handleWithAnchor = (hash) => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) element.scrollIntoView()
      }, 0)
    }
  }

  return (
    <div className='p-profilePage'>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className='p-profilePage__header'>
            <div className='p-profilePage__headerContent'>
              <div
                className={`p-profilePage__headerContent--image ${
                  lawyerData.onlineNow ? '-online' : ''
                }`}
              >
                <img
                  src={
                    lawyerData.photoURL || require('images/empty-avatar.jpg')
                  }
                  alt=''
                />
              </div>
              <div className='p-profilePage__headerContent--info'>
                <div className='p-profilePage__headerContent--info-title'>
                  <span>
                    {lawyerData.expertise
                      .map(
                        (item) =>
                          expertise.find((exp) => exp.value === item).label
                      )
                      .join(', ')}
                  </span>
                  <h2>
                    {lawyerData.name} {lawyerData.surname}
                  </h2>
                </div>
                <div className='p-profilePage__headerContent--info-reviews'>
                  <StarRatings
                    svgIconPath='M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
              c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
              L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438
              c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701
              c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0
              l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904
              l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z'
                    starDimension={'25px'}
                    starRatedColor='#ead42b'
                    rating={lawyerData.rating}
                    numberOfStars={5}
                    starSpacing={'3px'}
                  />
                </div>
                <div className='p-profilePage__headerContent--info-organization'>
                  <span>{lawyerData.lawFirmName}</span>
                </div>
                <div className='p-profilePage__headerContent--info-actions'>
                  <div className='p-profilePage__headerContent--info-actions-hourlyRate'>
                    <span>Hourly rate</span>
                    <small>Not provided</small>
                  </div>
                  <div className='p-profilePage__headerContent--info-actions-fixedFee'>
                    <span>Fixed Fee Option</span>
                    <small>Not provided</small>
                  </div>
                  <div className='p-profilePage__headerContent--info-buttons'>
                    {showPhoneNumber && lawyerData.phone ? (
                      <span className='-phone'>
                        <PhoneIcon /> <small>Not verified</small>
                      </span>
                    ) : (
                      <button
                        className={`-phone ${
                          showPhoneNumber ? '-phoneNumber' : ''
                        }`}
                        onClick={() => handleActionButtons('phone')}
                      >
                        <PhoneIcon />
                        <span>Call Now</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='p-profilePage__header--tabs'>
              <button
                onClick={() => handleWithAnchor('about')}
                className='-active'
              >
                About
              </button>
              <button onClick={() => handleWithAnchor('location')}>
                Location
              </button>
            </div>
          </div>

          <div className='p-profilePage__content'>
            <div className='p-profilePage__highlights'>
              <div className='p-profilePage__highlights--item'>
                <div className='p-profilePage__highlights--item-icon'>
                  <MembersIcon />
                </div>
                <span className='p-profilePage__highlights--item-text'>
                  {lawyerData.numberOfEmployees} Team <br />
                  members
                </span>
                <small>Not provided</small>
              </div>
              <div className='p-profilePage__highlights--item'>
                <div className='p-profilePage__highlights--item-icon'>
                  <ExperienceIcon />
                </div>
                <span className='p-profilePage__highlights--item-text'>
                  {lawyerData.practisingSince} Years <br />
                  Experience
                </span>
                <small>Not provided</small>
              </div>
              <div className='p-profilePage__highlights--item'>
                <div className='p-profilePage__highlights--item-icon'>
                  <CircleCheckIcon />
                </div>
                <span className='p-profilePage__highlights--item-text'>
                  0 Cases <br />
                  Completed
                </span>
              </div>
              <div className='p-profilePage__highlights--item'>
                <div className='p-profilePage__highlights--item-icon'>
                  <LanguageIcon />
                </div>
                <span className='p-profilePage__highlights--item-text'>
                  {lawyerData.languages.map((lang) => lang.name).join(', ')}
                </span>
              </div>
            </div>
            <div id='about' className='p-profilePage__info'>
              <div
                className='p-profilePage__infoBox'
                style={{ justifyContent: 'flex-start' }}
              >
                <h3>Bio</h3>
                <p>{lawyerData.about || 'Bio not informed'}</p>
                {lawyerData.linkedIn && (
                  <Button
                    onClick={openLinkedIn}
                    variant='contained'
                    color='primary'
                    fullWidth
                  >
                    See Linkedin
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div id='location' className='p-profilePage__getDirections'>
            <GetDirections lawyerData={lawyerData} />
          </div>
        </>
      )}

      {showLoginModal && (
        <LoginModal
          handleModalRedirect={handleModalRedirect}
          handleCloseModal={setShowLoginModal}
        />
      )}
    </div>
  )
}

export default withRouter(ProfilePage)
