import { useCallback } from 'react'
import { auth } from 'services/firebase'

const useSignUpWithEmail = () => {
  return useCallback(values => {
    return auth.createUserWithEmailAndPassword(values.email, values.password)
  }, [])
}

export default useSignUpWithEmail
