import axios from 'axios'

export const formatter = new Intl.NumberFormat('iu', {
  style: 'currency',
  currency: 'GBP',
})

export const getLatLngFromPostcode = (postcode) => {
  return axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=AIzaSyDhUB5zHmeVBAaVeizpfFhCgeoAWQ0Vi9s`
    )
    .then((result) => result.data.results)
}

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  } else {
    const radlat1 = (Math.PI * lat1) / 180
    const radlat2 = (Math.PI * lat2) / 180
    const theta = lon1 - lon2
    const radtheta = (Math.PI * theta) / 180
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    dist = dist * 0.8684
    return dist.toFixed(2)
  }
}

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

export const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})
