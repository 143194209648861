import React from 'react'
import { ReactComponent as Icon } from 'images/icon-hourly-rates.svg'
import './HourlyRates.scss'
import HourPrice from './HourPrice'
import FixedFee from './FixedFee'
const HourlyRates = () => {
  return (
    <div className='m-hourlyRates'>
      <header>
        <span>Hourly Rates</span>
        <Icon />
      </header>
      <main>
        <HourPrice />
        <FixedFee />
      </main>
    </div>
  )
}

export default HourlyRates
