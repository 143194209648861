import React, { useEffect } from 'react'
import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as NotificationsIcon } from 'images/icon-notifications.svg'
import NotificationItem from 'components/molecules/NotificationItem'
import { useNotifications } from 'context/notifications'

import './Notifications.scss'

const Notifications = () => {
  const {
    notifications,
    unreadNotifications,
    markAllAsRead,
    updateNotificationsStates,
  } = useNotifications()
  const [user] = useUserInfo()

  useEffect(() => {
    updateNotificationsStates()
  }, [])

  return (
    <div className='p-notifications'>
      <div className='p-notifications__container'>
        <div className='p-notifications__box'>
          <div className='p-notifications__items'>
            <header>
              <strong>Latest Notifications</strong>
              <button onClick={markAllAsRead}>Mark all as read</button>
            </header>
            <main>
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                  />
                ))
              ) : (
                <div className='p-notifications__items--noItems'>
                  <p>You have no notifications</p>
                </div>
              )}
              {/*<button className='p-notifications__items--loadMore'>*/}
              {/*  See all*/}
              {/*</button>*/}
            </main>
          </div>
        </div>
        <div className='p-notifications__box -aside'>
          <div className='p-notifications__aside'>
            <header>
              <strong>Hello!</strong>
              <b>
                {user.role === 'lawyer'
                  ? user.name
                  : user.displayName.replace(/ .*/, '')}
              </b>
            </header>
            <div className='p-notifications__aside--icon'>
              <NotificationsIcon />
              <span>{unreadNotifications}</span>
            </div>
            <div className='p-notifications__aside--text'>
              {unreadNotifications > 0 ? (
                <>
                  You have <b>{unreadNotifications}</b> unread notiﬁcations
                </>
              ) : (
                <>You have no unread notifications</>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications
