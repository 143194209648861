import React, { useState } from 'react'
import { Link } from '@reach/router'
import Section from '../../atoms/Section'
import Title from '../../atoms/Title'

import AppStoreButton from 'images/app-store.png'
import GooglePlayButton from 'images/google-play.png'
import './AppStores.scss'

const AppStores = () => {
  const [showSoon, setShowSoon] = useState(false)

  return (
    <Section className='o-appStores'>
      <Title>APP Available on IOS and Android</Title>
      <p>To use our full features download and register on the app for free</p>
      <div className='o-appStores__buttons'>
        <Link
          onClick={() => {
            setShowSoon(!showSoon)
          }}
          to={'#'}
        >
          <img src={AppStoreButton} alt='' />
        </Link>
        <Link
          onClick={() => {
            setShowSoon(!showSoon)
          }}
          to={'#'}
        >
          <img src={GooglePlayButton} alt='' />
        </Link>
      </div>
      {showSoon && <div className='o-appStores__soon'>Coming soon!</div>}
    </Section>
  )
}

export default AppStores
