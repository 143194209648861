import { useCallback } from 'react'
import { auth, firestore } from 'services/firebase'

const useGetUpdateNotification = () => {
  return useCallback(async (notificationId) => {
    const { uid } = auth.currentUser
    return firestore
      .collection('users')
      .doc(uid)
      .collection('notifications')
      .doc(notificationId)
      .update({ read: true })
  }, []) // eslint-disable-line
}

export default useGetUpdateNotification
