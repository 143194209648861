import React, { useState, useEffect } from 'react'
import './PracticeAreas.scss'

import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'
import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'
import { expertise } from 'utils/constants'
import { Checkbox, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  select: {
    padding: 10,
    width: '100%',
    height: '100%',
    minWidth: 140,
    overflow: 'hidden',
    fontSize: 14,
  },
  icon: {
    right: 15,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 4,
  },
}))

const PracticeAreas = () => {
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [areas, setAreas] = useState(userInfo.practiceAreas)
  const [practiceAreas, setPracticeAreas] = useState([])

  useEffect(() => {
    if (edit) {
      const tempPracticeAreas = expertise.filter((item) =>
        userInfo.expertise.includes(item.value)
      )
      setPracticeAreas(tempPracticeAreas)
    }
  }, [edit]) // eslint-ignore-line

  const handleChange = async (item, selected) => {
    const newArray = [...areas]

    if (selected) {
      const itemIndex = await newArray.findIndex(
        (area) => item.value === area.value
      )
      newArray.splice(itemIndex, 1)
    } else {
      newArray.push(item)
    }
    setAreas(newArray)
  }

  const handleEdit = () => {
    if (edit) {
      if (areas) {
        const filteredValues = areas.map((item) => item.value)
        doUpdateUserProfile({
          practiceAreas: areas,
          expertiseAreas: filteredValues,
        })
      }
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  return (
    <div className='m-practiceAreas'>
      <div className='m-practiceAreas__header'>
        <span>Practice Areas</span>
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <CheckIcon /> : <EditIcon />}
        </button>
      </div>

      <div className='m-practiceAreas__main'>
        {edit
          ? practiceAreas.map((item) =>
              item.options.map((subItem) => {
                const isChecked =
                  areas.findIndex((val) => val.value === subItem.value) > -1
                if (!subItem.placeholder) {
                  return (
                    <button
                      className='chip'
                      onClick={() => handleChange(subItem, isChecked)}
                      key={`${subItem.value}`}
                      value={subItem.value}
                    >
                      <Checkbox color='primary' checked={isChecked} />
                      <span>{subItem.label}</span>
                    </button>
                  )
                } else {
                  return <span className='divider'>{subItem.label}</span>
                }
              })
            )
          : areas &&
            areas.map((item) => (
              <span key={item.value} className='chip'>
                {item.label}
              </span>
            ))}
      </div>
    </div>
  )
}

export default PracticeAreas
