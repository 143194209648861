import { useCallback } from 'react'
import { auth, firestore } from 'services/firebase'
import { toast } from 'react-toastify'
import axios from 'axios'
import { firebaseBaseURL } from 'utils/constants'

const useUpdateBankDetails = () => {
  return useCallback(async (values) => {
    const { uid } = auth.currentUser

    const axiosConfig = {
      method: 'post',
      url: `${firebaseBaseURL}stripe/update-bank-account`,
      data: { ...values, uid },
      headers: {
        'Content-Type': 'application/json',
      },
    }

    await axios(axiosConfig)
      .then((result) => {
        if (result) {
          return result.data
        }
      })
      .catch((err) => {
        if (err.response.data.raw) {
          toast.error(err.response.data.raw.message)
        } else {
          toast.error('An error has occurred. Try again later.')
        }
        throw 'Error'
      })

    await firestore
      .collection('users')
      .doc(uid)
      .collection('bankDetails')
      .get()
      .then((snap) => {
        if (snap.size > 0) {
          snap.forEach((element) => {
            element.ref.delete()
          })
        }
      })

    firestore
      .collection('users')
      .doc(uid)
      .collection('bankDetails')
      .doc()
      .set({ ...values, uid }, { merge: true })
      .then((result) => {
        toast.success('Data updated successfully.')
      })
  }, [])
}

export default useUpdateBankDetails
