import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { ReactComponent as ListIcon } from 'images/icon-list.svg'
import './ResultsMap.scss'
import { useHistory } from 'react-router-dom'

// InfoWindow component
const InfoWindow = ({ lat, lng, address, lawFirm }) => {
  const infoWindowStyle = {
    position: 'relative',
    bottom: 150,
    left: '-25px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  }

  return (
    <div className='infoWindow'>
      {lawFirm && <strong>{lawFirm}</strong>}
      <p>{address}</p>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={`https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}`}
      >
        Get directions
      </a>
    </div>
  )
}
const CustomMarker = ({
  uid,
  image,
  status,
  history,
  single,
  lat,
  lng,
  address,
  lawFirm,
  validatedAccount,
}) => {
  return (
    <>
      {single && (
        <InfoWindow lat={lat} lng={lng} address={address} lawFirm={lawFirm} />
      )}
      <div
        onClick={() => {
          if (!single) {
            history.push(
              `/profile/${validatedAccount ? '' : 'not-validated/'}${uid}`
            )
          }
        }}
        className={`customMarker ${status ? '-online' : ''}`}
        style={{ backgroundImage: `url(${image})` }}
      />
    </>
  )
}

const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds()
  places.forEach((place) => {
    bounds.extend(new maps.LatLng(place.l.latitude, place.l.longitude))
  })
  return bounds
}

const apiIsLoaded = (map, maps, places, single) => {
  if (!single) {
    const bounds = getMapBounds(map, maps, places)
    map.fitBounds(bounds) // <= don't call this function
  }
}

const ResultsMap = ({ single = false, list, handleShowOptions }) => {
  const history = useHistory()
  const [zoom, setZoom] = useState(!single ? 9 : 12)
  const latitude = !single
    ? parseFloat(window.localStorage.getItem('current-latitude'))
    : list[0].l.latitude
  const longitude = !single
    ? parseFloat(window.localStorage.getItem('current-longitude'))
    : list[0].l.longitude

  if (!single && (!list || !latitude || !longitude)) return false

  const handleZoom = (type) => {
    if (type === 'in') {
      setZoom(zoom + 1)
    } else {
      setZoom(zoom - 1)
    }
  }

  return (
    <div className='o-results__map--action'>
      <div className='o-results__map--mobileButtons'>
        <div className='o-results__map--mobileButtons-zoom'>
          <a onClick={() => handleZoom('in')}>
            <span />
          </a>
          <a onClick={() => handleZoom('out')}>
            <span />
          </a>
        </div>
        <div className='o-results__map--mobileButtons-list'>
          <a onClick={() => handleShowOptions(false, 'map')}>
            <ListIcon />
            <span>List</span>
          </a>
        </div>
      </div>
      <div className='results_map'>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: 'AIzaSyDhUB5zHmeVBAaVeizpfFhCgeoAWQ0Vi9s' }}
          onGoogleApiLoaded={({ map, maps }) =>
            apiIsLoaded(map, maps, list, single)
          }
          defaultCenter={{ lat: latitude, lng: longitude }}
          center={{ lat: latitude, lng: longitude }}
          zoom={zoom}
          options={{
            panControl: true,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: false,
            fullscreenControl: false,
          }}
        >
          {list.map((item) => (
            <CustomMarker
              single={single}
              history={history}
              key={item.uid}
              uid={item.uid}
              image={item.photoURL || require('images/empty-avatar.jpg')}
              status={item.onlineNow}
              lat={item.l.latitude}
              lng={item.l.longitude}
              address={item.address}
              lawFirm={item.lawFirmName}
              validatedAccount={item.validatedAccount}
            />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  )
}

export default ResultsMap
