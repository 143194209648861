import React from 'react'
import HomeSearch from 'components/organisms/HomeSearch'
import PopularPosts from 'components/organisms/PopularPosts'
import WhatIsLawyerup from 'components/organisms/WhatIsLawyerup'
import HowItWorks from 'components/organisms/HowItWorks'
import AppStores from 'components/organisms/AppStores'
import OurSponsors from 'components/organisms/OurSponsors'

const Index = () => {
  return (
    <div className='p-homePage'>
      <HomeSearch />
      <PopularPosts />
      <WhatIsLawyerup />
      <HowItWorks />
      <OurSponsors />
      <AppStores />
    </div>
  )
}

export default Index
