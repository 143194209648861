import React, { useState, useEffect } from 'react'
import { auth } from 'services/firebase'
import useRegisterReview from 'services/firebase/hooks/cases/useRegisterReview'
import useGetCaseReviews from 'services/firebase/hooks/cases/useGetCaseReviews'
import { Button, FormControl, makeStyles, TextField } from '@material-ui/core'
import StarRatings from 'react-star-ratings'
import './CaseReviews.scss'
import { toast } from 'react-toastify'
import moment from 'moment'
import Loader from 'components/atoms/Loader'
import useRegisterUserNotification from 'services/firebase/hooks/notifications/useRegisterUserNotification'

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'transparent',
    minWidth: 120,
    border: 'none',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  field: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 400,
    border: 0,
    '&:focus': {
      backgroundColor: '#f6fcff',
    },
  },
  button: {
    margin: theme.spacing(2, 0, 0),
    padding: theme.spacing(2),
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    borderRadius: '0 0 10px 10px',
    outline: 'none',
    zIndex: 1000,
  },
}))

const CaseReviews = ({ caseId, senderUid, receiverUid, receiverName }) => {
  const { uid } = auth.currentUser
  const classes = useStyles()
  const doRegisterReview = useRegisterReview()
  const doGetCaseReviews = useGetCaseReviews()
  const doRegisterUserNotification = useRegisterUserNotification()
  const [rating, setRating] = useState(0)
  const [caseReviews, setCaseReviews] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentUserSentReview, setCurrentUserSentReview] = useState(false)
  const [comment, setComment] = useState('')

  useEffect(() => {
    doGetCaseReviews(caseId).then((reviews) => {
      setCaseReviews(reviews)
      setCurrentUserSentReview(reviews.find((item) => item.uid === uid))
      setIsLoading(false)
    })
  }, [])

  const handleChangeRating = async (newRating, name) => {
    setRating(newRating)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (rating === 0) {
      toast.error('Rating must be between 1 and 5 stars')
      return
    }
    if (comment.length <= 0) {
      toast.error('The review field needs to be filled')
      return
    }
    setIsLoading(true)
    await doRegisterReview(
      caseId,
      { comment, rating },
      uid,
      uid === senderUid ? receiverUid : senderUid
    ).then((result) => {
      doGetCaseReviews(caseId).then(async (reviews) => {
        setCaseReviews(reviews)
        setCurrentUserSentReview(reviews.find((item) => item.uid === uid))
        setIsLoading(false)
        /**
         * Lawyer notification
         */
        await doRegisterUserNotification(senderUid, {
          message: `${receiverName || 'The client'} gave you feedback`,
          type: 'review',
          link: `/panel/cases/${caseId}`,
        })
      })
    })
  }

  return (
    <div className='m-caseReviews'>
      {isLoading ? (
        <div className='m-caseReviews__loading'>
          <Loader />
        </div>
      ) : caseReviews.length > 0 ? (
        <div className='m-caseReviews__reviews'>
          {caseReviews.map((review) => (
            <div key={review.uid} className='m-caseReviews__reviews--item'>
              <header>
                <strong>
                  {review.uid === uid
                    ? 'Your review'
                    : uid === senderUid
                    ? 'Client review'
                    : 'Lawyer review'}
                </strong>
                <small>
                  {moment(review.date.toDate()).format('DD/MM/YYYY HH:mm')}
                </small>
              </header>
              <div className='m-caseReviews__reviews--item-star'>
                <StarRatings
                  svgIconPath='M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
              c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
              L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438
              c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701
              c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0
              l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904
              l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z'
                  starDimension={'20px'}
                  starRatedColor='#ead42c'
                  starHoverColor='#ead42c'
                  rating={review.rating}
                  numberOfStars={5}
                  starSpacing={'3px'}
                />
              </div>
              <p>{review.comment}</p>
            </div>
          ))}
        </div>
      ) : uid !== senderUid ? (
        <div className='m-caseReviews__form'>
          <form className='m-caseReviews__form' onSubmit={handleSubmit}>
            <div className='m-caseReviews__form--fields'>
              <div className='m-caseReviews__form--label'>
                What did you think of this{' '}
                {uid === senderUid ? 'client' : 'lawyer'}
              </div>
              <FormControl
                fullWidth
                variant='outlined'
                className={classes.formControl}
              >
                <TextField
                  required
                  className={classes.field}
                  placeholder={`Tell us about your experience`}
                  variant='outlined'
                  multiline
                  rows={2}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </FormControl>

              <FormControl
                fullWidth
                variant='outlined'
                className={classes.formControl}
              >
                <div className='m-caseReviews__form-stars'>
                  <span>
                    Give a note to the {uid === senderUid ? 'client' : 'lawyer'}
                  </span>
                  <StarRatings
                    changeRating={handleChangeRating}
                    svgIconPath='M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
              c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
              L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438
              c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701
              c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0
              l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904
              l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z'
                    starDimension={'24px'}
                    starRatedColor='#ead42c'
                    starHoverColor='#ead42c'
                    rating={rating}
                    numberOfStars={5}
                    starSpacing={'3px'}
                  />
                </div>
              </FormControl>
            </div>
            <Button
              className={classes.button}
              fullWidth
              size='large'
              variant='contained'
              color='primary'
              type='submit'
            >
              Post your review
            </Button>
          </form>
        </div>
      ) : (
        <p className='m-caseReviews__noReview'>
          The client has not yet submitted the review
        </p>
      )}
    </div>
  )
}

export default CaseReviews
