import { useCallback } from 'react'
import { firestore, GeoPoint } from 'services/firebase'

const useGetLawyers = () => {
  return useCallback(async (params) => {
    const { latitude, longitude, distance, expertise, practiceArea } = params
    window.localStorage.setItem('search-distance', distance)
    // ~1 mile of lat and lon in degrees
    let lat = 0.0144927536231884
    let lon = 0.0181818181818182
    let lowerLat = latitude - lat * distance
    let lowerLon = longitude - lon * distance
    let greaterLat = latitude + lat * distance
    let greaterLon = longitude + lon * distance
    let lesserGeoPoint = new GeoPoint(lowerLat, lowerLon)
    let greaterGeoPoint = new GeoPoint(greaterLat, greaterLon)

    let docRef = firestore.collection('users')
    let query = docRef
      .where('l', '>=', lesserGeoPoint)
      .where('l', '<=', greaterGeoPoint)
      .where('role', '==', 'lawyer')
      .where('validatedAccount', '==', true)

    if (practiceArea !== 'all') {
      query = query.where('expertiseAreas', 'array-contains', practiceArea)
    } else {
      query = query.where('expertise', 'array-contains', expertise)
    }

    return query.get().then((snap) => {
      return snap.docs.map((item) => {
        return item.data()
      })
    })
  }, [])
}

export default useGetLawyers
