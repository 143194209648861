import React, { useEffect } from 'react'
import './VideoCall.scss'

import { styled, Theme } from '@material-ui/core/styles'
import Room from 'components/molecules/Twilio/Room/Room'
import PreJoinScreens from 'components/molecules/Twilio/PreJoinScreens/PreJoinScreens'
import { VideoProvider } from 'components/molecules/Twilio/VideoProvider'

import { useAppState } from 'context/state'
import useConnectionOptions from 'utils/useConnectionOptions'
import ReconnectingNotification from 'components/molecules/Twilio/ReconnectingNotification/ReconnectingNotification'
import MobileTopMenuBar from 'components/molecules/Twilio/MobileTopMenuBar/MobileTopMenuBar'
import MenuBar from 'components/molecules/Twilio/MenuBar/MenuBar'
import useRoomState from 'hooks/useRoomState/useRoomState'
import UnsupportedBrowserWarning from 'components/molecules/Twilio/UnsupportedBrowserWarning/UnsupportedBrowserWarning'
import ErrorDialog from 'components/molecules/Twilio/ErrorDialog/ErrorDialog'
import useAddCaseSystemMessage from 'services/firebase/hooks/chat/useAddCaseSystemMessage'
import { auth } from 'services/firebase'

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}))

const VideoApp = ({
  chatId,
  handleOpenVideoCall,
  name,
  avatar,
  contactUid,
}) => {
  const roomState = useRoomState()
  const { uid } = auth.currentUser
  const doAddCaseSystemMessage = useAddCaseSystemMessage()

  useEffect(() => {
    // doAddCaseSystemMessage(
    //   caseId,
    //   offer.senderUid,
    //   offer.receiverUid,
    //   'Offer accepted!',
    //   'message'
    // )

    if (roomState === 'connected') {
      doAddCaseSystemMessage(
        null,
        uid,
        contactUid,
        `${name} entered the video room`,
        'videoRoom',
        null,
        uid,
        chatId
      )
    }
  }, [roomState])

  return (
    <div className='o-videoCall'>
      <div className='o-videoCall__content'>
        {roomState === 'disconnected' ? (
          <PreJoinScreens
            chatId={chatId}
            handleOpenVideoCall={handleOpenVideoCall}
            name={name}
            avatar={avatar}
          />
        ) : (
          <Main>
            <ReconnectingNotification />
            <MobileTopMenuBar />
            <Room />
            <MenuBar />
          </Main>
        )}
      </div>
    </div>
  )
}

const VideoCall = ({
  chatId,
  handleOpenVideoCall,
  name,
  avatar,
  contactUid,
}) => {
  const { error, setError } = useAppState()
  const connectionOptions = useConnectionOptions()

  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <VideoApp
          handleOpenVideoCall={handleOpenVideoCall}
          chatId={chatId}
          name={name}
          avatar={avatar}
          contactUid={contactUid}
        />
      </VideoProvider>
    </UnsupportedBrowserWarning>
  )
}

export default VideoCall
