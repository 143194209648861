import React, { useState, useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { Button } from '@material-ui/core'
import OwlCarousel from 'react-owl-carousel2'
import SimpleBar from 'simplebar-react'
import { Spinner } from 'react-activity'
import useLawyersSearch from 'hooks/useLawyersSearch'

import { ReactComponent as LeftArrow } from 'images/left-thin-chevron.svg'
import { ReactComponent as RightArrow } from 'images/right-thin-chevron.svg'

import ResultsFilter from 'components/molecules/ResultsFilter'
import ResultsSort from 'components/molecules/ResultsSort'
import LawyerCard from 'components/molecules/LawyersCards/LawyerCard'

import { ReactComponent as PinIcon } from 'images/icon-pin.svg'
import { ReactComponent as FilterIcon } from 'images/icon-filter.svg'
import { ReactComponent as SortIcon } from 'images/icon-sort.svg'
import 'simplebar/dist/simplebar.min.css'
import 'react-owl-carousel2/src/owl.carousel.css'
import './Results.scss'
import ResultsMap from '../ResultsMap'

const compareByPrice = (a, b) => {
  if (a.hourPrice === b.hourPrice) return 0
  return a.hourPrice < b.hourPrice ? -1 : 1
}

const compareByExperience = (a, b) => {
  if (a.practisingSince === b.practisingSince) return 0
  return a.practisingSince < b.practisingSince ? -1 : 1
}

const compareByRating = (a, b) => {
  if (a.rating === b.rating) return 0
  return a.rating < b.rating ? -1 : 1
}

const compareByDistance = (a, b) => {
  const distanceA = parseFloat(a.distance)
  const distanceB = parseFloat(b.distance)
  if (distanceA === distanceB) return 0
  return distanceA < distanceB ? -1 : 1
}

const Results = ({ handleShowSearchBar }) => {
  const listRef = useRef()
  const perPage = 6
  const [lawyers] = useLawyersSearch()
  const [showFilters, setShowFilters] = useState(false)
  const [showSort, setShowSort] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [cheapest, setCheapest] = useState(null)
  const [mostExperienced, setMostExperienced] = useState(null)
  const [orderedLawyersList, setOrderedLawyersList] = useState([])
  const [lawyersList, setLawyersList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [listOrder, setListOrder] = useState('distance-ASC')

  const handleShowOptions = (show, item) => {
    setShowFilters(false)
    setShowSort(false)
    setShowMap(false)
    handleShowSearchBar(true)
    if (item === 'filters') {
      setShowFilters(show)
    }
    if (item === 'sort') {
      setShowSort(show)
    }
    if (item === 'map') {
      setShowMap(show)
      handleShowSearchBar(!show)
    }
  }

  const handleSortList = () => {
    setCurrentPage(1)
    let currentList = [...lawyers.withFilters]
    let newOrderedList = []
    const currentOrder = listOrder.split('-')

    switch (currentOrder[0]) {
      case 'rating':
        newOrderedList = currentList.sort(compareByRating)
        break
      case 'practisingSince':
        newOrderedList = currentList.sort(compareByExperience)
        break
      case 'hourPrice':
        newOrderedList = currentList.sort(compareByPrice)
        break
      default:
        newOrderedList = currentList.sort(compareByDistance)
    }

    if (currentOrder[1] === 'DESC') {
      newOrderedList = newOrderedList.reverse()
    }
    setOrderedLawyersList([...newOrderedList, ...lawyers.unregisteredLawyers])
    if (listRef.current) {
      listRef.current.getScrollElement().scrollTop = 0
    }
  }

  useEffect(() => {
    handleSortList()
  }, [lawyers, listOrder]) // eslint-disable-line

  useEffect(() => {
    const byPrice = [...lawyers.withFilters.sort(compareByPrice)]
    const byExperience = [...lawyers.withFilters.sort(compareByExperience)]

    if (lawyers.withFilters) {
      setCheapest(byPrice.shift())
      setMostExperienced(byExperience.pop())
    }
    setLawyersList(orderedLawyersList.slice(0, perPage))
  }, [orderedLawyersList]) // eslint-disable-line

  const handleChangePage = (val) => {
    let pageNumber = val

    if (val === 'prev') {
      pageNumber = currentPage - 1
    }
    if (val === 'next') {
      pageNumber = currentPage + 1
    }

    const paginatedList = orderedLawyersList.slice(
      (pageNumber - 1) * perPage,
      pageNumber * perPage
    )

    setCurrentPage(pageNumber)
    setLawyersList(paginatedList)
  }

  useEffect(() => {
    const totalItems =
      lawyers.withFilters.length + lawyers.unregisteredLawyers.length
    let totalPages = Math.ceil(totalItems / perPage)

    setTotalPages(totalPages)

    let startPage, endPage
    if (totalPages <= 5) {
      startPage = 1
      endPage = totalPages
    } else {
      if (currentPage <= 2) {
        startPage = 1
        endPage = 5
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4
        endPage = totalPages
      } else {
        startPage = currentPage - 2
        endPage = currentPage + 2
      }
    }

    const pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i
    )

    setPages(pages)
    if (listRef.current) {
      listRef.current.recalculate()
      listRef.current.getScrollElement().scrollTop = 0
    }
  }, [currentPage, lawyers])

  return (
    <section className='o-results'>
      <div className='relative flex mx-auto'>
        <div className={`o-results__content ${showMap ? '-hide' : ''}`}>
          <div className='container o-results__content--container'>
            {lawyers.loading && (
              <div className='o-results__content--loader'>
                <Spinner color='#18a0db' size={32} speed={0.8} />
              </div>
            )}
            <div className='o-results__content--actions'>
              <div
                className={`o-results__content--actions-filter ${
                  showFilters ? '-active' : ''
                }`}
              >
                <Button
                  variant='outlined'
                  onClick={() => handleShowOptions(!showFilters, 'filters')}
                >
                  <span>Filter</span>
                  <FilterIcon />
                </Button>
              </div>
              <div
                className={`o-results__content--actions-sort ${
                  showSort ? '-active' : ''
                }`}
              >
                <Button
                  variant='outlined'
                  onClick={() => handleShowOptions(!showSort, 'sort')}
                >
                  <span>Sort</span>
                  <SortIcon />
                </Button>
              </div>
              <div className='o-results__content--actions-map'>
                <Button
                  variant='outlined'
                  onClick={() => handleShowOptions(!showMap, 'map')}
                >
                  <span>Map</span>
                  <PinIcon />
                </Button>
              </div>
            </div>
            <div className='o-results__content--sortFilterContainer'>
              <div className='o-results__content--sortFilter'>
                <ResultsFilter
                  open={showFilters}
                  handleShowFilters={setShowFilters}
                />
                <ResultsSort
                  open={showSort}
                  handleShowFilters={setShowSort}
                  handleOrder={setListOrder}
                  currentOrder={listOrder}
                />
              </div>
              <div className='o-results__content--info'>
                <div className='o-results__content--info-item'>
                  <span>
                    {lawyers.withFilters.length +
                      lawyers.unregisteredLawyers.length}{' '}
                    Lawyers <br />
                    Available
                  </span>
                </div>
                <div className='o-results__content--info-item'>
                  <span>Cheapest</span>
                  <b>£{cheapest ? cheapest.hourPrice : 0}</b>
                </div>
                <div className='o-results__content--info-divider' />
                <div className='o-results__content--info-item'>
                  <span>Most Experienced</span>
                  <b>£{mostExperienced ? mostExperienced.hourPrice : 0}</b>
                </div>
              </div>
              {isMobile ? (
                <div className='o-results__content--list'>
                  {lawyersList.map((item, key) => (
                    <div
                      key={`lawyer-card-${key}`}
                      className='o-results__content--list-item'
                    >
                      <LawyerCard data={item} />
                    </div>
                  ))}
                </div>
              ) : (
                <SimpleBar
                  ref={listRef}
                  autoHide={false}
                  style={{ maxHeight: 600, minHeight: 600 }}
                >
                  <div className='o-results__content--list'>
                    {lawyersList.length > 0 ? (
                      lawyersList.map((item, key) => (
                        <div
                          key={`lawyer-card-${key}`}
                          className='o-results__content--list-item'
                        >
                          <LawyerCard data={item} />
                        </div>
                      ))
                    ) : (
                      <div className='o-results__content--empty'>
                        No results found
                      </div>
                    )}
                  </div>
                </SimpleBar>
              )}
            </div>
          </div>
          {pages.length > 1 && (
            <div className='o-results__content--paginator'>
              <div className='container o-results__content--container'>
                <ul>
                  <li>
                    <Button
                      disabled={currentPage === 1}
                      onClick={() => handleChangePage('prev')}
                    >
                      <LeftArrow />
                    </Button>
                  </li>
                  {pages &&
                    pages.map((item) => (
                      <li
                        key={`page-${item}`}
                        className={currentPage === item ? '-active' : ''}
                        onClick={() => handleChangePage(item)}
                      >
                        <Button>{item}</Button>
                      </li>
                    ))}
                  <li>
                    <Button
                      disabled={currentPage === totalPages}
                      onClick={() => handleChangePage('next')}
                    >
                      <RightArrow />
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className={`o-results__map ${showMap ? '-show' : ''}`}>
          <ResultsMap
            list={[...lawyers.withFilters, ...lawyers.unregisteredLawyers]}
            handleShowOptions={handleShowOptions}
          />
          <div className='o-results__map--cards'>
            {lawyersList.length > 0 && (
              <OwlCarousel
                options={{
                  items: 1,
                  nav: true,
                  loop: true,
                  autoplay: false,
                  dots: false,
                  navText: [
                    '<i class="chevron-left"/>',
                    '<i class="chevron-right"/>',
                  ],
                }}
              >
                {lawyersList.map((item, key) => (
                  <div
                    key={`lawyer-card-carousel-${key}`}
                    className='o-results__map--cards--item'
                  >
                    <LawyerCard data={item} />
                  </div>
                ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Results
