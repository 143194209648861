import React from 'react'
import socialIcon from 'images/icon-social.png'
import Generic from '../Generic'

import './SocialLinks.scss'

const SocialLinks = () => {
  return (
    <div className='m-socialLinks'>
      <header>
        <span>Social Links</span>
        <img src={socialIcon} alt='Contact' />
      </header>
      <main>
        <Generic
          type='facebook'
          title='Facebook'
          emptyMessage='Link not informed'
        />
        <Generic
          type='linkedin'
          title='LinkedIn'
          emptyMessage='Link not informed'
        />
        <Generic
          type='twitter'
          title='Twitter'
          emptyMessage='Link not informed'
        />
      </main>
    </div>
  )
}

export default SocialLinks
