import { useCallback } from 'react'
import { firestore } from 'services/firebase'

const useRegisterReview = () => {
  return useCallback(async (caseId, reviewData, uid, receiverUid) => {
    await firestore
      .collection('reviews')
      .doc(receiverUid)
      .collection('items')
      .doc()
      .set({
        ...reviewData,
        uid,
        caseId,
        date: new Date(),
      })

    await firestore
      .collection('reviews')
      .doc(receiverUid)
      .collection('items')
      .get()
      .then(async (snap) => {
        if (snap) {
          const totalOfReviews = snap.size
          const reviews = snap.docs
          let newRatingAvg = 0
          let totalOfRating = 0
          if (totalOfReviews > 0) {
            const total = await reviews.map((review) => {
              const current = review.data()
              totalOfRating = totalOfRating + current.rating
            })
            Promise.all(total).then(() => {
              newRatingAvg = Math.abs(totalOfRating / totalOfReviews).toFixed(2)
              firestore
                .collection('users')
                .doc(receiverUid)
                .update({ rating: parseFloat(newRatingAvg) })
            })
          }
        }
      })

    return firestore
      .collection('cases')
      .doc(caseId)
      .collection('reviews')
      .doc(uid)
      .set({ ...reviewData, date: new Date(), uid })
  }, [])
}

export default useRegisterReview
