import { useCallback } from 'react'
import { auth, db, firestore } from 'services/firebase'
import useGetUserByUid from 'services/firebase/hooks/users/useGetUserByUid'
import moment from 'moment'
import { toast } from 'react-toastify'

const useUpdateCaseById = () => {
  return useCallback(async (caseId, data, remove = false) => {
    if (remove) {
      const offerData = await firestore
        .collection('cases')
        .doc(caseId)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            toast.error('Offer not found!')
          }
          return doc.data()
        })
      if (offerData.status === 'offer') {
        return firestore.collection('cases').doc(caseId).delete()
      } else {
        toast.error(
          'This offer cannot be removed as it has already been accepted by the customer.'
        )
        toast.error('Please refresh the page to see the update on this offer.')
        throw new Error('already_accepted')
      }
    }
    return firestore.collection('cases').doc(caseId).update(data)
  }, [])
}

export default useUpdateCaseById
