import React, { useCallback, useEffect, useState } from 'react'
import $ from 'jquery'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter as Router } from 'react-router-dom'
import Header from 'components/template/Header'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Footer from 'components/template/Footer/Footer'
import Routes from './routes'

import 'react-toastify/dist/ReactToastify.css'
import './styles/App.scss'
import { auth, db } from './services/firebase'
import Loader from './components/atoms/Loader'
import useUserInfo from './hooks/useUserInfo'
import useChangeStatus from './services/firebase/hooks/auth/useChangeStatus'
import { NotificationsProvider } from './context/notifications'
import { ChatProvider } from './context/chat'
import AppStateProvider from './context/state'
import ReactGA from 'react-ga'

window.jQuery = window.$ = $

const theme = createMuiTheme({
  palette: {
    primary: {
      contrastText: '#fff',
      500: '#18a1db',
    },
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$disabled': {
          color: '#eee',
          background: '#222',
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: 20,
        minWidth: 120,
      },
    },
    MuiTextField: {
      root: {
        marginBottom: 0,
        overflow: 'visible !important',
        backgroundColor: '#fff',
        position: 'relative',
        borderRadius: 4,
        '&:focus': {
          backgroundColor: '#fff',
        },
        '&:hover': {
          backgroundColor: '#fff',
        },
      },
    },
    MuiFilledInput: {
      root: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        overflow: 'hidden',
        width: '100%',
        backgroundColor: '#fff !important',
        borderRadius: 4,
        '&$focused': {
          backgroundColor: '#fff',
        },
      },
      input: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.54)',
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 400,
        width: '100%',
      },
    },
    MuiFormLabel: {
      root: {
        '&$disabled': {
          opacity: 0.4,
          color: '#999 !important',
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: '#bebebe',
        backgroundColor: '#fff !important',
      },
    },
    MuiFormHelperText: {
      root: {
        opacity: 0.95,
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 400,
        textAlign: 'right',
        position: 'absolute',
        top: '100%',
        right: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        marginRight: 5,
      },
    },
    MuiSelect: {
      select: {
        backgroundColor: '#f9f7f7',
        color: 'rgba(0, 0, 0, 0.54)',
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 400,
        '&:focus': {
          background: '#f6fcff !important',
        },
      },
    },
    MuiButton: {
      outlined: {
        borderWidth: 2,
        borderColor: '#18a1db',
        color: '#18a1db',
        fontSize: 14,
        transition: 'all .2s linear',
        padding: '12px 20px',
        '&:hover': {
          backgroundColor: '#18a1db',
          color: '#fff',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          opacity: '0.3 !important',
        },
      },
    },
  },
})

const App = () => {
  const doChangeStatus = useChangeStatus()
  const [, setUserInfo] = useUserInfo()
  const [isLoading, setIsLoading] = useState(true)

  const handleUserCheck = useCallback(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        db.ref(`users/${user.uid}`)
          .once('value')
          .then((snap) => {
            if (snap) {
              doChangeStatus()
              setUserInfo(snap.val())
              setIsLoading(false)
            }
          })
      } else {
        setIsLoading(false)
      }
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    ReactGA.initialize('UA-184078356-1')
    handleUserCheck()
  }, []) // eslint-disable-line

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [window.location.pathname]) // eslint-disable-line

  return (
    <MuiThemeProvider theme={theme}>
      <div className='App'>
        {isLoading ? (
          <Loader />
        ) : (
          <Router>
            <AppStateProvider>
              <NotificationsProvider>
                <ChatProvider>
                  <Header />
                </ChatProvider>
                <Routes />
                <Footer />
              </NotificationsProvider>
            </AppStateProvider>
          </Router>
        )}
      </div>
      <ToastContainer />
    </MuiThemeProvider>
  )
}

export default App
