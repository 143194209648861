import React, { useState } from 'react'
import {
  FormControl,
  TextField,
  Button,
  makeStyles,
  InputAdornment,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import Email from '@material-ui/icons/Email'
import { useHistory, Link } from 'react-router-dom'
import { auth, db, firestore } from 'services/firebase'
import useSendPasswordResetEmail from 'services/firebase/hooks/auth/useSendPasswordResetEmail'
import { Spinner } from 'react-activity'

import 'react-activity/dist/react-activity.css'
import './Login.scss'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
  },
  formControlLabel: {
    marginLeft: 0,
    color: '#888',
    fontFamily: 'Poppins',
  },
  checkboxLabel: {
    fontSize: 14,
  },
}))

const ForgotPasswordPage = () => {
  const history = useHistory()

  const doSendPasswordResetEmail = useSendPasswordResetEmail()

  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    if (email) {
      doSendPasswordResetEmail(email)
        .then(() => {
          toast.success(
            'A link to reset your password has been sent to your email.'
          )
          return history.push('/login')
        })
        .catch((err) => {
          toast.error(err.message)
          setSubmitting(false)
        })
    } else {
      setSubmitting(false)
      toast.error('You need to enter your email')
      return false
    }
  }

  return (
    <div className={`p-loginPage`}>
      <div className='p-loginPage__intro'>
        <div className='p-loginPage__intro--content'>
          <img src={require('images/login-logo.png')} alt='' />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut porta
            malesuada molestie. Donec neque neque, sollicitudin vitae nulla at,
            hendrerit viverra quam. Duis vestibulum lectus ac augue ultricies,
            nec tincidun.
          </p>
        </div>
      </div>
      <div className='p-loginPage__forms'>
        <div className='p-loginPage__forms--content'>
          <h1>Forgot my password</h1>
          <form
            onSubmit={handleSubmit}
            className='p-loginPage__form'
            autoComplete='none'
          >
            <div className='p-loginPage__form--field'>
              <FormControl component='div' fullWidth variant='filled'>
                <TextField
                  label='Enter Email Address'
                  variant='filled'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete='none'
                  InputProps={{
                    disableUnderline: true,
                    autoComplete: 'none',
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>

            <div className='p-loginPage__form--action'>
              <FormControl component='div' fullWidth variant='outlined'>
                <Button
                  type='submit'
                  className={classes.button}
                  size='large'
                  variant='contained'
                  color='primary'
                >
                  {submitting ? <Spinner color='#fff' /> : 'Reset password'}
                </Button>
              </FormControl>
            </div>
            <div className='p-loginPage__form--extra'>
              <Link to='/login'>Back to login</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordPage
