import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { auth } from 'services/firebase'

const PrivateRoute = ({ component: Component, path, exact }) => {
  const isLoggedIn = auth.currentUser

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to='/login' />
      }
    />
  )
}

export default PrivateRoute
