import React, { useState, useEffect } from 'react'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'
import useUserInfo from 'hooks/useUserInfo'
import useUpdateBankDetails from 'services/firebase/hooks/users/useUpdateBankDetails'
import useGetUserBankDetails from 'services/firebase/hooks/users/useGetUserBankDetails'
import { withRouter } from 'react-router-dom'
import { Button, makeStyles } from '@material-ui/core'
import InputMask from 'react-input-mask'

import './BankAccount.scss'
import Loader from '../../../atoms/Loader'
import { Spinner } from 'react-activity'
import { toast } from 'react-toastify'

const initialFormValues = {
  accountHolderName: '',
  routingNumber: '',
  accountNumber: '',
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2, 0, 0),
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    outline: 'none',
    zIndex: 1000,
    color: '#fff',
  },
}))

const BankAccount = ({ history }) => {
  const [userInfo] = useUserInfo()
  const classes = useStyles()
  const doGetUserBankDetails = useGetUserBankDetails()
  const [edit, setEdit] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const doUpdateBankDetails = useUpdateBankDetails()
  const [values, setValues] = useState(initialFormValues)
  const [routingNumberFormatted, setRoutingNumberFormatted] = useState(null)

  const getUserData = async () => {
    const [bankDetails] = await doGetUserBankDetails(userInfo.uid)
    if (bankDetails) {
      setValues(bankDetails)
      if (bankDetails.routingNumber) {
        const parts = bankDetails.routingNumber.match(/.{1,2}/g)
        setRoutingNumberFormatted(parts.join('-'))
      }
    }
    if (history.location.hash === '#bank-details') {
      document.getElementById('bank-details').scrollIntoView()
      setEdit(true)
    }
  }

  useEffect(() => {
    getUserData()
  }, [])

  const handleEdit = async () => {
    if (edit) {
      setIsSubmitting(true)
      doUpdateBankDetails({ ...values })
        .then(() => {
          const parts = values.routingNumber.match(/.{1,2}/g)
          setRoutingNumberFormatted(parts.join('-'))
          setEdit(false)
          window.location.hash = ''
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    } else {
      setEdit(true)
    }
  }

  const handleChangeValues = (e) => {
    const val =
      e.target.name === 'routingNumber'
        ? e.target.value.replace(/\D/g, '')
        : e.target.value
    setValues({ ...values, [e.target.name]: val })
  }

  return (
    <div id='bank-details' className='o-lawyerProfile__aside--form-box'>
      <header>
        <div className='flex flex-col'>
          <strong>Bank Account Details</strong>
        </div>
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <CheckIcon /> : <EditIcon />}
        </button>
      </header>
      <main>
        <small>
          Your payment from clients will be sent to this account within 5 days
          of completing a case.
        </small>
        <small>Your account will never be charged without consent.</small>

        {edit ? (
          <>
            <div className='bg-red-200 flex flex-col p-4 rounded'>
              <small className='mb-2'>
                Please check the account details are correct.
              </small>
              <small className='font-semibold'>
                Lawyerup is not responsible for withdrawals made to bank
                accounts with incorrect details.
              </small>
            </div>
            <label>Account holder name</label>
            <input
              required
              name='accountHolderName'
              type='text'
              value={values.accountHolderName}
              onChange={handleChangeValues}
            />
            <label>Account number</label>
            <input
              required
              name='accountNumber'
              type='text'
              value={values.accountNumber}
              onChange={handleChangeValues}
            />
            <small>
              The account number for the bank account, in string form. Must be a
              checking account.
            </small>
            <label>Sort code</label>
            <InputMask
              mask='99-99-99'
              maskChar={null}
              required
              name='routingNumber'
              type='text'
              value={values.routingNumber}
              onChange={handleChangeValues}
            />

            <small>
              The routing number, sort code, or other country-appropriate
              institution number for the bank account.{' '}
            </small>
            <Button
              size='large'
              variant='contained'
              color='primary'
              type='button'
              onClick={handleEdit}
              className={classes.button}
            >
              {isSubmitting ? <Spinner color='#fff' /> : 'Save'}
            </Button>
          </>
        ) : (
          <>
            <ul>
              <li>
                <span>Account holder name</span>
                <b>{values.accountHolderName || '--'}</b>
              </li>
              <li>
                <span>Account number</span>
                <b>{values.accountNumber || '--'}</b>
              </li>
              <li>
                <span>Sort code</span>
                <b>{routingNumberFormatted || '--'}</b>
              </li>
            </ul>
          </>
        )}
      </main>
    </div>
  )
}

export default withRouter(BankAccount)
