import { useCallback } from 'react'
import { firestore } from 'services/firebase'

const useGetLawyerReviews = () => {
  return useCallback(async (uid) => {
    return firestore
      .collection('reviews')
      .doc(uid)
      .collection('items')
      .orderBy('date', 'desc')
      .get()
      .then((snap) => {
        return snap.docs.map((item) => {
          return item.data()
        })
      })
  }, [])
}

export default useGetLawyerReviews
