import { useCallback } from 'react'
import { db, firestore } from 'services/firebase'

const useCreateUserOnFirestore = () => {
  return useCallback(async (user) => {
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      phone: user.phone || '',
      role: user.role || 'client'
    }
    await db.ref(`/users/${user.uid}`).set(userData)
    return firestore.collection('users').doc(user.uid).set(userData)
  }, [])
}

export default useCreateUserOnFirestore
