import React, { createContext, useContext, useState } from 'react'

export const ChatContext = createContext()
export const useChat = () => useContext(ChatContext)

export const ChatProvider = (props) => {
  const [hasUnreadChat, setHasUnreadChat] = useState(false)

  return (
    <ChatContext.Provider
      value={{ hasUnreadChat, setHasUnreadChat }}
      {...props}
    />
  )
}
