import React, { useEffect, useRef, useState } from 'react'

import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'

import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'
import './HourPrice.scss'

const HourPrice = () => {
  const elementRef = useRef(null)
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.hourPrice)

  const handleEdit = () => {
    if (edit) {
      if (value) {
        doUpdateUserProfile({
          hourPrice: parseFloat(value),
        })
      }
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  useEffect(() => {
    if (edit) {
      elementRef.current.focus()
    }
  }, [edit])

  return (
    <div className='m-hourPrice'>
      <div className='m-hourPrice__box'>
        <i>£</i>
        {edit ? (
          <>
            <input
              ref={elementRef}
              type='text'
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <span>per hour</span>to
          </>
        ) : (
          <span>{value} per hour</span>
        )}
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <CheckIcon /> : <EditIcon />}
        </button>
      </div>
    </div>
  )
}

export default HourPrice
