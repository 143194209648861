import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatter } from 'utils/functions'
import moment from 'moment'
import { expertise } from 'utils/constants'
import './CaseCard.scss'
import { auth } from 'services/firebase'

const Card = ({ data }) => {
  const { uid } = auth.currentUser
  const [image, setImage] = useState(require('images/empty-avatar.jpg'))
  const [name, setName] = useState(null)

  useEffect(() => {
    setImage(uid === data.senderUid ? data.receiverImage : data.senderImage)
    setName(uid === data.senderUid ? data.receiverName : data.senderName)
  }, [])

  return (
    <>
      <div className='m-caseCard__content'>
        <div className='m-caseCard__content--image'>
          <img src={image} alt='' />
        </div>
        <div className='m-caseCard__content--info'>
          <header>
            <div className='m-caseCard__content--client'>
              <strong>{name}</strong>
              <span>
                {expertise.find((exp) => exp.value === data.for).label}
              </span>
            </div>
            <div className='m-caseCard__content--deadline'>
              <strong>Deadline</strong>
              <span>{moment(data.deadline.toDate()).format('MM/DD/YYYY')}</span>
            </div>
          </header>
          <main>
            <p>{data.description}</p>
          </main>
        </div>
      </div>
      <footer>
        {data.paymentType === 'noWinNoFee' && (
          <span>
            {uid === data.senderUid ? 'You' : 'Lawyer'} {data.rate}%
            <div className='w-6' />
            {uid === data.senderUid ? 'Client' : 'You'} {data.clientRate}%
          </span>
        )}

        {data.paymentType === 'legalAid' && <span>Legal Aid</span>}

        {data.paymentType !== 'legalAid' &&
          data.paymentType !== 'noWinNoFee' && (
            <span>{formatter.format(data.amount)}</span>
          )}
      </footer>
    </>
  )
}

const CaseCard = ({ type, data, withoutLink = false }) => {
  if (!data) return false

  return withoutLink ? (
    <div className={`m-caseCard -${type} -withoutHover`}>
      <Card data={data} />
    </div>
  ) : (
    <Link className={`m-caseCard -${type}`} to={`/panel/cases/${data.caseId}`}>
      <Card data={data} />
    </Link>
  )
}

export default CaseCard
