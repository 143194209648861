import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { formatter } from 'utils/functions'

const Payments = ({ total, complete, label }) => {
  const percentage = (complete * 100) / total
  // formatter.format(complete)
  const options = {
    chart: {
      height: 200,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
        dataLabels: {
          name: {
            offsetY: -5,
            show: true,
          },
          value: {
            offsetY: 0,
            show: true,
            label: label,
            formatter: function (val) {
              return formatter.format(complete)
            },
          },
        },
      },
    },
    labels: [label],
  }

  return (
    <ReactApexChart
      options={options}
      series={[percentage]}
      type='radialBar'
      height={200}
      width={200}
    />
  )
}

export default Payments
