import firebase from 'firebase'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

let auth, db, firestore, GeoPoint, storage, facebookAuthProvider

if (!firebase.apps.length) {
  firebase.initializeApp(config)
  firebase.analytics()
  auth = firebase.auth()
  GeoPoint = firebase.firestore.GeoPoint
  db = firebase.database()
  firestore = firebase.firestore()
  storage = firebase.storage()
  facebookAuthProvider = new firebase.auth.FacebookAuthProvider()
}

export { auth, db, firestore, GeoPoint, storage, facebookAuthProvider }
