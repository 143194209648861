import React, { useState } from 'react'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'
import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'
import { ReactComponent as PlusIcon } from 'images/icon-square-plus.svg'

const Linkedin = () => {
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.linkedIn)
  const handleEdit = () => {
    if (edit) {
      if (value !== userInfo.linkedIn) {
        doUpdateUserProfile({
          linkedIn: value,
        })
      }
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  return (
    <div className='o-lawyerProfile__aside--form-box'>
      <header>
        <strong>LinkedIn</strong>
        {(userInfo.linkedIn || edit) && (
          <button
            onClick={handleEdit}
            type='button'
            className={edit ? '-editing' : ''}
          >
            {edit ? <CheckIcon /> : <EditIcon />}
          </button>
        )}
      </header>
      <main className='linkedin'>
        {edit ? (
          <input
            type='text'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : !userInfo.linkedIn ? (
          <button onClick={handleEdit} type='button'>
            <PlusIcon />
            <span>Add LinkedIn</span>
          </button>
        ) : (
          <span className='link'>
            <a
              href={value}
              title={value}
              rel='noopener noreferrer'
              target='_blank'
            >
              {value}
            </a>
          </span>
        )}
      </main>
    </div>
  )
}

export default Linkedin
