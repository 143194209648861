import React from 'react'

import '../TermsAndConditions/TermsAndPrivacy.scss'

const PrivacyAndPolicy = () => {
  const email = 'ku.gro.p*ur|*eyw*al@of*n|i'

  const formatT = (txt) => {
    return txt
      .replace(new RegExp(/\|/, 'g'), '')
      .replace(new RegExp(/\*/, 'g'), '')
      .split('')
      .reverse()
      .join('')
  }
  return (
    <div className='p-termsAndPrivacy'>
      <div className='container'>
        <div className='content'>
          <p>
            <b className='center'>ECLATECH LIMITED</b>
            <b className='center'>PRIVACY AND COOKIES POLICY</b>
          </p>
          <p>
            As the operators of www.lawyerup.org.uk (
            <span>&quot;Website&quot;</span>), Eclatech Limited (
            <span>&quot;We&quot;, &quot;Us&quot;</span>) with company number
            11777022 and having its registered address at 4 Old Park Lane,
            Mayfair, London, United Kingdom, W1K 1QW, is committed to protecting
            and respecting your privacy. This Privacy Policy (
            <span>&quot;Policy&quot;</span>) (together with our terms and
            conditions) sets out the basis on which the personal information
            collected from you, as a general user (<span>&quot;User&quot;</span>
            ) or as a lawyer or other legal professional (
            <span>&quot;Lawyer&quot;</span>), or that you provide to us will be
            processed by us. Please read the following carefully to understand
            our views and practices regarding your personal information and how
            we will treat it.
          </p>
          <p>
            For the purpose of the General Data Protection Regulation (
            <span>&quot;GDPR&quot;</span>) and the Data Protection Act 2018
            (collectively the <span>&quot;Data Protection Laws&quot;</span>),
            the Data Controller is Eclatech Limited.
          </p>
          <b className='-subtitle'>INFORMATION WE MAY COLLECT FROM YOU</b>
          <p>
            We collect and process some or all of the following information from
            you in the course of your use of the Website or providing our
            services:
          </p>
          <ul className='-smallSpace'>
            <li>
              Information that you provide by filling in forms on, or send
              messages via, the Website or App. This includes information
              provided if and when you register to use our site, complete a
              profile, sign up to receive email or sms notifications, post
              questions and topics for discussion, or comments on such
              discussions, submit requests for advice, referrals or cases or
              provide further information to or request services from other
              users (such as Lawyers). We may also ask you for information when
              you report a problem with our Website or App.
            </li>
            <li>
              Specifically, personal details such as name, email address, photos
              or any information input when using the Website or App,
              communication with other users through the Website, making or
              responding to contact requests or otherwise making use of any
              function of the Website and App.
            </li>
            <li>
              If you contact us, we may keep a record of that correspondence.
            </li>
            <li>
              We may also ask you to complete surveys that we use for research
              purposes, although you do not have to respond to them.
            </li>
            <li>
              Details of transactions you carry out through our site, if any.
            </li>
            <li>
              Details of your visits to the Website or App including, but not
              limited to, traffic data, location data, weblogs and other
              communication data, and the resources that you access.
            </li>
            <li>Details of any third party sites accessed via the Website.</li>
          </ul>
          <p>
            Where you are a User the provision of your name, email address, and
            phone number is required from you to enable Us to contact you and
            register you as a user of our services.
          </p>
          <p>
            Where you are a Lawyer we will require your company name (or law
            firm), business /personal e-mail address, SRA number and hourly
            rates is required from you to enable Us to contact you and register
            you as a user of our services
          </p>
          <p>
            <u>Lawful basis for processing</u>
          </p>
          <p>
            We rely on &quot;performance of a contract&quot; as the lawful basis
            on which We collect and process your personal data in order to
            fulfil our contractual obligations when you register to use our
            services, and/or contact us in relation to the services you have
            registered to use.
          </p>
          <p>
            We also rely on &quot;performance of a contract&quot; when sharing
            your personal data as part of providing our services. Where you are
            a User, we will share your personal information with relevant
            Lawyers that suit the criteria in your search. Where you are a
            Lawyer, we will share your personal information with Users seeking
            to connect with you in respect of your services.
          </p>
          <p>
            We rely on your consent to use, collect and share your personal data
            in relation to any surveys you participate to.
          </p>
          <p>
            We rely on &quot;legitimate interest&quot; as the lawful basis on
            which we collect and process your personal data in order to:
          </p>
          <ul className='-smallSpace'>
            <li>
              correspond with you to build a potential business relationship,
              including providing you with materials and information we feel may
              be of interest to you, and to respond to any enquiries you have
              made in relation to services we offer.
            </li>
            <li>
              to provide our services pursuant to an agreement with an
              organisation that has engaged you.
            </li>
          </ul>
          <p>We use information held about you in the following ways:</p>
          <ul className='-smallSpace'>
            <li>
              To provide you with our services and deliver you with search
              results or to link you with prospective clients.
            </li>
            <li>
              To ensure that content on the Website and App is presented in the
              most effective manner for you and for the device(s) you use to
              access and view the Website and App;
            </li>
            <li>
              To provide you with information, products or services that you
              request from us or which we feel may interest you.
            </li>
            <li>
              To carry out our obligations arising from any contracts entered
              into between you and Us.
            </li>
            <li>
              To allow you to participate in interactive features of our
              service, when you choose to do so.
            </li>
            <li>To notify you about changes to our service.</li>
          </ul>
          <p>
            <u>Marketing</u>
          </p>
          <p>
            In addition to the above uses We may use your information, to notify
            you about goods or services which may be of interest to you. For
            such uses We will only contact you by electronic means (email or
            SMS) if you have consented to such communication when We collect
            your data, if the contact relates to goods and services similar to
            those which were the subject of a previous transaction with you.
          </p>
          <p>
            If you do not want Us to use your data in this way, please{' '}
            <b>do not</b>
            tick the relevant box situated on the form on which We collect your
            data (for example, the registration form); unsubscribe from our
            electronic communications using the method indicated in the relevant
            communication; or inform us any time by contacting Us at the contact
            details set out below.
          </p>
          <b className='-subtitle'>DISCLOSURE OF YOUR INFORMATION</b>
          <p>
            We may disclose your personal information to any member of our
            group, which means our subsidiaries, our ultimate holding company
            and its subsidiaries, as defined in section 1159 of the UK Companies
            Act 2006 (where applicable).
          </p>
          <p>
            We will not share your contact details unless you are a Lawyer (in
            which case you will enter your contact details that will be publicly
            available) or if you are a User and you submit a contact request for
            a referral or advice, then upon submission your full name and
            contact details will be visible to the Lawyer (or relevant person
            acting on behalf of the Lawyer) who may contact you directly. We may
            ask you to review Services provided to you and if you choose to
            provide a review, your review may be public on the Website and App.
          </p>
          <p>We may disclose your personal information to third parties:</p>
          <ul className='-smallSpace'>
            <li>
              in the event that we sell or buy any business or assets, in which
              case we may disclose your personal data to the prospective seller
              or buyer of such business or assets; or
            </li>
            <li>
              if we or substantially all of our assets are acquired by a third
              party, in which case personal data held by us about our customers
              will be one of the transferred assets; or
            </li>
            <li>
              if we are under a duty to disclose or share your personal data in
              order to comply with any legal obligation, or in order to enforce
              or apply our Website Terms and Conditions and other agreements; or
            </li>
            <li>
              to protect the rights, property, our safety, the Website, our
              users and any third party we interact with the to provide the
              website or app.
            </li>
          </ul>
          <b className='-subtitle'>STORING YOUR PERSONAL DATA</b>
          <p>
            <u>Security</u>
          </p>
          <p>
            We take appropriate measures to ensure that any personal data are
            kept secure and are kept for the duration of your use of our
            service. We limit access to your personal data to those who have a
            genuine business need to know it. Those processing your information
            will do so only in an authorised manner and are subject to a duty of
            confidentiality. All information you provide to Us is stored on our
            secure servers. Any payment transactions will be encrypted using SSL
            technology. Where We have given you (or where you have chosen) a
            password which enables you to access certain parts of Website, you
            are responsible for keeping this password confidential. We ask you
            not to share a password with anyone.
          </p>
          <p>
            We also have procedures in place to deal with any suspected data
            security breach. We will notify you and any applicable regulator of
            a suspected data security breach where We are legally required to do
            so.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although We will do our best to protect your
            personal data, We cannot guarantee the security of your data
            transmitted to the Website; any transmission is at your own risk.
            Once We have received your information, We will use strict
            procedures and security features to try to prevent unauthorised
            access.
          </p>
          <p>
            <u>Keeping your personal data up to date</u>
          </p>
          <p>
            If your personal details change you may update them by accessing the
            relevant page of the Website, or by contacting us using the contact
            details below. If you have any questions about how We use data
            collected which relates to you, please contact us by sending a
            request by email to the contact details below.
          </p>
          <p>
            We will endeavour to update your personal data within seven (7)
            working days of any new or updated personal data being provided to
            Us, in order to ensure that the personal data We hold about you is
            as accurate and up to date as possible.
          </p>
          <p>
            <u>How long we keep your personal data</u>
          </p>
          <ul className='-smallSpace'>
            <li>
              We will hold your name, email address and contact details for 6
              years. We are required to retain this information for this period
              by applicable UK tax law.
            </li>
          </ul>
          <p>
            <u>Where we store your personal data</u>
          </p>
          <p>
            The data that We collect from you may be transferred to, and stored
            at, a destination outside the European Economic Area (
            <b>&quot;EEA&quot;</b>). It may also be processed by staff operating
            outside the EEA who work for Us or for one of our suppliers. Such
            staff maybe engaged in, among other things, the fulfilment of your
            orders, the processing of your payment details and the provision of
            support services. By submitting your personal data, you agree to
            this transfer, storing or processing. We will take all steps
            reasonably necessary to ensure that your data is treated securely
            and in accordance with this Policy.
          </p>
          <p>
            Under the General Data Protection Regulation you have a number of
            important rights free of charge. In summary, those include rights
            to:
          </p>
          <ul className='-smallSpace'>
            <li>
              access to your personal data and to certain other supplementary
              information that this Policy is already designed to address
            </li>
            <li>
              require Us to correct any mistakes in your information which We
              hold
            </li>
            <li>
              require the erasure of personal data concerning you in certain
              situations
            </li>
            <li>
              receive the personal data concerning you which you have provided
              to Us, in a structured, commonly used and machine-readable format
              and have the right to transmit those data to a third party in
              certain situations
            </li>
            <li>
              object at any time to processing of personal data concerning you
              for direct marketing
            </li>
            <li>
              object to decisions being taken by automated means which produce
              legal effects concerning you or similarly significantly affect you
            </li>
            <li>
              object in certain other situations to our continued processing of
              your personal data
            </li>
            <li>
              otherwise restrict our processing of your personal data in certain
              circumstances
            </li>
            <li>
              claim compensation for damages caused by our breach of any data
              protection laws.
            </li>
          </ul>
          <p>
            For further information on each of those rights, including the
            circumstances in which they apply, see the{' '}
            <a
              href='https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/'
              target='_blank'
              rel='noreferrer'
            >
              Guidance from the UK Information Commissioner’s Office (ICO) on
              individuals rights under the General Data Protection Regulation.
            </a>
          </p>
          <p>If you would like to exercise any of those rights, please:</p>
          <ul className='-smallSpace'>
            <li>email, or write to Us</li>
            <li>
              let Us have enough information to identify you (eg user name,
              registration details)
            </li>
            <li>
              let Us have proof of your identity and address (a copy of your
              driving licence or passport and a recent utility or credit card
              bill), and
            </li>
            <li>let Us know the information to which your request relates</li>
          </ul>
          <b className='-subtitle'>HOW TO COMPLAIN</b>
          <p>
            We hope that We can resolve any query or concern you raise about our
            use of your information.
          </p>
          <p>
            The{' '}
            <a
              href='https://eur-lex.europa.eu/TodayOJ/'
              target='_blank'
              rel='noreferrer'
            >
              General Data Protection Regulation
            </a>{' '}
            also gives you right to lodge a complaint with a supervisory
            authority, in particular in the European Union (or European Economic
            Area) state where you work, normally live or where any alleged
            infringement of data protection laws occurred. The supervisory
            authority in the UK is the Information Commissioner who may be
            contacted at{' '}
            <a
              href='https://ico.org.uk/concerns/'
              target='_blank'
              rel='noreferrer'
            >
              https://ico.org.uk/concerns/
            </a>{' '}
            or telephone: 0303 123 1113.
          </p>
          <b>CHANGES TO OUR PRIVACY POLICY</b>
          <p>
            We reserve the right to modify this Policy at any time. Any changes
            We may make to our Policy in the future will be notified and made
            available to you using the Website. Your continued use of the
            services and the Website and/or App shall be deemed your acceptance
            of the varied privacy policy.
          </p>
          <b className='-subtitle'>IP ADDRESSES AND COOKIES</b>
          <p>
            We may collect information about your mobile phone, computer or
            other device from which you access the Website or App including
            where available your IP address, operating system and browser type,
            for systems administration and to report aggregate information to
            our advertisers. This is statistical data about our users’ browsing
            actions and patterns, and does not identify any individual. We may,
            however, use such information in conjunction with the data We have
            about you in order to track your usage of our services.
          </p>
          <p>
            Our Website uses cookies to distinguish you from other users of our
            Website. This helps Us to provide you with a good experience when
            you browse our Website and also allows Us to improve the Website. By
            using our Website you agree to our use of cookies as more
            specifically set out below.
          </p>
          <p>
            A cookie is a small file of letters and numbers that We store on
            your browser or the hard drive of your computer if you agree.
            Cookies contain information that is transferred to your computer's
            hard drive.
          </p>
          <p>The cookies We use include:</p>
          <ul className='-smallSpace'>
            <li>
              <b>&quot;Analytical&quot;</b> cookies. They allow Us to recognise
              and count the number of visitors and to see how visitors move
              around the Website when they are using it. This helps Us to
              improve the way our Website works, for example, by ensuring that
              users are finding what they are looking for easily.
            </li>
            <li>
              <b>&quot;Strictly necessary&quot;</b> cookies. These are cookies
              that are required for the operation of our Website. They include,
              for example, cookies that enable you to log into secure areas of
              our Website, use a shopping cart or make use of e-billing
              services.
            </li>
            <li>
              <b>&quot;Functionality&quot;</b> cookies. These are used to
              recognise you when you return to our Website. This enables Us to
              personalise our content for you, greet you by name and remember
              your preferences (for example, your choice of language or region).
            </li>
            <li>
              <b>&quot;Targeting&quot;</b> cookies. These cookies record your
              visit to our Website, the pages you have visited and the links you
              have followed. We will use this information to make our Website
              and App and the advertising displayed on it more relevant to your
              interests. We may also share this information with third parties
              for this purpose.
            </li>
          </ul>
          <p>
            Please note that our advertisers may also use cookies, over which We
            have no control. The Website may, from time to time, contain links
            to and from the websites of our partner networks, advertisers and
            affiliates. If you follow a link to any of these websites, please
            note that these websites have their own privacy policies and terms
            of use and that We do not accept any responsibility or liability for
            these policies and terms of use. Please check these policies before
            you submit any personal data to these websites.
          </p>
          <p>
            You may block cookies by activating the setting on your browser that
            allows you to refuse the setting of all or some cookies. However, if
            you use your browser settings to block all cookies (including
            essential cookies) you may not be able to access all or parts of the
            Website. Unless you have adjusted your browser setting so that it
            will refuse cookies, our system will issue cookies as soon as you
            visit our Website.
          </p>
          <b className='-subtitle'>CONTACT</b>
          <p>
            All questions, comments and requests regarding this Privacy Policy
            should be addressed to Eclatech Limited, 4 Old Park Lane, Mayfair,
            London, United Kingdom, W1K 1QW, or {formatT(email)}.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyAndPolicy
