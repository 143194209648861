import { useCallback } from 'react'
import { firestore } from 'services/firebase'

const useGetUserBankDetails = () => {
  return useCallback(async (uid) => {
    return firestore
      .collection('users')
      .doc(uid)
      .collection('bankDetails')
      .get()
      .then((snap) => {
        if (snap.size > 0) {
          return snap.docs.map((doc) => doc.data())
        }
        return []
      })
  }, [])
}

export default useGetUserBankDetails
