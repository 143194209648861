import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { auth, facebookAuthProvider } from 'services/firebase'
import useCreateUserOnFirestore from './useCreateUserOnFirestore'

const useSignUpWithFacebook = () => {
  const history = useHistory()
  const doCreateUserOnFirestore = useCreateUserOnFirestore()
  return useCallback(async () => {
    return auth.signInWithPopup(facebookAuthProvider).then((authUser) => {
      doCreateUserOnFirestore(authUser.user)
        .then(() => {
          history.push('/panel/messages')
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }, []) // eslint-disable-line
}

export default useSignUpWithFacebook
