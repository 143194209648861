import React, { useState, useEffect, useCallback } from 'react'
import './ChatContact.scss'
import { auth, db } from 'services/firebase'
import Avatar from 'react-avatar'
import moment from 'moment'

const ChatContact = ({
  members,
  newMessages,
  timestamp,
  handleOnClick,
  roomId,
  lastMessage,
  isActive,
}) => {
  const { uid } = auth.currentUser

  const [name, setName] = useState('-')
  const [avatar, setAvatar] = useState(null)
  const [status, setStatus] = useState('offline')
  const [isLoading, setIsLoading] = useState(true)
  const [contactUid, setContactUid] = useState(null)

  const handleGetContact = useCallback(async (userId) => {
    return db.ref(`users/${userId}`).on('value', function (snap) {
      const userSnap = snap.val()

      handleUserData(
        userSnap.displayName || `${userSnap.name} ${userSnap.surname}`,
        `${userSnap.photoURL}?size=small`
      )
      setStatus(userSnap.status)
      setIsLoading(false)
    })
  }, [])

  const handleUserData = (name, avatar) => {
    setName(name)
    setAvatar(avatar)
  }

  useEffect(() => {
    Object.keys(members).map((userId) => {
      if (userId !== uid) {
        handleGetContact(userId)
        setContactUid(userId)
      }
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    if (isActive) {
      handleOnClick(roomId, contactUid)
    }
  }, [contactUid]) // eslint-disable-line

  // const status = props.status === 1 ? '-online' : (props.status === 2 ? '-away' : '')
  return (
    <button
      title={name}
      onClick={() => handleOnClick(roomId, contactUid)}
      className={`m-chatContact ${isActive ? '-active' : ''} ${
        typeof newMessages !== 'undefined' && newMessages[uid] === true
          ? '-newMessages'
          : ''
      }`}
    >
      {isLoading ? (
        <div className='m-chatContact__placeholder'>
          <div className='m-chatContact__placeholder--avatar' />
          <div className='m-chatContact__placeholder--message' />
        </div>
      ) : (
        <>
          <div className={`m-chatContact__avatar -${status}`}>
            <Avatar
              size='50'
              maxInitials={2}
              round='100%'
              color='#18a1db'
              textSizeRatio={2.5}
              src={avatar}
              name={name}
            />
          </div>
          <div className='m-chatContact__info'>
            <strong>{name}</strong>
            <p>
              {(typeof lastMessage === 'object'
                ? lastMessage[uid]
                : lastMessage) || '--'}
            </p>
          </div>
          <div className='m-chatContact__lastUpdate'>
            {timestamp &&
              moment.unix(timestamp).calendar(null, {
                sameDay: 'HH:mm',
                nextDay: '[Tomorrow]',
                lastDay: '[Yesterday]',
                lastWeek: 'MM/DD/YYYY',
                sameElse: 'MM/DD/YYYY',
              })}
          </div>
        </>
      )}
    </button>
  )
}

export default ChatContact
