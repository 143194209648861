import { useCallback } from 'react'
import { auth, db, firestore } from 'services/firebase'

const useLogout = () => {
  return useCallback(async () => {
    try {
      const { uid } = auth.currentUser
      await firestore.collection('users').doc(uid).update({ onlineNow: false })
      await db
        .ref('/users/' + uid)
        .child('status')
        .set('offline')
      await auth.signOut()

      return true
    } catch (err) {
      console.log('Err sign out', err)
    }
  }, [])
}

export default useLogout
