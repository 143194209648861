import React, { useEffect, useState } from 'react'
import useGetLawyerByUid from 'services/firebase/hooks/lawyers/useGetLawyerByUid'
import { Link } from 'react-router-dom'

const MemberItem = ({ data }) => {
  const doGetLawyerByUid = useGetLawyerByUid()
  const [member, setMember] = useState()

  useEffect(() => {
    if (data) {
      doGetLawyerByUid(data.uid).then((lawyer) => {
        if (lawyer) {
          setMember(lawyer[0])
        }
      })
    }
  }, [data])

  if (!member) return false

  return (
    <Link
      key={`team-member-carousel-${data.uid}`}
      className={`m-profileTeam__item`}
      to={`/profile/${data.uid}`}
    >
      <div className='m-profileTeam__item--image'>
        <img src={member.photoURL} alt='' />
      </div>
      <div className='m-profileTeam__item--info'>
        <strong>{`${member.name} ${member.surname}`}</strong>
        <span>{member.lawFirmName}</span>
      </div>
    </Link>
  )
}

export default MemberItem
