import { useState } from 'react'

export const store = {
  state: {
    original: [],
    withFilters: [],
    unregisteredLawyers: [],
    loading: true,
  },
  setState(value) {
    this.state = value
    this.setters.forEach((setter) => setter(this.state))
  },
  setters: [],
}
store.setState = store.setState.bind(store)

export default function useLawyersSearch() {
  const [state, set] = useState(store.state)
  if (!store.setters.includes(set)) {
    store.setters.push(set)
  }

  return [state, store.setState]
}
