import { useCallback } from 'react'
import { auth, db, firestore } from 'services/firebase'
import useGetUserByUid from 'services/firebase/hooks/users/useGetUserByUid'
import moment from 'moment'

const useRegisterOffer = () => {
  const { uid, displayName } = auth.currentUser
  const doGetUserByUid = useGetUserByUid()

  return useCallback(async (offerData, user, history) => {
    const receiverData = await doGetUserByUid(offerData.receiverUid)
    const documentRef = firestore.collection('cases').doc()

    return documentRef
      .set({
        ...offerData,
        amount: parseFloat(offerData.amount),
        deadline: offerData.deadline.toDate(),
        senderUid: uid,
        senderName: user.name ? `${user.name} ${user.surname}` : displayName,
        senderImage: user.photoURL,
        receiverName:
          receiverData[0].displayName ||
          `${receiverData[0].name} ${receiverData[0].surname}`,
        receiverImage: receiverData[0].photoURL,
        users: [uid, offerData.receiverUid],
        status: 'offer',
      })
      .then(async (snap) => {
        return firestore
          .collection('chats')
          .where('clientUid', '==', offerData.receiverUid)
          .where('lawyerUid', '==', uid)
          .get()
          .then((snapshot) => {
            snapshot.docs.map((chat) => {
              db.ref(`chatMessages/${chat.id}`).push({
                message: offerData.description,
                type: 'offer',
                caseId: documentRef.id,
                sender: uid,
                timestamp: moment().unix(),
              })
              db.ref(`chats/${chat.id}`)
                .child('lastMessage')
                .set(offerData.description)
              db.ref(`chats/${chat.id}`).child('timestamp').set(moment().unix())
              return history.push(`/panel/messages/${chat.id}`)
            })
          })
      })
  }, [])
}

export default useRegisterOffer
