import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as HeaderIcon } from 'images/icon-experience.svg'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'
import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'
import './Experience.scss'

const Experience = ({ handleEditing }) => {
  const elementRef = useRef(null)

  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.practisingSince)

  const handleEdit = () => {
    if (edit) {
      if (value) {
        doUpdateUserProfile({
          practisingSince: parseInt(value),
        })
      }
      setEdit(false)
      handleEditing(null)
    } else {
      setEdit(true)
      handleEditing('experience')
    }
  }

  useEffect(() => {
    if (edit) {
      elementRef.current.focus()
    }
  }, [edit])

  return (
    <div className={`m-experience ${edit ? '-editing' : ''}`}>
      <div className='m-experience__header'>
        <HeaderIcon />
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <CheckIcon /> : <EditIcon />}
        </button>
      </div>
      <div className='m-experience__main'>
        {edit ? (
          <div className='m-experience__main--form'>
            <input
              ref={elementRef}
              value={value}
              type='number'
              onChange={(e) => setValue(e.target.value)}
            />
            <span>Years Experience</span>
          </div>
        ) : (
          <span>
            <b>{value}</b>
            Years <br />
            Experience
          </span>
        )}
      </div>
    </div>
  )
}

export default Experience
