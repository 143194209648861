import React, { useState, useEffect } from 'react'
import Avatar from 'react-avatar'
import useUserInfo from 'hooks/useUserInfo'
import StarRatings from 'react-star-ratings'
import CompleteCases from 'components/molecules/Charts/CompleteCases'

import { ReactComponent as CasesIcon } from 'images/icon-cases.svg'
import { ReactComponent as CloseIcon } from 'images/icon-close-modal.svg'
import { ReactComponent as StarIcon } from 'images/icon-star.svg'
import { ReactComponent as PaymentIcon } from 'images/icon-credit-card.svg'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as DashboardIcon } from 'images/dashboard.svg'
import Payments from 'components/molecules/Charts/Payments'
import ReviewItem from 'components/molecules/ReviewItem'

import './LawyerDashboard.scss'
import Address from 'components/molecules/PanelFormItems/Address'
import Contacts from 'components/molecules/PanelFormItems/Contacts'
import LinkedIn from 'components/molecules/PanelFormItems/Linkedin'
import useGetCases from 'services/firebase/hooks/cases/useGetCases'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Loader from 'components/atoms/Loader'
import useGetLawyerReviews from 'services/firebase/hooks/lawyers/useGetLawyerReviews'
import useGetUserByUid from 'services/firebase/hooks/users/useGetUserByUid'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'

const LawyerDashboard = () => {
  const doUpdateUserProfile = useUpdateUserProfile()
  const [userInfo] = useUserInfo()
  const doGetCases = useGetCases()
  const doGetUserByUid = useGetUserByUid()
  const doGetLawyerReviews = useGetLawyerReviews()
  const [activeCases, setActiveCases] = useState([])
  const [completedCases, setCompletedCases] = useState([])
  const [paymentTotal, setPaymentTotal] = useState(0)
  const [paymentReceived, setPaymentReceived] = useState(0)
  const [paymentPending, setPaymentPending] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [reviews, setReviews] = useState([])
  const [showMoreComments, setShowMoreComments] = useState(false)
  const [hideWelcome, setHideWelcome] = useState(false)
  const [hideComplete, setHideComplete] = useState(false)

  useEffect(() => {
    doGetLawyerReviews(userInfo.uid).then(async (items) => {
      const clientsReviews = await items.map((item) => {
        return doGetUserByUid(item.uid).then((client) => {
          return {
            ...item,
            author: client[0].displayName || '--',
            image: client[0].photoURL || null,
          }
        })
      })

      Promise.all(clientsReviews).then((allReviews) => {
        setReviews(allReviews)
      })
    })
    doGetCases().then(async (result) => {
      if (result) {
        let tempPaymentTotal = 0
        let tempPaymentReceived = 0
        let tempPaymentPending = 0

        await result.map((item) => {
          if (item.paymentType !== 'noWinNoFee') {
            if (item.status !== 'canceled') {
              tempPaymentTotal =
                tempPaymentTotal + (item.amount - item.amount * 0.12)
            }
            if (item.status === 'complete') {
              tempPaymentReceived =
                tempPaymentReceived + (item.amount - item.amount * 0.12)
            }
            if (
              item.status === 'active' ||
              item.status === 'complete_by_lawyer'
            ) {
              tempPaymentPending =
                tempPaymentPending + (item.amount - item.amount * 0.12)
            }
          }
        })

        setPaymentTotal(tempPaymentTotal)
        setPaymentPending(tempPaymentPending)
        setPaymentReceived(tempPaymentReceived)

        const currentActiveCases = await result.filter(
          (item) => item.status === 'active'
        )
        const currentCompletedCases = await result.filter(
          (item) => item.status === 'complete'
        )
        setActiveCases(currentActiveCases)
        setCompletedCases(currentCompletedCases)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    })
  }, [])

  const handleHideWelcomeMessage = () => {
    doUpdateUserProfile({ hideWelcome: true }, false)
    setHideWelcome(true)
  }

  const handleHideCompleteMessage = () => {
    doUpdateUserProfile({ hideComplete: true }, false)
    setHideComplete(true)
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div className='o-lawyerDashboard'>
      <div className='o-lawyerDashboard__container'>
        <div className='o-lawyerDashboard__content'>
          {userInfo.validatedAccount &&
            !userInfo.hideComplete &&
            !hideComplete && (
              <div className='o-lawyerDashboard__content--box -welcome'>
                <header>
                  <span>
                    Your profile is now <b>complete!</b>
                  </span>
                  <button onClick={handleHideCompleteMessage}>
                    <CloseIcon />
                  </button>
                </header>
                <main className='-complete'>
                  <div className='c-flex'>
                    <div>
                      <p>
                        Thank you for verifying your credentials, your profile
                        is now online! People can search and contact you.
                        You&apos;ll receive a message when a client has
                        contacted you.
                      </p>
                      <p>
                        If you want to stand out from the crowd, add a short Bio
                        and invite your colleagues, this will appear on your
                        profile and increase consumer confidence.
                      </p>
                    </div>
                  </div>
                </main>
              </div>
            )}
        </div>
      </div>

      <div className='o-lawyerDashboard__container'>
        <div className='o-lawyerDashboard__row'>
          <div className='o-lawyerDashboard__aside'>
            <div className='o-lawyerDashboard__aside--avatar'>
              <Link to={`/profile/${userInfo.uid}`}>
                <Avatar
                  size='170'
                  maxInitials={2}
                  round='100%'
                  color='#18a1db'
                  textSizeRatio={2.5}
                  src={userInfo.photoURL}
                  name={`${userInfo.name} ${userInfo.surname}`}
                />
              </Link>
            </div>
            {userInfo.status === 'online' && (
              <div className='o-lawyerDashboard__aside--status'>
                You&apos;re Online
              </div>
            )}

            <div className='o-lawyerDashboard__aside--info'>
              <h2>{`${userInfo.name} ${userInfo.surname}`}</h2>
              <div className='o-lawyerDashboard__aside--info-rating'>
                <StarRatings
                  svgIconPath='M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
              c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
              L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438
              c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701
              c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0
              l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904
              l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z'
                  starDimension={'18px'}
                  starRatedColor='#ffc107'
                  rating={userInfo.rating}
                  numberOfStars={5}
                  starSpacing={'3px'}
                />
                <span>{userInfo.rating.toFixed(1)}</span>
              </div>
            </div>
            <div className='o-lawyerDashboard__aside--form'>
              <Address />
              <Contacts />
              <LinkedIn />
            </div>
          </div>
          <div className='o-lawyerDashboard__content'>
            <div className='o-lawyerDashboard__content--filter'>
              <button className='-active'>All time</button>
            </div>
            <div className='o-lawyerDashboard__content--box'>
              <header>
                <span>{activeCases.length} Open Cases</span>
                <CasesIcon />
              </header>
              <main>
                <table>
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Deadline</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeCases.map((item) => (
                      <tr key={item.caseId}>
                        <td>{item.receiverName}</td>
                        <td>
                          {moment(item.deadline.toDate()).format('MM/DD/YYYY')}
                        </td>
                        <td>
                          <Link to={`/panel/cases/${item.caseId}`}>
                            <CheckIcon />
                            <span>Mark as complete</span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </main>
            </div>

            <div className='o-lawyerDashboard__content--box'>
              <header>
                <span>{completedCases.length} Completed Cases</span>
                <CasesIcon />
              </header>
              <main className='-withSpace'>
                <CompleteCases cases={completedCases} />
              </main>
            </div>
            <div className='o-lawyerDashboard__content--box'>
              <header>
                <span>Payments</span>
                <PaymentIcon />
              </header>
              <main className='-payments'>
                <div className='o-lawyerDashboard__content--box-item -full'>
                  <Payments
                    total={paymentTotal}
                    complete={paymentTotal}
                    label='Total'
                  />
                </div>
                <div className='o-lawyerDashboard__content--box-item'>
                  <Payments
                    total={paymentTotal}
                    complete={paymentReceived}
                    label='Received'
                  />
                </div>
                <div className='o-lawyerDashboard__content--box-item'>
                  <Payments
                    total={paymentTotal}
                    complete={paymentPending}
                    label='Pending'
                  />
                </div>
              </main>
            </div>

            {reviews.length > 0 && (
              <div className='o-lawyerDashboard__content--box'>
                <header>
                  <span>{reviews.length} Reviews</span>
                  <StarIcon />
                </header>
                <main
                  className={`-reviews ${
                    showMoreComments || reviews.length < 4 ? '-showAll' : ''
                  }`}
                >
                  <div className='-items'>
                    {reviews.map((item, key) => (
                      <ReviewItem
                        key={`dashboard-review-=${key}`}
                        rating={item.rating}
                        date={item.date.toDate()}
                        user={{
                          displayName: item.author,
                          photoURL: item.photoURL,
                        }}
                        review={item.comment}
                      />
                    ))}
                  </div>
                  {reviews.length > 4 && (
                    <button
                      onClick={() => setShowMoreComments(!showMoreComments)}
                    >
                      <span>{showMoreComments ? 'Show less' : 'See all'}</span>
                    </button>
                  )}
                </main>
              </div>
            )}

            {!userInfo.hideWelcome && !hideWelcome && (
              <div className='o-lawyerDashboard__content--box'>
                <header>
                  <span>Welcome to your dashboard</span>
                </header>
                <main className='-welcome'>
                  <DashboardIcon />
                  <p>
                    All your payment information can be seen here. This
                    information is private for you. To see your public profile
                    click on your picture on the top right.
                  </p>
                  <button onClick={handleHideWelcomeMessage}>Close</button>
                </main>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LawyerDashboard
