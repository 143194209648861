import React, { useState, useEffect, useCallback } from 'react'
import Title from '../../atoms/Title'
import Section from '../../atoms/Section'
import { ReactComponent as IconVideo } from 'images/icon-watch-video.svg'
import { ReactComponent as IconCloseModal } from 'images/icon-close-modal.svg'

import './HowItWorks.scss'

const HowItWorks = () => {
  const [showModal, setShowModal] = useState(false)
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShowModal(false)
    }
  }, [])

  useEffect(() => {
    const videoRef = document.getElementById('how-it-works-video')
    if (videoRef) {
      videoRef.play()
    }

    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  return (
    <Section className='o-howItWorks'>
      <div className='o-howItWorks__header'>
        <Title>How It Works</Title>
      </div>
      <div className='o-howItWorks__content'>
        <div className='o-howItWorks__content--video'>
          <strong>Discuss</strong>
          <img src={require('images/how-it-works.gif')} alt='' />
        </div>
        <div className='o-howItWorks__content--text'>
          <strong>Discuss</strong>
          <p>
            Call, Message or Video chat with lawyers about your case and how
            much it will cost to complete
          </p>
          <b>Payment options:</b>
          <ul>
            <li>Fixed Fee</li>
            <li>No Win No Fee</li>
            <li>Hourly Rate</li>
            <li>Legal Aid</li>
          </ul>
        </div>
      </div>
      <div className='o-howItWorks__content -deposit'>
        <div className='o-howItWorks__content--text'>
          <strong>Deposit</strong>
          <p>
            Deposit your legal fees into Lawyerup&apos;s wallet until your case
            is complete
          </p>
        </div>
        <div className='o-howItWorks__content--image'>
          <strong>Deposit</strong>
          <img src={require('images/deposit.gif')} alt='' />
        </div>
      </div>
      <div className='o-howItWorks__content -receive'>
        <div className='o-howItWorks__content--text'>
          <strong>Receive</strong>
          <p>Receive your work or action</p>
        </div>
        <div className='o-howItWorks__content--image'>
          <strong>Receive</strong>
          <img src={require('images/receive.gif')} alt='' />
        </div>
      </div>
      <div className='o-howItWorks__content -release'>
        <div className='o-howItWorks__content--text'>
          <strong>Release</strong>
          <p>
            When your satisfied your case has been completed, release fees to
            your lawyer less Lawyerup&apos;s fee
          </p>
          <button onClick={() => setShowModal(true)}>
            <IconVideo />
            <span>Watch video</span>
          </button>
        </div>
        <div className='o-howItWorks__content--image'>
          <strong>Release</strong>
          <img src={require('images/release.gif')} alt='' />
        </div>
      </div>
      {showModal && (
        <div className='o-howItWorks__modal'>
          <button
            className='o-howItWorks__modal--close'
            onClick={() => setShowModal(false)}
          >
            <IconCloseModal />
          </button>
          <div className='o-howItWorks__modal--content'>
            <video width='100%' autoPlay controls>
              <source
                src={require('images/how-lawyerup-works.mp4')}
                type='video/mp4'
              />
            </video>
          </div>
        </div>
      )}
    </Section>
  )
}

export default HowItWorks
