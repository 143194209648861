import React, { useState } from 'react'
import Avatar from 'react-avatar'
import useUserInfo from 'hooks/useUserInfo'
import { auth } from 'services/firebase'
import moment from 'moment'
import Contact from 'components/molecules/ClientProfileItems/Contact'
import './ClientProfile.scss'
import Notifications from 'components/molecules/ClientProfileItems/Notifications'
import SocialLinks from 'components/molecules/ClientProfileItems/SocialLinks'
import Account from 'components/molecules/ClientProfileItems/Account'
import RemoveAccount from '../../molecules/ClientProfileItems/RemoveAccount'

const ClientProfile = () => {
  const [userInfo] = useUserInfo()
  const userCreatedAt = auth.currentUser.metadata.creationTime

  return (
    <div className='o-clientProfile'>
      <div className='o-clientProfile__container'>
        <div className='o-clientProfile__aside'>
          <div className='o-clientProfile__aside--box'>
            <div className='o-clientProfile__aside--avatar'>
              <Avatar
                size='170'
                maxInitials={2}
                round='100%'
                color='#18a1db'
                textSizeRatio={2.5}
                src={userInfo.photoURL}
                name={`${userInfo.displayName}`}
              />
            </div>
            <div className='o-clientProfile__aside--info'>
              <h2>{`${userInfo.displayName}`}</h2>
              <span>Member since {moment(userCreatedAt).format('YYYY')}</span>
            </div>
          </div>
        </div>
        <div className='o-clientProfile__content'>
          <div className='o-clientProfile__content--filter'>
            <strong>Profile</strong>
          </div>
          <div className='o-lawyerDashboard__content--box'>
            <Contact />
          </div>
          <div className='o-lawyerDashboard__content--box'>
            <Notifications />
          </div>
          <div className='o-lawyerDashboard__content--box'>
            <SocialLinks />
          </div>
          <div className='o-lawyerDashboard__content--box'>
            <Account />
          </div>
          <div className='o-lawyerDashboard__content--box -full'>
            <RemoveAccount />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientProfile
