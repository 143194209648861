import React from 'react'

export function SuccessIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
    >
      <path
        fill='#00994A'
        d='M14 4.667c5.155 0 9.333 4.178 9.333 9.333S19.155 23.333 14 23.333 4.667 19.155 4.667 14 8.845 4.667 14 4.667zm3.496 5.833c-.31 0-.608.123-.827.343l-3.834 3.843-1.504-1.514c-.22-.22-.517-.342-.827-.342-.31 0-.608.123-.828.342-.22.22-.343.517-.343.827 0 .31.124.608.343.827l2.331 2.33c.108.109.237.195.38.254.141.06.294.09.448.09.153 0 .306-.03.448-.09.142-.059.27-.145.379-.255l4.662-4.659c.22-.219.343-.516.343-.826 0-.31-.124-.608-.343-.827-.22-.22-.517-.343-.828-.343z'
        transform='translate(-588 -698) translate(588 698)'
      />
    </svg>
  )
}
