import React from 'react'

export default function ErrorIcon() {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-299.000000, -723.000000)' fill='#D61F1F'>
          <g transform='translate(276.000000, 704.000000)'>
            <g transform='translate(23.000000, 19.000000)'>
              <path
                d='M8.03935839,0.763849674 L13.2361503,5.96064161 C13.8101721,6.5346634 13.8101721,7.4653366 13.2361503,8.03935839 L8.03935839,13.2361503 C7.4653366,13.8101721 6.5346634,13.8101721 5.96064161,13.2361503 L0.763849674,8.03935839 C0.189827887,7.4653366 0.189827887,6.5346634 0.763849674,5.96064161 L5.96064161,0.763849674 C6.5346634,0.189827887 7.4653366,0.189827887 8.03935839,0.763849674 Z M7,8.66666667 C6.53976271,8.66666667 6.16666667,9.03976271 6.16666667,9.5 C6.16666667,9.96023729 6.53976271,10.3333333 7,10.3333333 C7.46023729,10.3333333 7.83333333,9.96023729 7.83333333,9.5 C7.83333333,9.03976271 7.46023729,8.66666667 7,8.66666667 Z M7.00842529,4.5 L6.99145559,4.5 C6.48802096,4.5 6.09771771,4.99363437 6.17690967,5.53663218 L6.53327351,7.40140326 C6.56721292,7.64822044 6.76519283,7.83333333 6.99711216,7.83333333 L7.00276873,7.83333333 C7.23468805,7.83333333 7.43266796,7.64822044 7.46660737,7.40140326 L7.82297121,5.53663218 C7.90216317,4.99363437 7.51751649,4.50617043 7.00842529,4.5 Z'
                id='Error'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
