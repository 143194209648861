import { useCallback } from 'react'
import { firestore } from 'services/firebase'

const useGetLawyerTeam = () => {
  return useCallback(async (uid) => {
    return await firestore
      .collection('users')
      .doc(uid)
      .collection('team')
      .get()
      .then((snap) => {
        if (!snap.empty) {
          return snap.docs.map((item) => {
            return item.data()
          })
        } else {
          return null
        }
      })
  }, [])
}

export default useGetLawyerTeam
