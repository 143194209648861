import React, { useEffect, useState } from 'react'
import {
  FormControl,
  InputAdornment,
  TextField,
  FormControlLabel,
  Checkbox,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core'
import { ReactComponent as User } from 'images/icon-lawyer-user.svg'
import { ReactComponent as Phone } from 'images/icon-lawyer-phone.svg'
import { ReactComponent as SRAIcon } from 'images/icon-lawyer-sra.svg'
import EmailIcon from 'images/icon-lawyer-envelope.png'
import { ReactComponent as PasswordIcon } from 'images/icon-lawyer-lock.svg'
import useLawyerRegistration from 'hooks/useLawyerRegistration'

const theme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fill: '#fff',
      },
    },
  },
})

const InitialData = ({ classes, errors }) => {
  const [formValues, setFormValues] = useLawyerRegistration()
  const [stepErros, setStepErros] = useState([])

  useEffect(() => {
    setStepErros(errors)
  }, [errors])

  const handleFormValues = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
    if (stepErros.includes(e.target.name)) {
      const tempErr = stepErros
      const errIndex = stepErros.indexOf(e.target.name)
      tempErr.splice(errIndex, 1)
      setStepErros(tempErr)
    }
  }

  const handleAddLater = (e) => {
    setFormValues({ ...formValues, addSRALater: e.target.checked })
  }

  return (
    <div>
      <div className='-half'>
        <FormControl
          component='div'
          fullWidth
          variant='filled'
          className={classes.formControl}
          required
        >
          <TextField
            required
            error={stepErros.includes('name')}
            label='First name'
            variant='filled'
            name='name'
            value={formValues.name}
            onChange={handleFormValues}
            data-validators='isRequired,isAlpha'
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <User />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl
          component='div'
          fullWidth
          variant='filled'
          className={classes.formControl}
        >
          <TextField
            required
            error={stepErros.includes('surname')}
            label='Surname'
            variant='filled'
            name='surname'
            value={formValues.surname}
            onChange={handleFormValues}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <User />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
      <div className='-half'>
        <FormControl
          component='div'
          fullWidth
          variant='filled'
          className={classes.formControl}
        >
          <TextField
            required
            error={stepErros.includes('phone')}
            label='Mobile number'
            variant='filled'
            name='phone'
            value={formValues.phone}
            onChange={handleFormValues}
            helperText={'This is kept conﬁdential'}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Phone />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl
          component='div'
          fullWidth
          variant='filled'
          className={classes.formControl}
        >
          <TextField
            required
            error={stepErros.includes('email')}
            label='Email'
            variant='filled'
            name='email'
            value={formValues.email}
            onChange={handleFormValues}
            helperText={'This is kept conﬁdential'}
            type='email'
            autoComplete={'off'}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <img src={EmailIcon} alt='' />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
      <FormControl
        component='div'
        fullWidth
        variant='filled'
        className={classes.formControl}
      >
        <TextField
          required
          error={stepErros.includes('password')}
          label='Password'
          variant='filled'
          name='password'
          value={formValues.password}
          onChange={handleFormValues}
          type='password'
          autoComplete={'off'}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                <PasswordIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <FormControl
        disabled={formValues.addSRALater}
        component='div'
        fullWidth
        variant='filled'
        className={classes.lastFormControl}
      >
        <TextField
          required
          disabled={formValues.addSRALater}
          error={stepErros.includes('srcOisc')}
          label='SRA or OISC number'
          variant='filled'
          name='srcOisc'
          value={formValues.srcOisc}
          onChange={handleFormValues}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                <SRAIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <MuiThemeProvider theme={theme}>
        <FormControl fullWidth component='div'>
          <FormControlLabel
            onChange={handleAddLater}
            className={classes.formControlLabel}
            classes={{ label: classes.checkboxLabel }}
            checked={formValues.addSRALater}
            control={<Checkbox value='true' color='primary' />}
            label='Add later'
          />
        </FormControl>
      </MuiThemeProvider>
    </div>
  )
}

export default InitialData
