import React, { createContext, useContext, useState, useEffect } from 'react'
import { auth } from '../services/firebase'
import useGetUserNotifications from '../services/firebase/hooks/notifications/useGetUserNotifications'
import useGetUpdateAllNotifications from '../services/firebase/hooks/notifications/useGetUpdateAllNotifications'
import useGetUpdateNotification from '../services/firebase/hooks/notifications/useGetUpdateNotification'

export const NotificationsContext = createContext()
export const useNotifications = () => useContext(NotificationsContext)

export const NotificationsProvider = (props) => {
  const currentUser = auth.currentUser
  const doGetUserNotifications = useGetUserNotifications()
  const doGetUpdateAllNotifications = useGetUpdateAllNotifications()
  const doGetUpdateNotification = useGetUpdateNotification()
  const [hasNewNotifications, setHasNewNotifications] = useState(false)
  const [unreadNotifications, setUnreadNotifications] = useState(0)
  const [notifications, setNotifications] = useState([])

  const updateNotificationsStates = () => {
    setNotifications([])
    doGetUserNotifications().then(async (result) => {
      if (result) {
        const unreadNotifications = result.filter((item) => item.read === false)
        setUnreadNotifications(unreadNotifications.length)
        setHasNewNotifications(unreadNotifications.length)
        setNotifications(result)
      }
    })
  }

  useEffect(() => {
    if (currentUser) {
      updateNotificationsStates()
    }
  }, [])

  const markAllAsRead = async () => {
    await doGetUpdateAllNotifications()
    updateNotificationsStates()
  }

  const markNotificationAsRead = async (notificationId) => {
    await doGetUpdateNotification(notificationId)
    updateNotificationsStates()
  }

  return (
    <NotificationsContext.Provider
      value={{
        hasNewNotifications,
        setHasNewNotifications,
        notifications,
        setNotifications,
        unreadNotifications,
        markAllAsRead,
        markNotificationAsRead,
        updateNotificationsStates,
      }}
      {...props}
    />
  )
}
