import React, { useState } from 'react'
import './CaseAction.scss'

const CaseAction = ({
  label,
  icon,
  type,
  children,
  withoutAction,
  startOpen = false,
}) => {
  const [showContent, setShowContent] = useState(startOpen)
  return (
    <div className={`a-caseAction ${type} ${showContent ? '-open' : ''}`}>
      {!withoutAction ? (
        <button
          onClick={() => setShowContent(!showContent)}
          className={`a-caseAction__button ${showContent ? '-open' : ''}`}
        >
          <span>{label}</span>
          {icon}
        </button>
      ) : (
        <div className={`a-caseAction__button -withoutAction`}>
          <span>{label}</span>
          {icon}
        </div>
      )}
      {showContent && <div className='a-caseAction__content'>{children}</div>}
    </div>
  )
}

export default CaseAction
