import React, { useEffect, useState } from 'react'
import useGetLawyerTeam from 'services/firebase/hooks/lawyers/useGetLawyerTeam'
import './Team.scss'
import useUserInfo from 'hooks/useUserInfo'
import MemberItem from './memberItem'

const Team = () => {
  const [userInfo] = useUserInfo()
  const doGetLawyerTeam = useGetLawyerTeam()
  const [showAll, setShowAll] = useState(false)
  const [team, setTeam] = useState([])
  useEffect(() => {
    if (userInfo) {
      doGetLawyerTeam(userInfo.uid).then((result) => {
        setTeam(result)
      })
    }
  }, [userInfo])
  return (
    <div className='m-profileTeam'>
      <header>
        <span>My Team</span>
        <button onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Hide' : 'View all'}
        </button>
      </header>
      <div className={`m-profileTeam__main ${showAll ? '-showAll' : ''}`}>
        {team && team.length > 0 ? (
          team.map((item, key) => <MemberItem key={key} data={item} />)
        ) : (
          <div className='m-profileTeam__main--empty'>
            <p>We didn&apos;t find any lawyers on your team</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Team
