import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
} from 'react'
import { withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import ChatMessage from 'components/molecules/ChatMessage'
import ChatForm from 'components/organisms/ChatForm'
import { ReactComponent as VideoIcon } from 'images/icon-video.svg'
import { ReactComponent as BackIcon } from 'images/icon-back.svg'
import { ReactComponent as IconClose } from 'images/icon-close-modal.svg'
import Avatar from 'react-avatar'
import { auth, db } from 'services/firebase'
import moment from 'moment'
import Loader from 'components/atoms/Loader'
import './ChatConversation.scss'
import VideoCall from 'components/organisms/VideoCall'
import useUserInfo from 'hooks/useUserInfo'
import ChatReport from 'components/organisms/ChatReport'

const ChatConversation = ({
  chatId,
  handleCloseChat,
  contactUid,
  handleMessagesRef,
}) => {
  const { uid } = auth.currentUser
  const scrollRef = useRef()
  const [messages, setMessages] = useState(null)
  const [selectedChat, setSelectedChat] = useState(false)
  const [openVideoCall, setOpenVideoCall] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [avatar, setAvatar] = useState(null)
  const [name, setName] = useState(null)
  const [status, setStatus] = useState('')
  const [messagesRef, setMessagesRef] = useState(null)
  const [reportMessage, setReportMessage] = useState(false)
  const [hideChatNotice, setHideChatNotice] = useState(
    localStorage.getItem('hideChatNotice')
  )

  const renderMessages = async () => {
    const ref = db.ref(`/chatMessages/${chatId}`).orderByChild('timestamp')
    setMessagesRef(ref)
    await db.ref(`chats/${chatId}/notifications/${uid}`).set(false)
    return ref.on('value', function (snap) {
      const messages = snap.val()
      setMessages(messages)
    })
  }

  useEffect(() => {
    renderMessages()
  }, [chatId])

  useEffect(() => {
    handleMessagesRef(messagesRef)
  }, [messagesRef])

  useEffect(() => {
    /**
     * Change user active chat
     **/
    db.ref(`/users/${uid}`).child('activeChat').set(chatId)
    return () => {
      db.ref(`/users/${uid}`).child('activeChat').set('null')
    }
  })

  // const handleChatMessages = async (id) => {
  //   return db
  //     .ref(`/chatMessages/${id}`)
  //     .orderByChild('timestamp')
  //     .on('value', function (snap) {
  //       setMessages(snap.val())
  //       setIsLoading(false)
  //     })
  // }

  const handleGetContact = useCallback(async (contactUid) => {
    return db.ref(`users/${contactUid}`).on('value', function (snap) {
      const userSnap = snap.val()
      setName(userSnap.displayName || `${userSnap.name} ${userSnap.surname}`)
      setStatus(userSnap.status)
      setAvatar(`${userSnap.photoURL}?size=small`)
    })
  }, [])

  useEffect(() => {
    if (chatId) {
      setSelectedChat(chatId)
      // handleChatMessages(chatId)
      handleGetContact(contactUid)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [chatId]) // eslint-disable-line

  const handleSendMessage = (message) => {
    db.ref(`chatMessages/${chatId}`)
      .push({
        message,
        sender: uid,
        timestamp: moment().unix(),
      })
      .then(() => {
        db.ref(`users/${contactUid}/activeChat`).once('value', function (snap) {
          const activeChat = snap.val()
          if (activeChat !== chatId) {
            db.ref(`/chats/${chatId}/newMessages`).child(contactUid).set(true)
            db.ref(`/users/${contactUid}/unreadChats`).child(chatId).set(true)
          }
        })
      })
    db.ref(`chats/${chatId}`).child('lastMessage').set(message)
    db.ref(`chats/${chatId}`).child('timestamp').set(moment().unix())
  }

  useEffect(() => {
    setTimeout(() => {
      if (typeof scrollRef.current !== 'undefined') {
        scrollRef.current.scrollToBottom()
      }
    }, 100)
  }, [messages])

  const handleScrollCasesMessages = () => {
    if (typeof scrollRef.current !== 'undefined') {
      scrollRef.current.scrollToBottom()
    }
  }

  const [user] = useUserInfo()

  const handleHideChatNotice = (param) => {
    setHideChatNotice(param)
    localStorage.setItem('hideChatNotice', param)
  }

  const adjustableHeight = hideChatNotice ? 272 : 323

  return (
    <div className='o-chatConversation'>
      <ChatReport
        reportMessage={reportMessage}
        handleShowModal={setReportMessage}
      />
      {selectedChat && !isLoading ? (
        <Fragment>
          <div className='o-chatConversation__header'>
            <button
              onClick={() => handleCloseChat(false)}
              className='o-chatConversation__header--close'
            >
              <BackIcon />
            </button>
            <div className='o-chatConversation__header--avatar'>
              <Avatar
                size='50'
                maxInitials={2}
                round='100%'
                color='#18a1db'
                textSizeRatio={2.5}
                src={avatar}
                name={name}
              />
            </div>
            <div className='o-chatConversation__header--info'>
              <strong>{name}</strong>
              {status !== 'offline' && <p>{status}</p>}
            </div>
            <div className='o-chatConversation__header--actions'>
              <button onClick={() => setOpenVideoCall(true)} type='button'>
                <VideoIcon />
              </button>
            </div>
          </div>
          {!hideChatNotice && (
            <div className='o-chatConversation__notice'>
              {user.role === 'lawyer' ? (
                <>
                  <div>
                    <span>
                      Agree a delivery time and price for the legal services
                      your client requires, then send an offer from the{' '}
                      <b>Cases</b> section.
                    </span>
                    <span>
                      Never ask clients for payments directly to you or your law
                      firm.
                    </span>
                  </div>
                  <IconClose
                    onClick={() => {
                      handleHideChatNotice(true)
                    }}
                  />
                </>
              ) : (
                <>
                  <div>
                    <span>
                      Discuss your case with unlimited lawyers for free. Once
                      you&apos;ve agreed your work and delivery date, accept
                      their offer and pay upfront. We hold these funds until you
                      agree the case is complete, then payment is released to
                      your lawyer.
                    </span>
                  </div>
                  <IconClose
                    onClick={() => {
                      handleHideChatNotice(true)
                    }}
                  />
                </>
              )}
            </div>
          )}
          <div className='o-chatConversation__messages'>
            <Scrollbars
              ref={scrollRef}
              autoHide
              autoHeight
              autoHeightMin={`calc(100vh - ${adjustableHeight}px)`}
            >
              {messages &&
                Object.keys(messages).map((messageId) => {
                  return (
                    <ChatMessage
                      chatId={chatId}
                      messageId={messageId}
                      {...messages[messageId]}
                      key={`message-${messageId}`}
                      handleScroll={handleScrollCasesMessages}
                      handleOpenVideo={setOpenVideoCall}
                      handleShowReportModal={setReportMessage}
                    />
                  )
                })}
            </Scrollbars>
          </div>
          <div className='o-chatConversation__form'>
            <ChatForm chatId={chatId} handleSendMessage={handleSendMessage} />
          </div>
        </Fragment>
      ) : (
        <Loader />
      )}
      {openVideoCall && (
        <VideoCall
          handleOpenVideoCall={setOpenVideoCall}
          chatId={chatId}
          name={name}
          avatar={avatar}
          contactUid={contactUid}
        />
      )}
    </div>
  )
}

export default withRouter(ChatConversation)
