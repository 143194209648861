import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'
import useUserInfo from 'hooks/useUserInfo'

import './AboutMe.scss'
import Experience from './Experience'
import Languages from './Languages'
import TeamMembers from './TeamMembers'

const AboutMe = () => {
  const elementRef = useRef(null)
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.about)
  const [activeFooterItem, setActiveFooterItem] = useState(null)

  const handleEdit = () => {
    if (edit) {
      if (value) {
        doUpdateUserProfile({
          about: value,
        })
      }
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  useEffect(() => {
    if (edit) {
      elementRef.current.focus()
    }
  }, [edit])

  return (
    <div className='m-aboutMe'>
      <header>
        <span>About Me</span>
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <CheckIcon /> : <EditIcon />}
        </button>
      </header>
      <main>
        {edit ? (
          <textarea
            ref={elementRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            {value}
          </textarea>
        ) : (
          <p className={value ? '' : '-empty'}>
            {value ? value : 'Tell us a little about yourself'}
          </p>
        )}
      </main>
      <footer>
        {(activeFooterItem === null || activeFooterItem === 'experience') && (
          <Experience handleEditing={setActiveFooterItem} />
        )}
        {(activeFooterItem === null || activeFooterItem === 'languages') && (
          <Languages handleEditing={setActiveFooterItem} />
        )}
        {(activeFooterItem === null || activeFooterItem === 'members') && (
          <TeamMembers handleEditing={setActiveFooterItem} />
        )}
      </footer>
    </div>
  )
}

export default AboutMe
