import React from 'react'
import './AvailabilityTable.scss'
const initialSchedule = {
  monday: 'Available',
  tuesday: 'Available',
  wednesday: 'Available',
  thursday: 'Available',
  friday: 'Available',
  saturday: 'Available',
  sunday: 'Available',
}
const AvailabilityTable = ({ data }) => {
  const availability = { ...initialSchedule, ...data }
  return (
    <div className='m-availabilityTable'>
      <table>
        <thead>
          <tr>
            <th>Day</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Monday</td>
            <td>{availability.monday}</td>
          </tr>
          <tr>
            <td>Tuesday</td>
            <td>{availability.tuesday}</td>
          </tr>
          <tr>
            <td>Wednesday</td>
            <td>{availability.wednesday}</td>
          </tr>
          <tr>
            <td>Thursday</td>
            <td>{availability.thursday}</td>
          </tr>
          <tr>
            <td>Friday</td>
            <td>{availability.friday}</td>
          </tr>
          <tr>
            <td>Saturday</td>
            <td>{availability.saturday}</td>
          </tr>
          <tr>
            <td>Sunday</td>
            <td>{availability.sunday}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default AvailabilityTable
