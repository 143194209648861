import { useCallback } from 'react'
import { auth, firestore } from 'services/firebase'
import moment from 'moment'

const useRegisterUserNotification = () => {
  return useCallback(async (uid, notification) => {
    return firestore
      .collection('users')
      .doc(uid)
      .collection('notifications')
      .doc()
      .set(
        {
          ...notification,
          read: false,
          date: moment().toDate(),
        },
        { merge: true }
      )
  }, []) // eslint-disable-line
}

export default useRegisterUserNotification
