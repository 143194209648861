import React, { useState, useEffect } from 'react'
import CaseCard from 'components/molecules/CaseCard'
import { ReactComponent as SendIcon } from 'images/icon-send.svg'
import { ReactComponent as PlayIcon } from 'images/icon-play-video.svg'
import useGetCases from 'services/firebase/hooks/cases/useGetCases'
import { Link } from 'react-router-dom'
import OfferVideo from 'components/molecules/OfferVideo'
import imageNewOffer from 'images/image-new-offer.png'
import imageVideo from 'images/bg-video-new-offer.png'

import useUserInfo from 'hooks/useUserInfo'
import './Cases.scss'

const Cases = () => {
  const [user] = useUserInfo()
  const doGetCases = useGetCases()
  const [activeCases, setActiveCases] = useState([])
  const [completeCases, setCompleteCases] = useState([])
  const [offerCases, setOfferCases] = useState([])
  const [offerVideo, setShowOfferVideo] = useState(false)

  useEffect(() => {
    doGetCases().then(async (result) => {
      if (result) {
        const activeCases = await result.filter(
          (item) => item.status === 'active'
        )
        const offers = await result.filter((item) => item.status === 'offer')
        const completedCases = await result.filter(
          (item) =>
            item.status === 'complete' || item.status === 'completed_by_lawyer'
        )
        if (activeCases.length > 0) {
          setActiveCases(activeCases)
        }
        if (offers.length > 0) {
          setOfferCases(offers)
        }
        if (completedCases.length > 0) {
          setCompleteCases(completedCases)
        }
      }
    })
  }, [])

  return (
    <div className='p-cases'>
      <div className='p-cases__container'>
        <div className='p-cases__header'>
          <strong>Cases details</strong>
        </div>
        <div className='p-cases__items'>
          {activeCases.length > 0 &&
            activeCases.map((item, key) => (
              <div
                key={`active-card-${key}`}
                className='p-cases__items--item -active'
              >
                {key === 0 && (
                  <header>
                    <strong>Active</strong>
                    <span>({activeCases.length})</span>
                  </header>
                )}
                <CaseCard data={item} type='active' />
              </div>
            ))}

          {offerCases.length > 0 &&
            offerCases.map((item, key) => (
              <div
                key={`offer-card-${key}`}
                className={`p-cases__items--item ${
                  activeCases.length < 2 ? '-active' : ''
                }`}
              >
                {key === 0 && (
                  <header>
                    <strong>Offers</strong>
                    <span>({offerCases.length})</span>
                  </header>
                )}
                <CaseCard withoutLink data={item} type='offer' />
              </div>
            ))}

          {completeCases.length > 0 &&
            completeCases.map((item, key) => (
              <div
                key={`completed-card-${key}`}
                className={`p-cases__items--item ${
                  activeCases.length + offerCases.length < 2 ? '-active' : ''
                }`}
              >
                {key === 0 && (
                  <header>
                    <strong>Completed</strong>
                    <span>({completeCases.length})</span>
                  </header>
                )}
                <CaseCard data={item} type='complete' />
              </div>
            ))}
        </div>
        {user.role === 'lawyer' && (
          <div className='p-cases__newOffer'>
            <div className='p-cases__newOffer--box'>
              <h3>Send a new offer</h3>
              <div className='p-cases__newOffer--box-image'>
                <img src={imageNewOffer} alt='' />
              </div>
              <Link to='/panel/cases/new-offer'>
                <span>Send Offer</span>
                <SendIcon />
              </Link>
            </div>
            <div className='p-cases__newOffer--howSendOffer'>
              <button onClick={() => setShowOfferVideo(true)}>
                <img src={imageVideo} alt='' />
                <div>
                  <PlayIcon />
                  <strong>How to send an offer</strong>
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
      {offerVideo && <OfferVideo handleCloseModal={setShowOfferVideo} />}
    </div>
  )
}

export default Cases
