import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel2'
import 'react-owl-carousel2/src/owl.carousel.css'
import useGetLawyerTeam from 'services/firebase/hooks/lawyers/useGetLawyerTeam'
import MemberItem from './memberItem'
import './Team.scss'

const Team = ({ uid }) => {
  const doGetLawyerTeam = useGetLawyerTeam()
  const [team, setTeam] = useState([])
  useEffect(() => {
    if (uid) {
      doGetLawyerTeam(uid).then((result) => {
        if (result) {
          setTeam(result)
        }
      })
    }
  }, [uid])

  return (
    team.length > 0 && (
      <div className='p-profilePage__team'>
        <h2 className='p-profilePage__team--title'>My Team</h2>
        <div className='m-team'>
          <OwlCarousel
            options={{
              nav: false,
              loop: false,
              autoplay: false,
              dots: true,
              margin: 30,
              responsive: {
                0: {
                  items: 1,
                },
                576: {
                  items: 1,
                  stagePadding: 100,
                },
                768: {
                  items: 1,
                  stagePadding: 200,
                },
                1024: {
                  items: 3,
                },
              },
            }}
          >
            {team.map((item, key) => (
              <div key={key}>
                <MemberItem data={item} />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    )
  )
}

export default Team
