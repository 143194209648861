import React, { useState } from 'react'
import { Checkbox, Button } from '@material-ui/core'
import { ReactComponent as CloseIcon } from 'images/icon-close.svg'

import './ResultsSort.scss'

const ResultsSort = ({ open, handleShowFilters, handleOrder }) => {
  const [order, setOrder] = useState('distance-ASC')

  const handleChangeOrder = (e) => {
    setOrder(e.target.value)
  }

  const handleSubmitOrder = () => {
    handleOrder(order)
    handleShowFilters(false)
  }

  return (
    <div className={`m-resultsSort ${open ? '-show' : ''}`}>
      <div className='m-resultsSort__header'>
        <strong>Sort Options</strong>
        <button onClick={() => handleShowFilters(false)}>
          <CloseIcon />
        </button>
      </div>
      <div className='m-resultsSort__content'>
        <div className='m-resultsSort__content--option'>
          <div className='m-resultsSort__content--label'>Star Rating</div>
          <div className='m-resultsSort__content--field'>
            <label>
              <Checkbox
                color='primary'
                checked={order === 'rating-DESC'}
                onChange={handleChangeOrder}
                value='rating-DESC'
              />
              <span>HIGH to LOW</span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={order === 'rating-ASC'}
                onChange={handleChangeOrder}
                value='rating-ASC'
              />
              <span>LOW to HIGH</span>
            </label>
          </div>
        </div>

        <div className='m-resultsSort__content--option'>
          <div className='m-resultsSort__content--label'>
            Years of Experience
          </div>
          <div className='m-resultsSort__content--field'>
            <label>
              <Checkbox
                color='primary'
                checked={order === 'practisingSince-DESC'}
                onChange={handleChangeOrder}
                value='practisingSince-DESC'
              />
              <span>HIGH to LOW</span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={order === 'practisingSince-ASC'}
                onChange={handleChangeOrder}
                value='practisingSince-ASC'
              />
              <span>LOW to HIGH</span>
            </label>
          </div>
        </div>

        <div className='m-resultsSort__content--option'>
          <div className='m-resultsSort__content--label'>Distance</div>
          <div className='m-resultsSort__content--field'>
            <label>
              <Checkbox
                color='primary'
                checked={order === 'distance-DESC'}
                onChange={handleChangeOrder}
                value='distance-DESC'
              />
              <span>HIGH to LOW</span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={order === 'distance-ASC'}
                onChange={handleChangeOrder}
                value='distance-ASC'
              />
              <span>LOW to HIGH</span>
            </label>
          </div>
        </div>

        <div className='m-resultsSort__content--option'>
          <div className='m-resultsSort__content--label'>Hourly Rate</div>
          <div className='m-resultsSort__content--field'>
            <label>
              <Checkbox
                color='primary'
                checked={order === 'hourPrice-DESC'}
                onChange={handleChangeOrder}
                value='hourPrice-DESC'
              />
              <span>HIGH to LOW</span>
            </label>
            <label>
              <Checkbox
                color='primary'
                checked={order === 'hourPrice-ASC'}
                onChange={handleChangeOrder}
                value='hourPrice-ASC'
              />
              <span>LOW to HIGH</span>
            </label>
          </div>
        </div>

        <div className='m-resultsSort__content--action'>
          <Button
            onClick={handleSubmitOrder}
            fullWidth
            variant='contained'
            color='primary'
          >
            Sort Results
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ResultsSort
