import { useCallback } from 'react'
import { firestore } from 'services/firebase'

const useGetLawyerByEmail = () => {
  return useCallback(async (email) => {
    return firestore
      .collection('users')
      .where('email', '==', email)
      .where('role', '==', 'lawyer')
      .get()
      .then((snap) => {
        if (!snap.empty) {
          return snap.docs.map((item) => {
            return item.data()
          })
        }
        return null
      })
      .catch(() => {
        return null
      })
  }, [])
}

export default useGetLawyerByEmail
