import React, { useState, useEffect, useCallback } from 'react'
import ChatContact from 'components/molecules/ChatContact'
import { Scrollbars } from 'react-custom-scrollbars'
import ChatConversation from 'components/organisms/ChatConversation'
import { useParams, useHistory } from 'react-router-dom'
import { auth, db } from 'services/firebase'
import './MessagesPage.scss'
import useUserInfo from 'hooks/useUserInfo'

const PanelMessagesPage = () => {
  const { uid } = auth.currentUser
  const [user] = useUserInfo()
  const { chatId } = useParams()
  const history = useHistory()

  const [rooms, setRooms] = useState([])
  const [activeChat, setActiveChat] = useState(null)
  const [contactUid, setContactUid] = useState(null)
  const [messagesRef, setMessagesRef] = useState(null)

  const handleChatRooms = useCallback(async () => {
    db.ref('chats')
      .orderByChild(`members/${uid}`)
      .equalTo(true)
      .on('value', function (snap) {
        setRooms(snap.val())
      })
  }, []) // eslint-disable-line

  useEffect(() => {
    if (chatId) {
      setActiveChat(chatId)
    }
    handleChatRooms()
  }, []) // eslint-disable-line

  const handleChangeChat = (chat, contactId) => {
    setActiveChat(chat)
    setContactUid(contactId)
    history.push(`/panel/messages/${chat}`)
    db.ref(`/chats/${chat}/newMessages`).child(uid).set(false)
    db.ref(`/users/${uid}/unreadChats/${chat}`).remove()
    if (messagesRef) messagesRef.off('value')
  }

  return (
    <div className='p-panelMessagesPage'>
      <div className='p-panelMessagesPage__contacts'>
        {rooms ? (
          <Scrollbars autoHide autoHeight autoHeightMin='calc(100vh - 84px)'>
            {Object.keys(rooms).map((roomId) => {
              return (
                <ChatContact
                  roomId={roomId}
                  key={`chat-${roomId}`}
                  {...rooms[roomId]}
                  handleOnClick={handleChangeChat}
                  isActive={activeChat === roomId}
                />
              )
            })}
          </Scrollbars>
        ) : (
          <div className='p-panelMessagesPage__contacts--empty'>
            <span>No contacts found</span>
          </div>
        )}
      </div>
      <div
        className={`p-panelMessagesPage__content ${
          activeChat ? '-active' : ''
        }`}
      >
        {contactUid ? (
          <ChatConversation
            handleMessagesRef={setMessagesRef}
            chatId={activeChat}
            contactUid={contactUid}
            handleCloseChat={handleChangeChat}
          />
        ) : (
          <div className='o-chatConversation'>
            <div className='o-chatConversation__empty'>
              {rooms && rooms.length > 0
                ? 'No conversations selected'
                : `You have no conversations with ${
                    user.role === 'lawyer' ? 'clients' : 'lawyers'
                  } yet. ${
                    user.role === 'lawyer'
                      ? 'As soon as a client contacts you their chat will appear here.'
                      : ''
                  }`}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PanelMessagesPage
