import React from 'react'
import contactIcon from 'images/icon-contact.png'

import './Contact.scss'
import Generic from '../Generic'

const Contact = () => {
  return (
    <div className='m-contact'>
      <header>
        <span>Contact</span>
        <img src={contactIcon} alt='Contact' />
      </header>
      <main>
        <Generic type='displayName' title='Name' />
        <Generic type='phone' title='Phone' />
      </main>
    </div>
  )
}

export default Contact
