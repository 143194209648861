import { useCallback } from 'react'
import { auth } from 'services/firebase'
import useGetLawyerStripeAccountId from '../lawyers/useGetLawyerStripeAccountId'
import axios from 'axios'
import { firebaseBaseURL } from 'utils/constants'

const useGenerateOnboardingLink = () => {
  const doGetLawyerStripeAccountId = useGetLawyerStripeAccountId()

  return useCallback(async () => {
    if (!auth) return false

    const { uid } = auth.currentUser

    const test = await doGetLawyerStripeAccountId(uid)
    const axiosConfig = {
      method: 'post',
      url: `${firebaseBaseURL}stripe/get-onboarding-link`,
      data: { account_id: test.account_id },
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(axiosConfig)
      .then((result) => {
        if (result) {
          return result.data
        }
      })
      .catch(() => {
        throw 'Error'
      })
  }, [])
}

export default useGenerateOnboardingLink
