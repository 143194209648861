import React, { useRef, useState, useEffect } from 'react'
import { Link, withRouter, useHistory } from 'react-router-dom'
import Avatar from 'react-avatar'
import Logo from 'images/logo.png'
import useUserInfo from 'hooks/useUserInfo'
import useLogout from 'services/firebase/hooks/auth/useLogout'
import Edit from '@material-ui/icons/Edit'
import ExitToApp from '@material-ui/icons/ExitToApp'
import { useNotifications } from 'context/notifications'
import { useChat } from 'context/chat'
import { db } from 'services/firebase'

import useGenerateOnboardingLink from 'services/firebase/hooks/lawyers/useGenerateOnboardingLink'
import useRetrieveUserAccount from 'services/firebase/hooks/users/useRetrieveUserAccount'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'
import useGetInvitesToTeam from 'services/firebase/hooks/lawyers/useGetInvitesToTeam'
import useHandleTeamInvite from 'services/firebase/hooks/lawyers/useHandleTeamInvite'
import useGetUserBankDetails from 'services/firebase/hooks/users/useGetUserBankDetails'

import { ReactComponent as IconCheck } from 'images/icon-check-circle.svg'
import './Header.scss'

const PanelHeader = ({ location }) => {
  const doGetUserBankDetails = useGetUserBankDetails()
  const { hasNewNotifications } = useNotifications()
  const doGenerateOnboardingLink = useGenerateOnboardingLink()
  const doHandleTeamInvite = useHandleTeamInvite()
  const doGetInvitesToTeam = useGetInvitesToTeam()
  const doRetrieveUserAccount = useRetrieveUserAccount()
  const doUpdateUserProfile = useUpdateUserProfile()
  const { hasUnreadChat, setHasUnreadChat } = useChat()
  const dropdownRef = useRef()
  const [userInfo] = useUserInfo()
  const doLogout = useLogout()
  const history = useHistory()
  const [showNav, setShowNav] = useState(false)
  const [onboardingLink, setOnboardingLink] = useState(null)
  const [fullName, setFullName] = useState(null)
  const [showDropdown, setShowDropdown] = useState(false)
  const [showAccountSteps, setShowAccountSteps] = useState(false)
  const [hasInitialInformation, setHasInitialInformation] = useState(false)
  const [hasBankAccount, setHasBankAccount] = useState(false)
  const [extraInformation, setExtraInformation] = useState(false)
  const [invites, setInvites] = useState([])
  const handleLogout = () => {
    doLogout().then(() => {
      history.push('/login')
    })
  }

  const getOnboardingLink = async () => {
    if (userInfo) {
      if (!userInfo.validatedAccount && userInfo.role === 'lawyer') {
        const userStripeAccount = await doRetrieveUserAccount()
        if (userStripeAccount) {
          const [bankDetails] = await doGetUserBankDetails(userInfo.uid)

          if (bankDetails) {
            setHasBankAccount(true)
          }

          if (userStripeAccount.charges_enabled) {
            setHasInitialInformation(true)
          }

          if (
            !userStripeAccount.charges_enabled ||
            userStripeAccount.requirements.errors.length > 0
          ) {
            const accountLink = await doGenerateOnboardingLink()
            setOnboardingLink(accountLink.url)
            if (
              userStripeAccount.charges_enabled &&
              userStripeAccount.requirements.errors.length > 0
            ) {
              setExtraInformation(true)
            }
          }
          if (
            userStripeAccount.charges_enabled &&
            userStripeAccount.payouts_enabled
          ) {
            doUpdateUserProfile({ validatedAccount: true }, false)
          } else {
            setShowAccountSteps(true)
          }
        }
      }
    }
  }

  useEffect(() => {
    doGetInvitesToTeam().then((result) => {
      setInvites(result)
    })
    getOnboardingLink()
    if (userInfo) {
      if (userInfo.role === 'lawyer') {
        setFullName(`${userInfo.name} ${userInfo.surname}`)
      } else {
        setFullName(userInfo.displayName)
      }
    }
  }, [userInfo])

  useEffect(() => {
    db.ref(`/users/${userInfo.uid}/unreadChats/`).on('value', function (snap) {
      setHasUnreadChat(snap.numChildren())
    })
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDropdown)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown)
    }
  })

  const handleClickOutsideDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false)
    }
  }

  const handleNavigation = (to) => {
    setShowNav(false)
    history.push(to)
  }

  const handleAddBankDetails = () => {
    history.push('/panel/profile#bank-details')
  }

  const handleAcceptInvite = (invite, accepted) => {
    const tempInvites = [...invites]

    const index = tempInvites.indexOf(invite)
    if (typeof index !== 'undefined') {
      tempInvites.splice(index, 1)
      setInvites(tempInvites)
      doHandleTeamInvite(invite, accepted)
    }
  }

  return (
    <>
      <header className='t-mainHeader -panel'>
        <div className='flex items-center justify-start'>
          <Link to='/' className='mr-auto t-mainHeader__logo'>
            <img src={Logo} alt='Lawyerup' />
          </Link>
          <nav className={`t-mainHeader__nav ${showNav ? '-show' : ''}`}>
            <a
              onClick={() => handleNavigation('/panel/dashboard')}
              className={`t-mainHeader__nav--link ${
                location.pathname === '/panel/dashboard' ? '-current' : ''
              }`}
            >
              Dashboard
            </a>
            <a
              onClick={() => handleNavigation('/panel/cases')}
              className={`t-mainHeader__nav--link ${
                location.pathname.includes('/panel/cases') ? '-current' : ''
              }`}
            >
              Cases
            </a>
            <a
              onClick={() => handleNavigation('/panel/messages')}
              className={`t-mainHeader__nav--link ${
                location.pathname === '/panel/messages' ? '-current' : ''
              }`}
            >
              Messages
              {hasUnreadChat > 0 && (
                <i className='t-mainHeader__nav--link-notification'>
                  {hasUnreadChat}
                </i>
              )}
            </a>
            <a
              onClick={() => handleNavigation('/panel/notifications')}
              className={`t-mainHeader__nav--link ${
                location.pathname === '/panel/notifications' ? '-current' : ''
              }`}
            >
              Notifications
              {hasNewNotifications > 0 && (
                <i className='t-mainHeader__nav--link-notification'>
                  {hasNewNotifications}
                </i>
              )}
            </a>
          </nav>
          <div ref={dropdownRef} className='t-mainHeader__dropdown'>
            <div className='t-mainHeader__dropdown--trigger'>
              <Avatar
                size='35'
                maxInitials={2}
                round='100%'
                color='#18a1db'
                textSizeRatio={2.5}
                src={userInfo && userInfo.photoURL ? userInfo.photoURL : null}
                name={userInfo ? fullName : '--'}
                onClick={() => setShowDropdown(!showDropdown)}
              />
            </div>
            <div
              className={`t-mainHeader__dropdown--content  ${
                showDropdown ? '-open' : '-close'
              }`}
            >
              <div className='t-mainHeader__dropdown--box'>
                <div className='t-mainHeader__dropdown--header'>
                  <div className='t-mainHeader__dropdown--avatar'>
                    <Avatar
                      size='80'
                      maxInitials={2}
                      round='100%'
                      color='#18a1db'
                      textSizeRatio={2.5}
                      src={
                        userInfo && userInfo.photoURL ? userInfo.photoURL : null
                      }
                      name={fullName}
                      onClick={() => setShowDropdown(!showDropdown)}
                    />
                  </div>
                  <span className='t-mainHeader__dropdown--welcome'>
                    {fullName}
                  </span>
                  <Link to='/panel/profile'>
                    <small>Edit profile</small>
                    <Edit />
                  </Link>
                </div>
                <button type='button' onClick={handleLogout}>
                  <span>Logout</span>
                  <ExitToApp />
                </button>
              </div>
            </div>
          </div>
          <button
            onClick={() => setShowNav(!showNav)}
            className={`t-mainHeader__toggleNav ${showNav ? '-close' : ''}`}
          >
            <span />
          </button>
        </div>
      </header>
      {!userInfo.validatedAccount && showAccountSteps && (
        <div className='t-accountActivation'>
          <div className='content'>
            <header>
              <b>Verify your identity and bank details</b>
            </header>
            <ul>
              <li className={hasInitialInformation && '-complete'}>
                <IconCheck />
                <p>Verify my identity</p>
                {!hasInitialInformation ? (
                  <a href={onboardingLink}>Add info</a>
                ) : (
                  <>
                    <span className='-verifying'>Verifying</span>
                    <span>Complete</span>
                  </>
                )}
              </li>
              <li className={hasBankAccount && '-complete'}>
                <IconCheck />
                <p>Bank details</p>
                {!hasBankAccount ? (
                  <button onClick={handleAddBankDetails}>Add info</button>
                ) : (
                  <span>Complete</span>
                )}
              </li>
              <li
                className={`${
                  !extraInformation && !hasInitialInformation && '-disabled'
                } ${!onboardingLink && !extraInformation && '-complete'}`}
              >
                <IconCheck />
                <p>Extra information</p>
                {extraInformation && onboardingLink && (
                  <a href={onboardingLink}>Add info</a>
                )}
                {!extraInformation && !onboardingLink && (
                  <>
                    <span className='-verifying'>Verifying</span>
                    <span>Complete</span>
                  </>
                )}
              </li>
            </ul>
            <footer>
              <small>
                * Stripe is a 3rd party secure payment gateway which allows you
                to accept funds directly to your bank. Your bank details should
                be the account that you want to receive payments for your legal
                services into. Note: This account will never be charged without
                your consent.
              </small>
            </footer>
          </div>
        </div>
      )}
      {invites && (
        <div className='t-teamInvites'>
          {invites.map((invite) => (
            <div key={invite.id} className='t-teamInvites__item'>
              <strong>
                <Link to={`/profile/${invite.firmUid}`}>
                  {invite.firmName.trim()}
                </Link>{' '}
                wants to add you to his firm&apos;s team
              </strong>
              <div className='t-teamInvites__item--actions'>
                <button
                  className='-decline'
                  onClick={() => handleAcceptInvite(invite, false)}
                >
                  Decline
                </button>
                <button onClick={() => handleAcceptInvite(invite, true)}>
                  Accept
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default withRouter(PanelHeader)
