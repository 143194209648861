import React from 'react'
import LawyerProfile from 'components/organisms/LawyerProfile'
import useUserInfo from 'hooks/useUserInfo'
import ClientProfile from 'components/organisms/ClientProfile'

const Profile = () => {
  const [user] = useUserInfo()
  return user.role === 'lawyer' ? <LawyerProfile /> : <ClientProfile />
}

export default Profile
