import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Logo from 'images/login-logo.png'
import LogoGray from 'images/logo.png'
import useUserInfo from 'hooks/useUserInfo'
import './Header.scss'

const WebsiteHeader = ({ location, history }) => {
  const [userInfo] = useUserInfo()
  const [showNav, setShowNav] = useState(false)
  const handleNavigation = (to) => {
    setShowNav(false)
    history.push(to)
  }

  return (
    <header
      className={`t-mainHeader ${
        location.pathname === '/' ? '-absolute' : '-relative'
      } ${
        location.pathname === '/login' ||
        location.pathname === '/forgot-password'
          ? '-login'
          : ''
      }`}
    >
      <div className='container mx-auto'>
        <div className='flex items-center justify-between'>
          <Link to='/' className='t-mainHeader__logo'>
            <img
              src={location.pathname === '/' ? Logo : LogoGray}
              alt='Lawyerup'
            />
          </Link>
          <button
            onClick={() => setShowNav(!showNav)}
            className={`t-mainHeader__toggleNav ml-auto ${
              showNav ? '-close' : ''
            }`}
          >
            <span />
          </button>
          <nav className={`t-mainHeader__nav ${showNav ? '-show' : ''}`}>
            <a
              onClick={() => handleNavigation('/faq')}
              className={`t-mainHeader__nav--link ${
                location.pathname === '/faq' ? '-current' : ''
              }`}
            >
              Faq
            </a>
            <a
              onClick={() => handleNavigation('/be-featured')}
              className={`t-mainHeader__nav--link ${
                location.pathname === '/be-featured' ? '-current' : ''
              }`}
            >
              Be Featured
            </a>
            {userInfo && typeof userInfo.uid !== 'undefined' ? (
              <Link to='/panel/dashboard' className='rounded -join'>
                My Dashboard
              </Link>
            ) : (
              <>
                <a onClick={() => handleNavigation('/login')}>Login</a>
                <a
                  onClick={() => handleNavigation('/join')}
                  className='rounded -join'
                >
                  Join
                </a>
              </>
            )}
          </nav>
        </div>
      </div>
    </header>
  )
}

export default withRouter(WebsiteHeader)
