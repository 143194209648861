import { useCallback } from 'react'

import { auth } from 'services/firebase'

const useSignInWithEmail = () => {
  return useCallback(async (values) => {
    return auth.signInWithEmailAndPassword(values.email, values.password)
  }, [])
}

export default useSignInWithEmail
