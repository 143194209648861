import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as SubmitIcon } from 'images/icon-send-message.svg'
import ChatActions from 'components/molecules/ChatActions'
import './ChatForm.scss'

const ChatForm = ({ handleSendMessage, chatId }) => {
  const fieldRef = useRef()
  const [message, setMessage] = useState('')
  const [height, setHeight] = useState(40)

  useEffect(() => {
    if (fieldRef) {
      fieldRef.current.focus()
    }
  }, [chatId])

  const handleSubmitOnClick = () => {
    if (message.replace(/(\r\n|\n|\r)/gm, '').length) {
      handleSendMessage(message)
      setMessage('')
      setHeight(40)
    }
  }

  const handleSubmitMessage = (e) => {
    if (
      e.keyCode === 13 &&
      !e.shiftKey &&
      message.replace(/(\r\n|\n|\r)/gm, '').length
    ) {
      e.preventDefault()
      handleSendMessage(message)
      setMessage('')
      setHeight(40)
    }
    if (e.keyCode === 13 && e.shiftKey && height === 40) {
      setHeight(height + 40)
    }
  }

  const handleChangeMessage = (val) => {
    if (val.length > 100 && height === 40) {
      setHeight(height + 40)
    } else if (val.length < 100 && height === 80 && !/\r|\n/.exec(val)) {
      setHeight(40)
    }
    setMessage(val)
  }

  const handleBlur = () => {
    if (message.replace(/(\r\n|\n|\r)/gm, '').length < 100) {
      setHeight(40)
    }
  }

  return (
    <div className='o-chatForm'>
      <div className='o-chatForm__actions'>
        <ChatActions chatId={chatId} />
      </div>
      <div className='o-chatForm__field'>
        <textarea
          ref={fieldRef}
          onBlur={handleBlur}
          placeholder='Write a message'
          value={message}
          onChange={(e) => handleChangeMessage(e.target.value)}
          onKeyDown={handleSubmitMessage}
          style={{ height: `${height}px` }}
        />
      </div>
      <div className='o-chatForm__submit'>
        <button onClick={handleSubmitOnClick}>
          <SubmitIcon />
        </button>
      </div>
    </div>
  )
}

export default ChatForm
