import React, { useEffect, useRef, useState } from 'react'
import CaseCard from 'components/molecules/CaseCard'
import { ReactComponent as MarkAsCompleteIcon } from 'images/icon-mark-as-complete.svg'
import { ReactComponent as RequestExtensionIcon } from 'images/icon-request-extension.svg'
import { ReactComponent as CancelCaseIcon } from 'images/icon-cancel-case.svg'
import { ReactComponent as ReportADisputeIcon } from 'images/icon-report-a-dispute.svg'
import { ReactComponent as ViewInvoiceIcon } from 'images/icon-view-invoice.svg'
import { ReactComponent as CommentIcon } from 'images/icon-comment.svg'

import useGetCaseById from 'services/firebase/hooks/cases/useGetCaseById'
import Loader from 'components/atoms/Loader'
import './ManageCase.scss'
import CaseAction from 'components/atoms/CaseAction/CaseAction'
import useUpdateCaseById from 'services/firebase/hooks/cases/useUpdateCaseById'
import { toast } from 'react-toastify'
import { auth } from 'services/firebase'
import MomentUtils from '@date-io/moment'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import {
  FormControl,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core'
import moment from 'moment'
import useAddCaseSystemMessage from 'services/firebase/hooks/chat/useAddCaseSystemMessage'
import { formatter } from 'utils/functions'
import StripeCheckoutForm from 'components/organisms/StripeCheckoutForm/StripeCheckoutForm'
import Modal from '@material-ui/core/Modal'
import useGetLawyerStripeAccountId from 'services/firebase/hooks/lawyers/useGetLawyerStripeAccountId'
import CaseReviews from 'components/molecules/CaseReviews'
import { useNotifications } from 'context/notifications'
import useRegisterUserNotification from 'services/firebase/hooks/notifications/useRegisterUserNotification'

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'transparent',
    padding: theme.spacing(2),
    minWidth: 120,
    border: 'none',
  },
  date: {
    marginRight: 0,
  },
}))

const ManageCase = ({ match, history }) => {
  const classes = useStyles()
  const { uid, displayName } = auth.currentUser
  const doGetCaseById = useGetCaseById()
  const doUpdateCaseById = useUpdateCaseById()

  const { updateNotificationsStates } = useNotifications()
  const doRegisterUserNotification = useRegisterUserNotification()

  const doGetLawyerStripeAccountId = useGetLawyerStripeAccountId()
  const doAddCaseSystemMessage = useAddCaseSystemMessage()
  const [caseData, setCaseData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [caseStatus, setCaseStatus] = useState(null)
  const [newDate, setNewDate] = useState(new Date())
  const [amount, setAmount] = useState(null)
  const [amountTotal, setAmountTotal] = useState(0)
  const [disputeMessage, setDisputeMessage] = useState('')

  const [showPaymentModal, setShowPaymentModal] = useState(false)

  useEffect(() => {
    if (!amount) {
      setAmountTotal(0)
    } else {
      setAmountTotal((caseData.rate / 100) * amount)
    }
  }, [amount])

  useEffect(() => {
    doGetCaseById(match.params.caseId).then((result) => {
      setCaseData(result)
      setCaseStatus(result.status)
      setIsLoading(false)
    })
  }, [])

  const handleMarkAsComplete = (data) => {
    let newAmount = caseData.amount
    if (caseData.paymentType === 'noWinNoFee') {
      if (!amount) {
        toast.error('You need to enter the amount.')
        return
      }
      newAmount = amount
    }

    doUpdateCaseById(match.params.caseId, {
      status: 'completed_by_lawyer',
      amount: newAmount,
      stripe: data || caseData.stripe,
    }).then(async () => {
      /**
       * Lawyer notification
       */
      await doRegisterUserNotification(caseData.senderUid, {
        message: 'You marked the case as complete.',
        type: 'completed',
        link: `/panel/cases/${match.params.caseId}`,
      })

      /**
       * Client notification
       */
      await doRegisterUserNotification(caseData.receiverUid, {
        message: `${
          caseData.senderName || 'The lawyer'
        } marked the case as complete.`,
        type: 'completed',
        link: `/panel/cases/${match.params.caseId}`,
      })

      await updateNotificationsStates()

      await doAddCaseSystemMessage(
        match.params.caseId,
        caseData.senderUid,
        caseData.receiverUid,
        {
          [caseData.senderUid]: 'You marked the case as complete.',
          [caseData.receiverUid]: 'The lawyer marked the case as complete.',
        },
        'link',
        history
      )

      toast.success('Case marked as complete!')
      setCaseStatus('completed_by_lawyer')
    })
  }

  const handleAcceptAsComplete = () => {
    doUpdateCaseById(match.params.caseId, {
      status: 'complete',
      payout: moment().add(7, 'days').toDate(),
    }).then(async () => {
      /**
       * Lawyer notification
       */
      await doRegisterUserNotification(caseData.senderUid, {
        message: `${
          caseData.receiverName || 'The client'
        } accepted the delivery of the case and marked it as finished.`,
        type: 'completed-accepted',
        link: `/panel/cases/${match.params.caseId}`,
      })

      /**
       * Client notification
       */
      await doRegisterUserNotification(caseData.receiverUid, {
        message: `You accepted the delivery of the case and marked it as finished.`,
        type: 'completed-accepted',
        link: `/panel/cases/${match.params.caseId}`,
      })

      await doRegisterUserNotification(caseData.receiverUid, {
        message: `Send a review about ${caseData.senderName || 'the lawyer'}`,
        type: 'review',
        link: `/panel/cases/${match.params.caseId}`,
      })

      await updateNotificationsStates()

      await doAddCaseSystemMessage(
        match.params.caseId,
        caseData.senderUid,
        caseData.receiverUid,
        {
          [caseData.senderUid]:
            'The client accepted the delivery of the case and marked it as finished.',
          [caseData.receiverUid]:
            'You accepted the delivery of the case and marked it as finished.',
        },
        'message',
        history
      )
      toast.success('Case marked as complete!')
      setCaseStatus('complete')
    })
  }

  const handleClientAcceptDelivery = async () => {
    handleAcceptAsComplete()
    /**
     * Comentei isso aqui porque não tenho como 'agendar' uma retirada
     */
    /*    const stripeData = await doGetLawyerStripeAccountId(caseData.senderUid)

        axios
          .post(
            'https://us-central1-api-project-619151099122.cloudfunctions.net/stripe/payout',
            { stripe: caseData.stripe, account_id: stripeData.account_id }
          )
          .then((result) => {
            console.log('result:', result)
            handleAcceptAsComplete()
          })
          .catch((err) => {
            toast.error('There was an error, please try again!')
          })*/
  }

  const handleToCompleteCase = () => {
    if (caseData.paymentType === 'noWinNoFee' && caseData.status === 'active') {
      setShowPaymentModal(true)
    } else {
      if (caseData.status === 'active') {
        handleMarkAsComplete()
      } else {
        handleClientAcceptDelivery()
      }
    }
  }

  const handleRequestExtension = () => {
    doUpdateCaseById(match.params.caseId, {
      newDeadline: newDate.toDate(),
    }).then(async () => {
      await doRegisterUserNotification(caseData.receiverUid, {
        message: `${
          caseData.senderName || 'The lawyer'
        } asked for more time to complete the case.`,
        type: 'extend-time',
        link: `/panel/cases/${match.params.caseId}`,
      })

      await doAddCaseSystemMessage(
        match.params.caseId,
        caseData.senderUid,
        caseData.receiverUid,
        'The lawyer asked for more time to complete the case.',
        'link',
        history
      )
      toast.success('Time extension requested!')
    })
  }

  const handleAcceptRequestExtension = () => {
    doUpdateCaseById(match.params.caseId, {
      newDeadline: null,
      deadline: caseData.newDeadline,
    }).then(async () => {
      /**
       * Lawyer notification
       */
      await doRegisterUserNotification(caseData.senderUid, {
        message: `Delivery extension for ${moment(
          caseData.newDeadline.toDate()
        ).format('DD/MM/YYYY')} was accepted.`,
        type: 'extend-time-accepted',
        link: `/panel/cases/${match.params.caseId}`,
      })

      /**
       * Client notification
       */
      await doRegisterUserNotification(caseData.receiverUid, {
        message: `Delivery extension for ${moment(
          caseData.newDeadline.toDate()
        ).format('DD/MM/YYYY')} was accepted.`,
        type: 'extend-time-accepted',
        link: `/panel/cases/${match.params.caseId}`,
      })

      await updateNotificationsStates()

      await doAddCaseSystemMessage(
        match.params.caseId,
        caseData.senderUid,
        caseData.receiverUid,
        `Delivery extension for ${moment(caseData.newDeadline.toDate()).format(
          'DD/MM/YYYY'
        )} was accepted.`,
        'message',
        history
      )

      toast.success('Delivery change accepted successfully!')
    })
  }

  const handleCaseCancellation = () => {
    doUpdateCaseById(match.params.caseId, { status: 'canceled' }, history).then(
      async () => {
        /**
         * Lawyer notification
         */
        await doRegisterUserNotification(caseData.senderUid, {
          message:
            'You canceled the case and the payment will be returned to the client.',
          type: 'canceled',
          link: `/panel/cases/${match.params.caseId}`,
        })

        /**
         * Client notification
         */
        await doRegisterUserNotification(caseData.receiverUid, {
          message: `${
            caseData.senderName || 'The lawyer'
          } canceled the case and the payment will be returned to you.`,
          type: 'canceled',
          link: `/panel/cases/${match.params.caseId}`,
        })

        await updateNotificationsStates()

        await doAddCaseSystemMessage(
          match.params.caseId,
          caseData.senderUid,
          caseData.receiverUid,
          'The case has been cancelled and the payment will be refunded back to the client.',
          'message',
          history
        )

        toast.success('Cancellation request sent successfully!')
        setCaseStatus('canceled')
      }
    )
  }

  const handleReportADispute = () => {
    if (disputeMessage.length > 50) {
      doUpdateCaseById(match.params.caseId, {
        status: 'dispute',
        disputeMessage: disputeMessage,
        disputeInitiatedBy: {
          uid,
          displayName,
        },
      }).then(async () => {
        /**
         * Lawyer notification
         */
        await doRegisterUserNotification(uid, {
          message: 'You started a dispute in this case',
          type: 'dispute',
          link: `/panel/cases/${match.params.caseId}`,
        })

        /**
         * Client notification
         */
        const userUid =
          uid === caseData.senderUid ? caseData.receiverUid : caseData.senderUid
        const messageName =
          uid === caseData.senderUid
            ? `${caseData.senderName || 'The lawyer'}`
            : `${caseData.receiverName || 'The client'} `
        await doRegisterUserNotification(userUid, {
          message: `${messageName} started a dispute in this case.`,
          type: 'dispute',
          link: `/panel/cases/${match.params.caseId}`,
        })

        await updateNotificationsStates()

        await doAddCaseSystemMessage(
          match.params.caseId,
          caseData.senderUid,
          caseData.receiverUid,
          'This case is in dispute.',
          'link',
          history
        )

        toast.success('Dispute request sent successfully!')
        setCaseStatus('dispute')
      })
    } else {
      toast.error('The message must have more than 50 characters.')
    }
  }

  const handleChangeData = (date) => {
    setNewDate(date)
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div className='p-manageCase'>
      <div className='p-manageCase__container'>
        <div className='p-manageCase__header'>
          <strong>Manage Case</strong>
        </div>
        <div className='p-manageCase__content'>
          <div className='p-manageCase__content--card'>
            <CaseCard withoutLink data={caseData} type={caseData.status} />
          </div>
          <div className='p-manageCase__content--actions'>
            {caseStatus !== 'dispute' ? (
              <>
                {caseStatus === 'completed_by_lawyer' &&
                  (caseData.senderUid === uid ? (
                    <CaseAction
                      withoutAction
                      label='Marked as Completed'
                      type='-markAsComplete'
                      icon={<MarkAsCompleteIcon />}
                    />
                  ) : (
                    <CaseAction
                      startOpen
                      label='Case marked as complete'
                      type='-markAsComplete'
                      icon={<MarkAsCompleteIcon />}
                    >
                      {caseData.paymentType === 'noWinNoFee' ? (
                        <>
                          <p>
                            Your lawyer has completed the case. If the amount
                            awarded for your case is correct and you have
                            received your settlement click accept to mark the
                            case as complete.
                          </p>
                          <div className='p-manageCase__content--paymentInfo'>
                            <div className='p-manageCase__content--calculation'>
                              <span>Total amount awarded for your case:</span>
                              <span>{formatter.format(caseData.amount)}</span>
                            </div>
                            <div className='p-manageCase__content--calculation'>
                              <b>Less lawyer fee ({caseData.rate}%):</b>
                              <b>
                                {formatter.format(
                                  (caseData.rate / 100) * caseData.amount
                                )}
                              </b>
                            </div>
                            <div className='p-manageCase__content--calculation -start'>
                              <div className='flex flex-col'>
                                <b>You received ({caseData.clientRate}%):</b>
                                <small className='text-gray-600 w-56 text-xs'>
                                  If you have not received payment, please
                                  report a dispute.
                                </small>
                              </div>
                              <b>
                                {formatter.format(
                                  (caseData.clientRate / 100) * caseData.amount
                                )}
                              </b>
                            </div>
                          </div>
                        </>
                      ) : (
                        <p>
                          Your lawyer has completed the case. Do you agree to
                          mark the case as completed?
                        </p>
                      )}
                      <button onClick={handleToCompleteCase}>Accept</button>
                    </CaseAction>
                  ))}
                {caseData.senderUid === uid ? (
                  <>
                    {caseStatus !== 'completed_by_lawyer' &&
                      caseStatus !== 'complete' &&
                      caseStatus !== 'canceled' && (
                        <CaseAction
                          label='Mark as Completed'
                          type='-markAsComplete'
                          icon={<MarkAsCompleteIcon />}
                        >
                          {caseData.paymentType === 'noWinNoFee' ? (
                            <>
                              <p>
                                I have completed the case. Please confirm with
                                my client the amount of settled or recovered
                                funds is correct to close the case & pay
                                Lawyersup&apos;s fee.
                              </p>
                              <FormControl
                                fullWidth
                                variant='outlined'
                                className={classes.formControl}
                              >
                                <TextField
                                  label='Amount'
                                  className={classes.field}
                                  variant='outlined'
                                  required
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position='start'>
                                        £
                                      </InputAdornment>
                                    ),
                                  }}
                                  type='number'
                                  onChange={(e) => setAmount(e.target.value)}
                                />
                                <small className='-gray'>
                                  This total is the amount received from the
                                  &apos;settled&apos; or &apos;recovered
                                  funds&apos;.
                                </small>
                              </FormControl>
                              {amount && (
                                <div className='p-manageCase__content--paymentInfo'>
                                  <div className='p-manageCase__content--calculation'>
                                    <b>My fee ({caseData.rate}%):</b>
                                    <span>
                                      {formatter.format(
                                        (caseData.rate / 100) * amount
                                      )}
                                    </span>
                                  </div>
                                  <div className='p-manageCase__content--calculation'>
                                    <b>
                                      I have paid my client (
                                      {caseData.clientRate}
                                      %):
                                    </b>
                                    <b>
                                      {formatter.format(
                                        (caseData.clientRate / 100) * amount
                                      )}
                                    </b>
                                  </div>
                                  <div className='p-manageCase__content--calculation'>
                                    <span>Lawyerup 10% + VAT:</span>
                                    <span>
                                      {formatter.format(amountTotal * 0.12)}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <p>
                              I have completed the case, please send
                              confirmation request to my client and approve
                              payment.
                            </p>
                          )}
                          {caseData.paymentType !== 'noWinNoFee' &&
                            caseData.paymentType !== 'legalAid' && (
                              <p className='-gray'>
                                Note: If the client does not confirm within
                                7days, funds are automatically paid to you
                              </p>
                            )}
                          <button onClick={handleToCompleteCase}>
                            Confirm
                            {caseData.paymentType === 'noWinNoFee' && ' & Pay'}
                          </button>
                        </CaseAction>
                      )}
                    {caseStatus !== 'completed_by_lawyer' &&
                      caseStatus !== 'complete' &&
                      caseStatus !== 'canceled' && (
                        <>
                          <CaseAction
                            label='Request Extension'
                            type='-requestExtension'
                            icon={<RequestExtensionIcon />}
                          >
                            <div className='p-manageCase__actionContent -requestExtension'>
                              <div className='-field'>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <KeyboardDatePicker
                                    KeyboardButtonProps={{
                                      class: classes.date,
                                    }}
                                    disablePast
                                    placeholder='DD/MM/YYYY'
                                    className={classes.date}
                                    inputVariant='outlined'
                                    format='DD/MM/YYYY'
                                    value={newDate}
                                    onChange={handleChangeData}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                              <p>
                                I would like to extend the completion deadline
                                until this date
                              </p>
                            </div>
                            <button onClick={handleRequestExtension}>
                              Request
                            </button>
                          </CaseAction>
                          <CaseAction
                            label='Cancel Case'
                            type='-cancelCase'
                            icon={<CancelCaseIcon />}
                          >
                            <p>
                              I would like to cancel the case, please refund all
                              money back to the client.
                            </p>
                            <button onClick={handleCaseCancellation}>
                              Confirm
                            </button>
                          </CaseAction>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    {caseData.newDeadline && (
                      <CaseAction
                        startOpen
                        label='The lawyer asked for an extension of time'
                        type='-requestExtension'
                        icon={<RequestExtensionIcon />}
                      >
                        <div className='p-manageCase__actionContent -requestExtension'>
                          <p>
                            The lawyer wants to change the delivery date of the
                            case to{' '}
                            <b>
                              {moment(caseData.newDeadline.toDate()).format(
                                'DD/MM/YYYY'
                              )}
                            </b>
                          </p>
                        </div>
                        <button onClick={handleAcceptRequestExtension}>
                          Accept
                        </button>
                      </CaseAction>
                    )}
                  </>
                )}

                {caseStatus !== 'complete' && caseStatus !== 'canceled' && (
                  <CaseAction
                    label='Report a Dispute'
                    type='-reportADispute'
                    icon={<ReportADisputeIcon />}
                  >
                    <div className='p-manageCase__actionContent -requestExtension -reportDispute'>
                      <textarea
                        placeholder='Describe why you would like to report this dispute.'
                        value={disputeMessage}
                        onChange={(e) => setDisputeMessage(e.target.value)}
                        maxLength={1000}
                      />
                      <small>{disputeMessage.length}/1000</small>
                    </div>
                    <button onClick={handleReportADispute}>Report</button>
                  </CaseAction>
                )}

                {caseStatus === 'complete' && (
                  <div>
                    {(caseData.receiverUid === uid &&
                      caseData.paymentType === 'noWinNoFee') ||
                    caseData.paymentType === 'legalAid' ? (
                      <CaseAction
                        label='View invoice'
                        type='-viewInvoice'
                        icon={<ViewInvoiceIcon />}
                      >
                        <p>This case has no invoice available.</p>
                      </CaseAction>
                    ) : (
                      <CaseAction
                        label='View invoice'
                        type='-viewInvoice'
                        icon={<ViewInvoiceIcon />}
                      />
                    )}
                    <CaseAction
                      startOpen
                      label='Order reviews'
                      type='-orderReviews'
                      icon={<CommentIcon />}
                    >
                      <CaseReviews
                        caseId={match.params.caseId}
                        senderUid={caseData.senderUid}
                        receiverUid={caseData.receiverUid}
                        receiverName={caseData.receiverName}
                      />
                    </CaseAction>
                  </div>
                )}
              </>
            ) : (
              <CaseAction
                startOpen
                label='Case in a Dispute'
                type='-reportADispute'
                icon={<ReportADisputeIcon />}
              >
                <p>
                  A dispute has been opened for this case and until the dispute
                  is resolved, none of the other actions in the case will be
                  available.
                </p>
              </CaseAction>
            )}
          </div>
        </div>
      </div>
      <Modal open={showPaymentModal}>
        <>
          <StripeCheckoutForm
            handleShowModal={setShowPaymentModal}
            offerId={caseData.offerId}
            offer={caseData}
            handleOffer={handleMarkAsComplete}
            amount={amountTotal * 0.12}
            type='directCharge'
          />
        </>
      </Modal>
    </div>
  )
}

export default ManageCase
