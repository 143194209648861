import React, { useState } from 'react'
import Camera from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import { ReactComponent as CloseIcon } from 'images/icon-close-modal.svg'
import Loader from 'components/atoms/Loader'
import { auth, db, storage } from 'services/firebase'
import { toast } from 'react-toastify'
import './ChatCamera.scss'
import moment from 'moment'

const ChatCamera = ({ handleCloseCamera, chatId }) => {
  const { uid } = auth.currentUser

  const [showCamera, setShowCamera] = useState(true)
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hideModal, setHideModal] = useState(false)

  const handleTakePhoto = (val) => {
    setShowCamera(false)
    setImage(val)
  }

  const handleSaveImage = async () => {
    setIsLoading(true)

    const filename = `${Date.now()}.jpg`
    const imagePath = `chats/${chatId}/${filename}`

    const messageRef = await db.ref(`chatMessages/${chatId}`).push({
      type: 'loading',
      message: filename,
      filename: filename,
      sender: uid,
      timestamp: moment().unix(),
    })

    setHideModal(true)

    storage
      .ref()
      .child(imagePath)
      .putString(image, 'data_url', {
        contentType: 'image/jpeg',
        customMetadata: {
          chatId,
          messageId: messageRef.key,
        },
      })
      .then(() => {
        return storage
          .ref()
          .child(imagePath)
          .getDownloadURL()
          .then(async (url) => {
            await db
              .ref(`chatMessages/${chatId}/${messageRef.key}/fileUrl`)
              .set(url)
            await db
              .ref(`chatMessages/${chatId}/${messageRef.key}/type`)
              .set('image')
            setIsLoading(false)
            handleCloseCamera(false)
          })
          .catch((err) => {
            setIsLoading(false)
            toast.error(err.message)
          })
      })
  }

  return (
    <div className={`m-chatCamera ${hideModal ? '-hide' : ''}`}>
      {isLoading && (
        <div className='m-chatCamera__loading'>
          <Loader />
        </div>
      )}
      <div className='m-chatCamera__content'>
        <button
          className='m-chatCamera__content--cameraClose'
          onClick={() => handleCloseCamera(false)}
        >
          <CloseIcon />
        </button>
        {showCamera ? (
          <div className='m-chatCamera__content--camera'>
            <Loader />
            <Camera
              onTakePhoto={(dataUri) => {
                handleTakePhoto(dataUri)
              }}
            />
          </div>
        ) : (
          <>
            <div className='m-chatCamera__content--image'>
              <img src={image} alt='' />
            </div>
            <div className='m-chatCamera__content--actions'>
              <button
                type='button'
                onClick={() => setShowCamera(true)}
                className='m-chatCamera__content--actions-button -changePhoto'
              >
                Take another photo
              </button>
              <button
                type='button'
                className='m-chatCamera__content--actions-button -sendImage'
                onClick={handleSaveImage}
              >
                Send this photo
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ChatCamera
