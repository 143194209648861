import React, { useEffect, useRef, useState } from 'react'

import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'

import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'
import { Checkbox } from '@material-ui/core'

import './FixedFee.scss'
const FixedFee = () => {
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.fixedFee)

  const handleEdit = () => {
    if (edit) {
      doUpdateUserProfile({
        fixedFee: value,
      })
      setEdit(false)
    } else {
      setEdit(true)
    }
  }
  return (
    <div className='m-fixedFee'>
      <div className='m-fixedFee__box'>
        <i>£</i>
        {edit ? (
          <div className='m-fixedFee__form'>
            <span>Accept fixed fee?</span>
            <label onClick={() => setValue(true)}>
              <Checkbox color='primary' checked={value} value={true} />
              <span>Yes</span>
            </label>
            <label onClick={() => setValue(false)}>
              <Checkbox color='primary' checked={!value} value={false} />
              <span>No</span>
            </label>
          </div>
        ) : (
          <span>
            {value ? 'Fixed offer available' : 'Fixed offer not available'}
          </span>
        )}
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <CheckIcon /> : <EditIcon />}
        </button>
      </div>
    </div>
  )
}

export default FixedFee
