import React from 'react'
import { LocalVideoTrack, LocalAudioTrack } from 'twilio-video'
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Theme,
  Hidden,
} from '@material-ui/core'
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview'
import SettingsMenu from './SettingsMenu/SettingsMenu'
import { Steps } from '../PreJoinScreens'
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton'
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton'
import { useAppState } from 'context/state'
import useVideoContext from 'hooks/useVideoContext/useVideoContext'

import { auth } from 'services/firebase'

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '0 0 1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
        padding: '6px 16px',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  cancel: {
    '&:hover': {
      color: '#222',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}))

interface DeviceSelectionScreenProps {
  name: string
  avatar: string
  roomName: string
  handleOpenVideoCall: (open: boolean) => boolean
}

export default function DeviceSelectionScreen({
  name,
  avatar,
  roomName,
  handleOpenVideoCall,
}: DeviceSelectionScreenProps) {
  const classes = useStyles()
  const { getToken, isFetching } = useAppState()
  const {
    connect,
    isAcquiringLocalTracks,
    isConnecting,
    localTracks,
  } = useVideoContext()

  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting

  const handleJoin = () => {
    getToken(name, roomName).then((token) => connect(token))
  }

  const handleCancelVideoCall = () => {
    const videoTrack = localTracks.find(
      (track) => track.kind === 'video'
    ) as LocalVideoTrack

    const audioTrack = localTracks.find(
      (track) => track.kind === 'audio'
    ) as LocalVideoTrack

    if (audioTrack) {
      audioTrack.stop()
    }

    if (videoTrack) {
      videoTrack.disable()
      videoTrack.stop()
    }

    handleOpenVideoCall(false)
  }

  return (
    <>
      <Typography variant='h5' className={classes.gutterBottom}>
        Join the video room
      </Typography>

      <Grid container justify='center'>
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton
                className={classes.mobileButton}
                disabled={disableButtons}
              />
              <ToggleVideoButton
                className={classes.mobileButton}
                disabled={disableButtons}
              />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction='column' style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton
                  className={classes.deviceButton}
                  disabled={disableButtons}
                />
                <ToggleVideoButton
                  className={classes.deviceButton}
                  disabled={disableButtons}
                />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button
                variant='outlined'
                color='primary'
                onClick={handleCancelVideoCall}
                className={classes.cancel}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
              >
                Join Now
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
