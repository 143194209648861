import React, { useState } from 'react'
import './Expertise.scss'

import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'
import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'
import { expertise } from 'utils/constants'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import {
  Checkbox,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import PracticeAreas from './PracticeAreas'

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  select: {
    padding: 10,
    width: '100%',
    height: '100%',
    minWidth: 140,
    overflow: 'hidden',
    fontSize: 14,
  },
  icon: {
    right: 15,
  },
}))

const MainExpertise = () => {
  const classes = useStyles()

  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.expertise)

  const handleEdit = () => {
    if (edit) {
      if (value) {
        doUpdateUserProfile({
          expertise: value,
        })
      }
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <div className='m-mainExpertise'>
      <header>
        <span>Expertise</span>
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <CheckIcon /> : <EditIcon />}
        </button>
      </header>
      <main>
        {edit ? (
          <Select
            required
            multiple
            labelId='select-languages'
            disableUnderline
            IconComponent={KeyboardArrowDown}
            name='expertise'
            value={value}
            classes={{
              icon: classes.icon,
            }}
            className={classes.select}
            inputProps={{ className: classes.select }}
            onChange={handleChange}
            renderValue={(selected) =>
              selected
                .map(
                  (item) => expertise.find((exp) => exp.value === item).label
                )
                .join(', ')
            }
          >
            {expertise.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                <Checkbox
                  color='primary'
                  checked={value.indexOf(item.value) > -1}
                />
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}
          </Select>
        ) : (
          value &&
          value.map((item) => (
            <span
              key={expertise.find((exp) => exp.value === item).value}
              className='chip'
            >
              {expertise.find((exp) => exp.value === item).label}
            </span>
          ))
        )}
      </main>
      <footer>
        <PracticeAreas />
      </footer>
    </div>
  )
}

export default MainExpertise
