import React from 'react'
import notificationsIcon from 'images/icon-notifications.png'
import './Notifications.scss'
import Generic from '../Generic'

const Notifications = () => {
  return (
    <div className='m-notifications'>
      <header>
        <span>Notifications</span>
        <img src={notificationsIcon} alt='Contact' />
      </header>
      <main>
        <Generic
          toggle
          type='notificationByEmail'
          title='Email'
          legend='Receive Email notifications for new alerts'
        />
        <Generic
          toggle
          type='notificationByPushNotification'
          title='Mobile'
          legend='Enable Push notifications on your mobile device or tablet'
        />
      </main>
    </div>
  )
}

export default Notifications
