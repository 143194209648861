import React from 'react'
import { withRouter } from 'react-router-dom'
import PanelHeader from 'components/template/Header/PanelHeader'
import WebsiteHeader from 'components/template/Header/WebsiteHeader'

const Header = ({ location }) => {
  return location.pathname.includes('/panel/') ? (
    <PanelHeader />
  ) : (
    <WebsiteHeader />
  )
}

export default withRouter(Header)
