import { useCallback } from 'react'
import { db, firestore } from 'services/firebase'
import moment from 'moment'

const useAddCaseSystemMessage = () => {
  return useCallback(
    async (
      caseId,
      lawyerUid,
      clientUid,
      message,
      messageType,
      history = false,
      senderFrom = null,
      chatId = null
    ) => {
      if (chatId) {
        db.ref(`chatMessages/${chatId}`).push({
          message: message,
          type: messageType,
          caseId: caseId,
          sender: 'system',
          senderFrom: senderFrom,
          timestamp: moment().unix(),
        })
      } else {
        await firestore
          .collection('chats')
          .where('clientUid', '==', clientUid)
          .where('lawyerUid', '==', lawyerUid)
          .get()
          .then((snapshot) => {
            snapshot.docs.map((chat) => {
              db.ref(`chatMessages/${chat.id}`).push({
                message: message,
                type: messageType,
                caseId: caseId,
                sender: 'system',
                senderFrom: senderFrom,
                timestamp: moment().unix(),
              })
              db.ref(`chats/${chat.id}`).child('lastMessage').set(message)
              db.ref(`chats/${chat.id}`).child('timestamp').set(moment().unix())
              if (history) return history.push(`/panel/messages/${chat.id}`)
            })
          })
      }
    },
    []
  )
}

export default useAddCaseSystemMessage
