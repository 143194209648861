import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { groupBy } from '../../../../utils/functions'
import { expertise } from '../../../../utils/constants'

const CompleteCases = ({ cases }) => {
  if (cases.length <= 0) return false

  let series = []
  let labels = []

  const groupByType = groupBy('for')
  const groupedCases = groupByType(cases)

  Object.entries(groupedCases).map((item) => {
    series.push(item[1].length)
    labels.push(expertise.find((exp) => exp.value === item[0]).label)
  })

  const options = {
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
        },
      },
    },
    labels: labels,
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 425,
        options: {
          pie: {
            donut: {
              size: '75%',
            },
          },
          legend: { position: 'bottom' },
        },
      },
    ],
  }
  return <ReactApexChart options={options} series={series} type='donut' />
}

export default CompleteCases
