import React, { useState } from 'react'

// import { ReactComponent as ScheduleIcon } from 'images/icon-schedule.svg'
// import { ReactComponent as MeetingIcon } from 'images/icon-meeting.svg'
import AvailabilityTable from '../../molecules/AvailabilityTable'
// import BookMeetingForm from '../../molecules/BookMeetingForm'

import './BookMeeting.scss'

const BookMeeting = ({ availability }) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className='o-bookMeeting'>
      {/*<div className='o-bookMeeting__tabs'>*/}
      {/*  <button*/}
      {/*    className={activeTab === 0 ? '-active' : ''}*/}
      {/*    onClick={() => setActiveTab(0)}*/}
      {/*  >*/}
      {/*    <ScheduleIcon />*/}
      {/*    <span>Availability</span>*/}
      {/*  </button>*/}
      {/*  <button*/}
      {/*    className={activeTab === 1 ? '-active' : ''}*/}
      {/*    onClick={() => setActiveTab(1)}*/}
      {/*  >*/}
      {/*    <MeetingIcon />*/}
      {/*    <span>Book a Meeting</span>*/}
      {/*  </button>*/}
      {/*</div>*/}
      <div className='o-bookMeeting__content'>
        <h3>Availability</h3>
        {activeTab === 0 && <AvailabilityTable data={availability} />}
        {/*{activeTab === 1 && <BookMeetingForm />}*/}
      </div>
    </div>
  )
}

export default BookMeeting
