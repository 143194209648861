import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import ResultsSearchBar from 'components/organisms/ResultsSearchBar'
import Results from 'components/organisms/Results'
import './ResultsPage.scss'

const ResultsPage = ({ match }) => {
  const [showSearchBar, setShowSearchBar] = useState(true)

  return (
    <div className='p-resultsPage'>
      <div className='p-resultsPage__container'>
        <ResultsSearchBar showSearchBar={showSearchBar} />
      </div>
      <Results handleShowSearchBar={setShowSearchBar} />
    </div>
  )
}

export default withRouter(ResultsPage)
