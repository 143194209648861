import { useState } from 'react'

export const store = {
  state: {
    name: '',
    surname: '',
    email: '',
    password: '',
    phone: '',
    srcOisc: '',
    addSRALater: false,
    image: null,
    expertise: [],
    practiceAreas: [],
    languages: [],
    practisingSince: '',
    lawFirmName: '',
    numberOfEmployees: '',
    address: '',
    latitude: 51.509578,
    longitude: -0.127682,
    hourPrice: '',
    fixedFee: true,
    referralId: '',
  },
  setState(value) {
    this.state = value
    this.setters.forEach((setter) => setter(this.state))
  },
  setters: [],
}

store.setState = store.setState.bind(store)

export default function useLawyerRegistration() {
  const [state, set] = useState(store.state)
  if (!store.setters.includes(set)) {
    store.setters.push(set)
  }

  return [state, store.setState]
}
