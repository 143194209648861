import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { ReactComponent as PlusIcon } from 'images/icon-plus.svg'
import { ReactComponent as MinusIcon } from 'images/icon-minus.svg'
import './FaqQuestions.scss'

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid #ddd',
    boxShadow: 'none',
    color: '#222',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      color: '#fff',
      margin: 'auto',
      borderColor: '#18a1db',
      backgroundColor: '#18a1db'
    }
  },
  expanded: {}
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    padding: theme.spacing(3),
    '&$expanded': {
      padding: theme.spacing(3, 3, 1, 3),
      backgroundColor: '#18a1db',
      borderColor: '#18a1db'
    }
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
      color: '#fff'
    }
  },
  expanded: {}
}))(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(0, 3, 5, 3)
  }
}))(MuiExpansionPanelDetails)

const FaqQuestions = ({ data }) => {
  const [expanded, setExpanded] = React.useState('panel0')

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  return (
    <div className='o-faqQuestions'>
      {data.length > 0 ? data.map((item, key) => (
        <ExpansionPanel
          square
          className='o-faqQuestions__panel'
          key={`panel${key}`}
          expanded={expanded === `panel${key}`}
          onChange={handleChange(`panel${key}`)}>
          <ExpansionPanelSummary className='o-faqQuestions__panel--question'>
            <span className='o-faqQuestions__panel--questionText'>{item.question}</span>
            {expanded === 'panel' + key
              ? <MinusIcon className='o-faqQuestions__panel--questionIcon' />
              : <PlusIcon className='o-faqQuestions__panel--questionIcon' />}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className='o-faqQuestions__panel--anwser'>
            {item.answer}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )) : (
        <div className="o-faqQuestions__empty">No questions found</div>
      )}
    </div>
  )
}

export default FaqQuestions
