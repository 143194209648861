import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core'

import './JoinPage.scss'

const JoinPage = ({ history }) => {
  const [tab, setTab] = useState('lawyer')
  return (
    <div className='p-joinPage'>
      <div className='p-joinPage__tabs'>
        <button
          className={`-lawyer ${tab === 'lawyer' ? '-active' : ''}`}
          onClick={() => setTab('lawyer')}
        >
          I&apos;m a Lawyer
        </button>
        <button
          className={`-client ${tab === 'client' ? '-active' : ''}`}
          onClick={() => setTab('client')}
        >
          Find a Lawyer
        </button>
      </div>
      <div
        className={`p-joinPage__lawyer ${tab === 'lawyer' ? '-active' : ''}`}
      >
        <h2>I am a Lawyer</h2>
        <p>
          Be listed for free. <br />
          Let people contact you.
          <br />
          Instant notifications.
        </p>
        <Button
          size='large'
          variant='contained'
          color='primary'
          onClick={() => history.push('/lawyer-registration')}
        >
          Join
        </Button>
        <div className='p-joinPage__lawyer--image'>
          <img src={require('images/img-lawyer-mobile.jpg')} alt='' />
        </div>
      </div>
      <div className='p-joinPage__image'>
        <img src={require('images/img-join-page.png')} alt='' />
      </div>

      <div
        className={`p-joinPage__client ${tab === 'client' ? '-active' : ''}`}
      >
        <h2>Find a Lawyer</h2>
        <p>
          Search for free. <br />
          Compare hundreds of lawyers.
          <br />
          Call or Message now.
        </p>
        <Button
          size='large'
          variant='contained'
          color='primary'
          onClick={() => history.push('/')}
        >
          Search
        </Button>
        <div className='p-joinPage__client--image'>
          <img src={require('images/img-client-mobile.jpg')} alt='' />
        </div>
      </div>
    </div>
  )
}

export default withRouter(JoinPage)
