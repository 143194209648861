import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { Link } from 'react-router-dom'
import useUserInfo from 'hooks/useUserInfo'
import CompleteCases from 'components/molecules/Charts/CompleteCases'

import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'
import { ReactComponent as CasesIcon } from 'images/icon-cases.svg'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'

import useGetCases from 'services/firebase/hooks/cases/useGetCases'
import Loader from 'components/atoms/Loader'
import moment from 'moment'
import './ClientDashboard.scss'

const ClientDashboard = () => {
  const [userInfo] = useUserInfo()
  const [phone, setPhone] = useState(userInfo.phone)
  const [editPhone, setEditPhone] = useState(false)

  const doGetCases = useGetCases()
  const [activeCases, setActiveCases] = useState([])
  const [completedCases, setCompletedCases] = useState([])
  const [, setPaymentTotal] = useState(0)
  const [, setPaymentReceived] = useState(0)
  const [, setPaymentPending] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    doGetCases().then(async (result) => {
      if (result) {
        let tempPaymentTotal = 0
        let tempPaymentReceived = 0
        let tempPaymentPending = 0

        await result.map((item) => {
          if (item.paymentType !== 'noWinNoFee') {
            if (item.status !== 'canceled') {
              tempPaymentTotal = tempPaymentTotal + item.amount
            }
            if (item.status === 'complete') {
              tempPaymentReceived = tempPaymentReceived + item.amount
            } else {
              tempPaymentPending = tempPaymentPending + item.amount
            }
          }
        })

        setPaymentTotal(tempPaymentTotal)
        setPaymentPending(tempPaymentPending)
        setPaymentReceived(tempPaymentReceived)

        const currentActiveCases = await result.filter(
          (item) => item.status === 'active'
        )
        const currentCompletedCases = await result.filter(
          (item) => item.status === 'complete'
        )
        setActiveCases(currentActiveCases)
        setCompletedCases(currentCompletedCases)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    })
  }, [])

  return isLoading ? (
    <Loader />
  ) : (
    <div className='o-clientDashboard'>
      <div className='o-clientDashboard__container'>
        <div className='o-clientDashboard__aside'>
          <div className='o-clientDashboard__aside--whiteBox'>
            <div className='o-clientDashboard__aside--avatar'>
              <Avatar
                size='170'
                maxInitials={2}
                round='100%'
                color='#18a1db'
                textSizeRatio={2.5}
                src={userInfo.photoURL}
                name={userInfo.displayName}
              />
            </div>
            <div className='o-clientDashboard__aside--info'>
              <h2>{userInfo.displayName}</h2>
            </div>
            <div className='o-clientDashboard__aside--form'>
              <div className='o-clientDashboard__aside--form-box'>
                <header>
                  <strong>Contacts</strong>
                  <button
                    className={editPhone ? '-editing' : ''}
                    onClick={() => setEditPhone(!editPhone)}
                    type='button'
                  >
                    {editPhone ? <CheckIcon /> : <EditIcon />}
                  </button>
                </header>
                <main>
                  <span>{userInfo.email}</span>
                  {editPhone ? (
                    <input
                      type='text'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  ) : (
                    <span>{phone}</span>
                  )}
                </main>
              </div>
            </div>
          </div>

          <div className='o-clientDashboard__aside--searchService'>
            <h3>What Service are you looking for?</h3>
            <strong>Receive offers from Qualified Lawyers</strong>
            <div className='o-clientDashboard__aside--searchService-image'>
              <img src={require('images/looking-for-service.png')} alt='' />
            </div>
            <Link to='/'>Search</Link>
          </div>
        </div>
        <div className='o-clientDashboard__content'>
          <div className='o-clientDashboard__content--filter'>
            <button className='-active'>All time</button>
          </div>
          <div className='o-clientDashboard__content--box'>
            <header className='-openCases'>
              <span>{activeCases.length} Open Cases</span>
              <CasesIcon />
            </header>
            <main>
              <table>
                <thead>
                  <tr>
                    <th>Lawyer</th>
                    <th>Deadline</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {activeCases.map((item) => (
                    <tr key={item.caseId}>
                      <td>{item.senderName}</td>
                      <td>
                        {moment(item.deadline.toDate()).format('MM/DD/YYYY')}
                      </td>
                      <td>
                        <Link to={`/panel/cases/${item.caseId}`}>
                          <CheckIcon />
                          <span>Mark as complete</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </main>
          </div>

          <div className='o-clientDashboard__content--box'>
            <header className='-completedCases'>
              <span>{completedCases.length} Completed Cases</span>
              <CasesIcon />
            </header>
            <main className='-withSpace'>
              <CompleteCases cases={completedCases} />
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientDashboard
